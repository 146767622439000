import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@shared/dialog';
import { DialogConfirmationModule } from '@shared/dialog-confirmation';
import { CanDeactivateGuard } from './guards';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    DialogConfirmationModule
  ],
  providers: [
    CanDeactivateGuard
  ]
})
export class CanDeactivateModule { }
