import 'reflect-metadata';
import 'pdfjs-dist/build/pdf.worker.min';
import { AppModule } from './app/app.module';
import { configuration } from '@configurations';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

if (configuration.production) {
  enableProdMode();
}

Sentry.init({
  enabled: configuration.production,
  dsn: configuration.sentry.dsn,
  environment: configuration.sentry.environment,
  ignoreErrors: [
    new RegExp(/msDiscoverChatAvailable'/),
    new RegExp(/Failed to read the 'localStorage'/),
    new RegExp(/There is no operation to undo'/),
    new RegExp(/"headers":{"normalizedNames":{},"lazyUpdate":null,"headers":{}},"status":0,"statusText":"Unknown Error"/)
  ]
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
