import { Configuration } from './utils';

export const configuration = Configuration({
  production: true,
  api: {
    domain: 'api.mbuild.global'
  },
  openAPI: {
    url: 'https://openapi.mbuild.global'
  },
  sentry: {
    environment: 'production'
  }
});
