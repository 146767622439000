import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { DateTime } from 'luxon';
import { Media } from '@shared/media';
import { User } from '@shared/user';
import { Task } from '@shared/task';
import { Recipient } from '@shared/recipient';
import { SubtaskStatus } from '../enums';
import { QualityIssue } from '@shared/quality-issue';
import { SubtaskUpdate } from './subtask-update';
import { Company } from '@shared/company';
import { FloorPlanAreaPin } from '@shared/floor-plan-area-pin';
import { LocationMatrix } from '@shared/location-matrix';

export class Subtask {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public description: string;

  @Expose({ name: 'location_description', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public locationDescription: string;

  @Expose({ name: 'is_defect', groups: [ClassGroup.MAIN] })
  public isDefect: boolean;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public estimation: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public status: SubtaskStatus;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public task: number;

  @Type(() => Task)
  @Expose({ name: 'expanded_task', groups: [ClassGroup.MAIN] })
  public expandedTask: Task;

  @Expose({ groups: [ClassGroup.MAIN] })
  public user: number;

  @Type(() => User)
  @Expose({ name: 'expanded_user', groups: [ClassGroup.MAIN] })
  public expandedUser: User;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public company: number;

  @Type(() => Company)
  @Expose({ name: 'expanded_company', groups: [ClassGroup.MAIN] })
  public expandedCompany: Company;

  @Expose({ name: 'quality_issue', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public qualityIssue: number;

  @Type(() => FloorPlanAreaPin)
  @Expose({ name: 'floor_plan_area_pin', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public floorPlanAreaPin: FloorPlanAreaPin;

  @Type(() => QualityIssue)
  @Expose({ name: 'expanded_quality_issue', groups: [ClassGroup.MAIN] })
  public expandedQualityIssue: QualityIssue;

  @Type(() => FloorPlanAreaPin)
  @Expose({ name: 'expanded_floor_plan_area_pin', groups: [ClassGroup.MAIN] })
  public expandedFloorPlanAreaPin: FloorPlanAreaPin;

  @Type(() => LocationMatrix)
  @Expose({ name: 'expanded_location_matrix', groups: [ClassGroup.MAIN] })
  public expandedLocationMatrix: LocationMatrix;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public files: Array<number>;

  @Type(() => Media)
  @Expose({ name: 'expanded_files', groups: [ClassGroup.MAIN] })
  public expandedFiles: Array<Media>;

  @Type(() => Media)
  @Expose({ name: 'expanded_closed_files', groups: [ClassGroup.MAIN] })
  public expandedClosedFiles: Array<Media>;

  @Type(() => Recipient)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public recipients: Array<Recipient>;

  @Type(() => Recipient)
  @Expose({ name: 'expanded_last_recipients', groups: [ClassGroup.MAIN] })
  public expandedLastRecipients: Array<Recipient>;

  @Type(() => SubtaskUpdate)
  @Expose({ name: 'expanded_last_confirmed_update', groups: [ClassGroup.MAIN] })
  public expandedLastConfirmedUpdate: SubtaskUpdate;

  @Type(() => SubtaskUpdate)
  @Expose({ name: 'expanded_last_status_change_update', groups: [ClassGroup.MAIN] })
  public expandedLastStatusChangeUpdate: SubtaskUpdate;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO(), { toPlainOnly: true, groups: [ClassGroup.CREATING] })
  @Expose({ name: 'due_date', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public dueDate: DateTime;

  @Transform(({ value }) => DateTime.fromSQL(value), { toClassOnly: true })
  @Expose({ name: 'expanded_status_date', groups: [ClassGroup.MAIN] })
  public expandedStatusDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO(), { toPlainOnly: true, groups: [ClassGroup.CREATING] })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  @Expose({ name: 'files_count', groups: [ClassGroup.MAIN] })
  public filesCount: number;

  @Expose({ name: 'closed_files_count', groups: [ClassGroup.MAIN] })
  public closedFilesCount: number;

  @Exclude()
  public get dueDateDaysCount(): number {
    return Math.round(this.dueDate
      .diff(this.createdAt, 'days')
      .toObject()
      .days
    );
  }

  @Exclude()
  public get referenceID(): number {
    return this.qualityIssue;
  }

  @Exclude()
  public get isUnderInspection(): boolean {
    return this.status === SubtaskStatus.UNDER_INSPECTION;
  }

  @Exclude()
  public get isUnderReview(): boolean {
    return this.status === SubtaskStatus.UNDER_REVIEW;
  }

  @Exclude()
  public get isRequestingApproval(): boolean {
    return this.status === SubtaskStatus.REQUESTING_APPROVAL;
  }

  @Exclude()
  public get isRequestedApprovalRejected(): boolean {
    return this.status === SubtaskStatus.REQUESTED_APPROVAL_REJECTED;
  }

  @Exclude()
  public get isRemoved(): boolean {
    return this.status === SubtaskStatus.REMOVED;
  }

  @Exclude()
  public get isInProgress(): boolean {
    return this.status === SubtaskStatus.IN_PROGRESS;
  }

  @Exclude()
  public get isInspectionRejected(): boolean {
    return this.status === SubtaskStatus.INSPECTION_REJECTED;
  }

  @Exclude()
  public get isDeclined(): boolean {
    return this.status === SubtaskStatus.DECLINED;
  }

  @Exclude()
  public get isContested(): boolean {
    return this.status === SubtaskStatus.CONTESTED;
  }

  @Exclude()
  public get isClosed(): boolean {
    return this.status === SubtaskStatus.CLOSED;
  }

  constructor(model: Partial<Subtask> = {}) {
    Object.assign(this, model);
  }
}
