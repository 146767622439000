import { Expose, Type, Transform } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { DateTime } from 'luxon';
import { Media } from '@shared/media';
import { LocationPackageMatrix } from './location-package-matrix';

export class LocationMatrixPackage {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public enabled: boolean;

  @Type(() => LocationPackageMatrix)
  @Expose({ name: 'package_matrix', groups: [ClassGroup.MAIN] })
  public packageMatrix: LocationPackageMatrix;

  @Expose({ groups: [ClassGroup.MAIN] })
  public package: number;

  @Expose({ name: 'location_matrix', groups: [ClassGroup.MAIN] })
  public locationMatrix: number;

  @Expose({ name: 'expanded_location_matrix', groups: [ClassGroup.MAIN] })
  public expandedLocationMatrix: number;

  @Expose({ name: 'package_activity', groups: [ClassGroup.MAIN] })
  public packageActivity: number;

  @Expose({ name: 'package_activity_name', groups: [ClassGroup.MAIN] })
  public packageActivityName: string;

  @Expose({ name: 'expanded_media_count', groups: [ClassGroup.MAIN] })
  public expandedMediaCount: number;

  @Type(() => Media)
  @Expose({ name: 'expanded_media', groups: [ClassGroup.MAIN] })
  public expandedMedia: Array<Media>;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  constructor(model: Partial<LocationMatrixPackage> = {}) {
    Object.assign(this, model);
  }
}
