import { Exclude, Expose, Type } from 'class-transformer';

export class FloorPlanRevisionSerializedDataItem<T> {
  @Type((options) => options.newObject.type)
  @Expose()
  public fields: T;

  @Expose()
  public model: string;

  @Expose()
  public pk: number;

  @Exclude()
  private type: new() => T;

  constructor(type: new() => T) {
    this.type = type;
  }
}
