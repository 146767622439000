import { Expose } from 'class-transformer';

export class HandoverDocumentGenerateDownloadLinkRequest {
  @Expose({ name: 'handover_document' })
  public handoverDocument: Array<number>;

  @Expose({ name: 'asset_handover_document_media' })
  public assetHandoverDocumentMedia: Array<number>;

  @Expose({ name: 'package_handover_document_media' })
  public packageHandoverDocumentMedia: Array<number>;

  constructor(model: Partial<HandoverDocumentGenerateDownloadLinkRequest>) {
    Object.assign(this, model);
  }
}
