import { UserStatusEnum } from '@shared/user';

export class UserFilters {
  public company?: Array<number>;
  public lastName?: string;
  public group?: Array<number>;
  public groupExclude?: number;
  public search?: string;
  public project?: number;
  public projectOrCompanyAdmins?: number;
  public getTotalItemsCount?: boolean;
  public projectKeyContactsExcludeOrCompanyAdmins?: number;
  public projectExclude?: number;
  public subcontractorsOfPackageActivity?: number;
  public subcontractorsCompany?: number;
  public status?: Array<string>;
  public statusExclude?: Array<UserStatusEnum>;

  constructor(model: Partial<UserFilters> = {}) {
    Object.assign(this, model);
  }
}
