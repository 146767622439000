import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';

export class HandoverDocumentPaginationRequest extends PaginationRequest {
  @Expose()
  public search?: string;

  @Expose()
  public package?: Array<number>;

  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose()
  public area?: Array<string>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'document_type' })
  public documentType?: Array<number | string>;

  @Expose({ name: 'package_handover_document_type' })
  public packageHandoverDocumentType?: Array<number>;

  @Expose({ name: 'asset_handover_document_type' })
  public assetHandoverDocumentType?: Array<number>;

  @Expose()
  public company?: Array<number>;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<HandoverDocumentPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
