import { PackageActivity } from '@shared/package-activity';
import { PackageHandoverDocumentMedia } from '@shared/package-handover-document-media';
import { PackageHandoverDocumentType } from '@shared/package-handover-document-type';
import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { PackageHandoverDocumentStatistics } from './package-handover-document-statistics';

export class PackageHandoverDocument {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public project: number;

  @Type(() => Number)
  @Expose({ name: 'package_activity', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public packageActivity: number;

  @Type(() => Number)
  @Expose({ name: 'package_handover', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public packageHandoverID: number;

  @Type(() => Number)
  @Expose({ name: 'package_handover_document_type', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public packageHandoverDocumentTypeID: number;

  @Type(() => Number)
  @Expose({ name: 'number_required_files', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public filesRequired: number;

  @Type(() => PackageHandoverDocumentType)
  @Expose({ name: 'expanded_package_handover_document_type', groups: [ClassGroup.MAIN] })
  public expandedPackageHandoverDocumentType: PackageHandoverDocumentType;

  @Type(() => PackageActivity)
  @Expose({ name: 'expanded_package_activity', groups: [ClassGroup.MAIN] })
  public expandedPackageActivity: PackageActivity;

  @Type(() => PackageHandoverDocumentStatistics)
  @Expose({ name: 'expanded_package_handover_statistics', groups: [ClassGroup.MAIN] })
  public expandedPackageHandoverStatistics: PackageHandoverDocumentStatistics;

  @Type(() => PackageHandoverDocumentMedia)
  @Expose({ name: 'expanded_package_handover_document_media', groups: [ClassGroup.MAIN] })
  public expandedPackageHandoverDocumentMedia: Array<PackageHandoverDocumentMedia>;

  constructor(model: Partial<PackageHandoverDocument> = {}) {
    Object.assign(this, model);
  }
}
