import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageHandoverDocumentTypeService } from './package-handover-document-type.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    PackageHandoverDocumentTypeService
  ]
})
export class PackageHandoverDocumentTypeModule { }
