export enum TaskSortField {
  PACKAGE = 'package',
  PACKAGE_ACTIVITY = 'package_activity',
  PACKAGE_ACTIVITY_TASK = 'package_activity_task',
  BUILDING = 'building',
  LEVEL = 'level',
  AREA = 'area',
  STATUS = 'status',
  COMMENT = 'comment',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  USER = 'user'
}
