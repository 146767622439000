import { PackageActivity } from '@shared/package-activity';
import { PackageHandoverDocumentGroup } from '@shared/package-handover-document-group';
import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';

export class PackageHandover {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => Number)
  @Expose({ name: 'package_matrix', groups: [ClassGroup.MAIN] })
  public packageMatrixID: number;

  @Type(() => Number)
  @Expose({ name: 'inspection_period', groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public inspectionPeriod: number;

  @Type(() => Number)
  @Expose({ name: 'maintenance_period', groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public maintenancePeriod: number;

  @Type(() => PackageActivity)
  @Expose({ name: 'expanded_package_activity', groups: [ClassGroup.MAIN] })
  public expandedPackageActivity: PackageActivity;

  @Type(() => PackageHandoverDocumentGroup)
  @Expose({ name: 'package_handover_document_groups', groups: [ClassGroup.MAIN] })
  public packageHandoverDocumentGroups: Array<PackageHandoverDocumentGroup>;

  constructor(model: Partial<PackageHandover> = {}) {
    Object.assign(this, model);
  }
}
