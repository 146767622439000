import { Company } from '@shared/company';
import { Expose, Type } from 'class-transformer';

export class PackageMatrixCompany {
  @Expose()
  public id: number;

  @Expose({ name: 'package_matrix' })
  public packageMatrix: number;

  @Type(() => Company)
  @Expose({ name: 'expanded_company' })
  public expandedCompany: Company;

  constructor(model: Partial<PackageMatrixCompany> = {}) {
    Object.assign(this, model);
  }
}
