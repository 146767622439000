import { AssetHandoverDocumentMediaUpdate } from '@shared/asset-handover-document-media-update';
import { AssetHandoverDocument } from '@shared/asset-handover-document/models';
import { Media } from '@shared/media';
import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { AssetHandoverDocumentMediaStatusEnum } from '../enums';

export class AssetHandoverDocumentMedia {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => Number)
  @Expose({ name: 'asset_handover_document', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public assetHandoverDocument: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public media: number;

  @Type(() => String)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public status: AssetHandoverDocumentMediaStatusEnum;

  @Type(() => Media)
  @Expose({ name: 'expanded_media', groups: [ClassGroup.MAIN] })
  public expandedMedia: Media;

  @Type(() => AssetHandoverDocument)
  @Expose({ name: 'expanded_asset_handover_document', groups: [ClassGroup.MAIN] })
  public expandedAssetHandoverDocument: AssetHandoverDocument;

  @Type(() => AssetHandoverDocumentMediaUpdate)
  @Expose({ name: 'expanded_last_confirmed_update', groups: [ClassGroup.MAIN] })
  public expandedLastConfirmedUpdate: AssetHandoverDocumentMediaUpdate;

  @Type(() => AssetHandoverDocumentMediaUpdate)
  @Expose({ name: 'expanded_upload_update', groups: [ClassGroup.MAIN] })
  public expandedUploadUpdate: AssetHandoverDocumentMediaUpdate;

  @Type(() => AssetHandoverDocumentMediaUpdate)
  @Expose({ name: 'expanded_last_status_change_update', groups: [ClassGroup.MAIN] })
  public expandedLastStatusChangeUpdate: AssetHandoverDocumentMediaUpdate;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public uid: string;

  constructor(model: Partial<AssetHandoverDocumentMedia> = {}) {
    Object.assign(this, model);
  }
}
