const dateTimeInputFormat = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hourCycle: 'h12',
  hour: '2-digit',
  minute: '2-digit'
};

export const defaultConfiguration = {
  production: false,
  api: {
    domain: 'dev.api.mbuild.sctechnologies.co',
    protocol: 'https',
    websocketProtocol: 'wss',
    get url(): string {
      return `${this.protocol}://${this.domain}/api`;
    },
    get websocketUrl(): string {
      return `${this.websocketProtocol}://${this.domain}/edit-mode/`;
    },
    get allowedDomains(): Array<string> {
      return [this.domain];
    },
    get disallowedRoutes(): Array<string | RegExp> {
      return [
        ...[
          /media\/private\/(.*)/
        ],
        ...[
          '/password-reset/',
          '/password-reset/confirm/',
          '/password-reset/validate_token/',
          '/token/'
        ].map((endpoint) => `${this.url}${endpoint}`)
      ];
    }
  },
  openAPI: {
    url: 'https://dev.mbuild-openapi.sctechnologies.co',
    get documentationUrl(): string {
      return `${this.url}/docs`;
    },
    keyExpirationDefaultDaysCount: 28
  },
  language: {
    available: ['en'],
    default: 'en'
  },
  storeDevtools: {
    maxAge: 25
  },
  sentry: {
    dsn: 'https://bab4e0ab67c54f73a96e3986ce205106@o1248245.ingest.sentry.io/6408377',
    environment: 'local'
  },
  restorePassword: {
    resendDelay: { minutes: 1, seconds: 30 }
  },
  notifications: {
    positionClass: 'inline',
    disableTimeOut: true,
    tapToDismiss: false
  },
  avatars: {
    textSizeRatio: 2.28
  },
  leftSidebar: {
    storageKey: 'is_sidebar_closed',
    bodyClassName: 'sidebar-collapsed'
  },
  userGroupID: {
    storageKey: 'user_group_id'
  },
  requiredURL: {
    storageKey: 'required_url'
  },
  isPlansViewModeEnabled: {
    reworkAndDefects: {
      storageKey: 'is_plans_view_mode_enabled_in_rework_and_defects'
    },
    qualityIssues: {
      storageKey: 'is_plans_view_mode_enabled_in_quality_issues'
    }
  },
  dateFormats: {
    report: 'd MMM, yyyy h:mm',
    companies: 'd MMM, yyyy h:mm a',
    projectPC: 'dd MMM yyyy',
    projectInfo: 'MMM dd, yyyy',
    projectNews: 'dd MMM, yyyy',
    reportExportDate: `yyyy'-'LL'-'dd'_'HH'-'mm'-'ss`,
    historySignature: 'MMM dd, yyyy, h:mm a',
    historyHeader: 'MMM dd, yyyy',
    revision: 'MMM dd, yyyy h:mm'
  },
  datepicker: {
    dateFormats: {
      display: {
        dateInput: 'MMM d, yyyy',
        monthYearLabel: 'MMMM, yyyy'
      }
    },
    dateTimeFormats: {
      parse: {
        dateInput: dateTimeInputFormat
      },
      display: {
        dateInput: dateTimeInputFormat,
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
      }
    },
    dateOffsets: {
      default: 7
    }
  },
  materail: {
    ripples: {
      disabled: true,
      animation: {
        enterDuration: 0,
        exitDuration: 0
      }
    }
  },
  allowedFileExtensions: {
    default: ['image/jpeg', 'image/png', 'image/bmp', 'image/heic', 'image/heif'],
    subtaskAndQualityIssuesUpdate: ['*'],
    packageHandover: [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/gif',
      'video/mp4',
      'application/zip',
      'application/x-zip-compressed',
      '.ifc',
      '.dwg',
      '.rvt',
      '.nwd'
    ],
    assetsHandover: [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/gif',
      'video/mp4',
      'application/zip',
      'application/x-zip-compressed',
      '.ifc',
      '.dwg',
      '.rvt',
      '.nwd'
    ],
    locationMatrix: ['application/pdf'],
    plans: ['application/pdf'],
    packageActivities: ['image/jpeg', 'image/png', 'application/pdf']
  },
  masks: {
    cost: {
      mask: 'value',
      blocks: {
        value: {
          mask: Number,
          thousandsSeparator: ',',
          scale: 2,
          radix: '.',
          padFractionalZeros: true
        }
      }
    },
    projectLocation: {
      building: {
        mask: 'a##',
        definitions: { '#': /[a-zA-Z\d]/ }
      },
      level: {
        mask: [
          {
            mask: '[#]00a0',
            definitions: { '#': /-/ }
          },
          {
            mask: '[#]0a00',
            definitions: { '#': /-/ }
          }
        ]
      }
    }
  },
  hyperFormula: {
    licenseKey: 'internal-use-in-handsontable'
  },
  handsontable: {
    base: {
      licenseKey: 'non-commercial-and-evaluation',
      selectionMode: 'multiple',
      rowHeights: 32,
      columnHeaderHeight: 28
    },
    locations: {
      rowHeaders: false,
      filters: true,
      minSpareRows: 1,
      viewportRowRenderingOffset: 40,
      fillHandle: {
        direction: 'vertical'
      }
    },
    locationsView: {
      rowHeaders: false,
      readOnly: true,
      fillHandle: false,
      filters: true,
      viewportRowRenderingOffset: 40
    },
    packages: {
      editor: false,
      rowHeaders: false,
      colWidths: 82,
      viewportRowRenderingOffset: 40,
      autoRowSize: false,
      autoColumnSize: false
    },
    assetRegister: {
      fixedColumnsStart: 1,
      rowHeaders: true,
      colHeaders: true,
      manualColumnMove: true,
      manualRowMove: true,
      allowRemoveColumn: true,
      contextMenu: [
        'row_above',
        'row_below',
        'remove_row',
        'undo',
        'redo',
        'make_read_only',
        'alignment',
        'copy',
        'cut',
        'hidden_columns_hide',
        'hidden_columns_show',
        'hidden_rows_hide',
        'hidden_rows_show'
      ],
      dropdownMenu: [
        'clear_column',
        'make_read_only',
        'alignment',
        'filter_by_condition',
        'filter_by_value',
        'filter_action_bar'
      ],
      columnSorting: true,
      hiddenColumns: true,
      hiddenRows: true,
      filters: true,
      manualColumnResize: true,
      colWidths: 150
    },
    exportCsv: {
      bom: false,
      columnDelimiter: ',',
      columnHeaders: true,
      exportHiddenColumns: true,
      exportHiddenRows: true,
      fileExtension: 'csv',
      filename: 'CSV-file_[YYYY]-[MM]-[DD]',
      mimeType: 'text/csv',
      rowDelimiter: '\r\n'
    }
  },
  tooltips: {
    contentAttribute: 'data-tippy-content',
    locationsTable: {
      placement: 'right',
      theme: 'validation-error'
    },
    packagesTable: {
      placement: 'top',
      theme: 'custom'
    },
    actionsButtonDropdown: {
      placement: 'bottom'
    },
    plan: {
      allowHTML: true,
      hideOnClick: false,
      theme: 'light',
      placement: 'bottom-start'
    }
  },
  swiper: {
    direction: 'horizontal',
    slidesPerView: 'auto'
  },
  ngxProgress: {
    spinner: false,
    color: '#2C71D3',
    min: 0,
    max: 100
  },
  dialog: {
    disableClose: true
  },
  attachments: {
    default: {
      maxCount: 6
    }
  },
  intervals: {
    bulk: {
      default: 30000
    }
  },
  formText: {
    rework: {
      maxLength: 50
    }
  },
  company: {
    rework: {
      assignedToActivity: 1
    }
  },
  packageHandover: {
    inspectionAndMaintenance: {
      periods: [0, 1, 3, 6, 12, 18]
    }
  },
  drawableArea: {
    minSize: 10,
    scaleFactor: 1.02,
    minZoomValue: 0.0625,
    maxZoomValue: 16,
    strokeColor: '#2C71D3',
    pointColor: '#FFFFFF',
    pointFillColor: '#00A2E0',
    fillColor: '#2C71D333',
    activeFillColor: '#2C71D366',
    cornerColor: '#FCFDFD',
    lineStrokeWidth: 3,
    polygonStrokeWidth: 1,
    hoverPolygonStrokeWidth: 2,
    activePolygonStrokeWidth: 3,
    cornerSize: 8,
    customCornerSize: 10,
    customCornerStyle: 'circle',
    pointerRadius: 5,
    pointRadius: 12,
    pointScaleCoefficient: 1.33,
    pointStrokeWidth: 6,
    pointerColor: '#000000',
    pointerStrokeWidth: 1,
    offsetXProp: 4,
    offsetYProp: 5,
    rightClickButtonCode: 3,
    middleClickButtonCode: 2,
    deleteKeys: ['Delete', 'Backspace'],
    cancelKey: 'Escape',
    undoKey: 'KeyZ',
    fontFamily: 'Helvetica Neue',
    fontSize: 12,
    minFontSize: 8,
    fontColor: '#000000',
    textBackgroundColor: '#FFFFFF',
    textPadding: 6,
    duration: 300,
    leftMargin: 75,
    topMargin: 100,
    shadow: {
      blur: 6,
      offsetY: 2,
      color: 'rgba(0, 0, 0, 0.25)'
    },
    pinStatusColors: {
      inProgress: '#FFD100',
      contested: '#00A2E0',
      requestingApproval: '#AF52DE',
      requestingApprovalRejected: '#FF3B30',
      underReview: '#999999',
      removed: '#C4CDD4',
      declined: '#FF9000',
      underInspection: '#FF6800',
      closed: '#01B140'
    },
    tooltipOffset: 2
  },
  export: {
    qualityReport: {
      maxItems: {
        pdf: 1000,
        csv: 5000
      }
    },
    qualityIssues: {
      maxItems: {
        pdf: 1000,
        csv: 5000
      }
    },
    reworkAndDefects: {
      maxItems: {
        pdf: 200,
        csv: 5000
      }
    }
  },
  filters: {
    reworkAndDefects: {
      storageKey: 'filters_rework_and_defects'
    },
    qualityIssues: {
      storageKey: 'filters_quality_issues'
    }
  },
  app: {
    reloads: {
      storageKey: 'reload_count',
      maxCount: 5
    }
  },
  tinymce: {
    base: {
      base_url: '/tinymce/',
      suffix: '.min'
    },
    textarea: {
      menubar: false,
      convert_urls: false,
      elementpath: false,
      branding: false,
      plugins: 'lists link',
      toolbar: 'undo redo | bold italic bullist numlist link',
      height: 170,
      content_css: '/assets/styles/tinymce.css'
    }
  }
};
