import { Injectable } from '@angular/core';
import { FilterOptions } from '@app/account/shared/base-filter';
import { ApiService } from '@shared/api';
import { ClassGroup } from '@shared/class-group';
import { PaginationResponse } from '@shared/pagination';
import { classToPlain, plainToClassFromExist } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PackageHandoverDocumentGroupSortField } from '../enums';
import { PackageHandoverDocumentGroup } from './package-handover-document-group';
import { PackageHandoverDocumentGroupPaginationRequest } from './pagination-request';

@Injectable()
export class PackageHandoverDocumentGroupService {
  public endpoint: string;

  constructor(
    private readonly apiService: ApiService
  ) {
    this.endpoint = '/package-handover-document-groups';
  }

  public search({ page, perPage, sortBy, desc, filters }: {
    page?: number;
    perPage?: number;
    sortBy?: PackageHandoverDocumentGroupSortField;
    desc?: boolean;
    filters?: FilterOptions;
  } = {}): Observable<PaginationResponse<PackageHandoverDocumentGroup>> {
    const request = new PackageHandoverDocumentGroupPaginationRequest({ ...filters, page, perPage, sortBy, desc });

    return this.apiService.get<PaginationResponse<PackageHandoverDocumentGroup>>(
      this.endpoint,
      omitBy(
        classToPlain<PackageHandoverDocumentGroupPaginationRequest>(request), isUndefined
      )
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<PackageHandoverDocumentGroup>(PackageHandoverDocumentGroup), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }
}
