import { Exclude, Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { PackageHandoverDocumentTypeSortField } from '../enums';

export class PackageHandoverDocumentTypePaginationRequest extends PaginationRequest {
  @Expose()
  public name?: string;

  @Expose()
  public package?: number | Array<number>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'group' })
  public group?: Array<number>;

  @Expose({ name: 'user_group' })
  public userGroup?: Array<number>;

  @Expose({ name: 'configuration_mode' })
  public configurationMode?: boolean;

  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose()
  public area?: Array<string>;

  @Expose({ name: 'has_handover_documents' })
  public hasHandoverDocuments?: boolean;

  @Expose({ name: 'has_published_tasks' })
  public hasPublishedTasks?: boolean;

  @Exclude()
  public sortBy?: PackageHandoverDocumentTypeSortField;

  constructor(model: Partial<PackageHandoverDocumentTypePaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
