<div
  data-testid="dialog-alert"
  class="box popup-box">
  <div class="form">
    @if (data.text) {
      <div
        data-testid="confirm-message"
        class="popup-message">
        {{ data.text }}
      </div>
    }
  </div>
</div>
