import { PackageHandoverDocumentMediaStatusEnum } from '@shared/package-handover-document-media/enums';

export class PackageHandoverFilters {
  public package?: number;
  public search?: string;
  public packageActivity?: Array<number>;
  public documentGroup?: Array<number>;
  public documentType?: Array<number>;
  public status?: Array<PackageHandoverDocumentMediaStatusEnum>;
  public company?: Array<number>;
  public showAllGroups?: boolean;
  public showDocumentGroup?: Array<number>;

  constructor(model: Partial<PackageHandoverFilters> = {}) {
    Object.assign(this, model);
  }
}
