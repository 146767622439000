export class FloorPlanRevisionFilters {
  public search?: string;
  public comment?: string;
  public floorPlan?: number;
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<FloorPlanRevisionFilters> = {}) {
    Object.assign(this, model);
  }
}
