import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageActivityService } from './package-activity.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    PackageActivityService
  ]
})
export class PackageActivityModule { }
