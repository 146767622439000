import { Expose, Exclude } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { PackageActivitySortField } from '../enums';

export class PackageActivityPaginationRequest extends PaginationRequest {
  @Expose()
  public name?: string;

  @Exclude()
  public sortBy?: PackageActivitySortField;

  @Expose({ name: 'exclude_bound_with_project' })
  public excludeBoundWithProject?: number;

  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose()
  public area?: Array<string>;

  @Expose()
  public package?: number | Array<number>;

  @Expose()
  public project?: number;

  @Expose({ name: 'package_handover_document_company' })
  public packageHandoverDocumentCompany?: Array<number>;

  @Expose({ name: 'asset_handover_document_company' })
  public assetHandoverDocumentCompany?: Array<number>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'has_published_tasks' })
  public hasPublishedTasks?: boolean;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  @Expose({ name: 'has_package_handover_documents' })
  public hasPackageHandoverDocuments?: boolean;

  @Expose({ name: 'has_asset_handover_documents' })
  public hasAssetHandoverDocuments?: boolean;

  @Expose({ name: 'has_handover_documents' })
  public hasHandoverDocuments?: boolean;

  @Expose({ name: 'with_deleted_asset_handovers' })
  public withDeletedAssetHandovers?: boolean;

  @Expose({ name: 'document_type' })
  public documentType?: Array<number>;

  @Expose({ name: 'filter_by_company' })
  public filterByCompany?: boolean;

  @Expose({ name: 'has_asset_handovers' })
  public hasAssetHandovers?: boolean;

  @Expose({ toPlainOnly: true })
  public omit?: Array<string>;

  constructor(model: Partial<PackageActivityPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
