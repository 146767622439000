import { ApiService } from '@shared/api';
import { AuthenticatedGuard, UnauthenticatedGuard } from './guards';
import { AuthConfig } from './config';
import { CommonAuthService } from './common-auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  JWT_OPTIONS,
  JwtHelperService,
  JwtInterceptor,
  JwtModule
} from '@auth0/angular-jwt';
import { jwtOptionsFactory } from './factories';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TokenExpiredInterceptor } from './interceptors';

@NgModule({
  imports: [
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthConfig, CommonAuthService]
      }
    }),
    RouterModule,
    HttpClientModule
  ],
  providers: [
    AuthenticatedGuard,
    UnauthenticatedGuard,
    JwtInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenExpiredInterceptor,
      deps: [ApiService, AuthConfig, CommonAuthService, JwtHelperService, JwtInterceptor],
      multi: true
    }
  ]
})
export class CommonAuthModule {
  static forRoot(config: AuthConfig): ModuleWithProviders<CommonAuthModule> {
    return {
      ngModule: CommonAuthModule,
      providers: [
        { provide: AuthConfig, useValue: config },
        { provide: CommonAuthService, useExisting: config.authService }
      ]
    };
  }
}
