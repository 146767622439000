import { Exclude, Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { PackageHandoverDocumentGroupSortField } from '../enums';

export class PackageHandoverDocumentGroupPaginationRequest extends PaginationRequest {
  @Expose()
  public project?: number;

  @Expose()
  public package?: number | Array<number>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Exclude()
  public sortBy?: PackageHandoverDocumentGroupSortField;

  constructor(model: Partial<PackageHandoverDocumentGroupPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
