import { configuration } from '@configurations';
import { FloorPlanArea, FloorPlanAreaSize } from '@shared/floor-plan-area/models';
import { FloorPlanPlanLinearPointer } from '@shared/floor-plan/models';
import { fabric } from 'fabric';

export class FloorPlanAreaContext {
  public isDialogOpened: boolean;

  public initialSize: FloorPlanAreaSize;
  public imageSize: FloorPlanAreaSize;

  public drawingLinePointer: fabric.Line;
  // pointer for creating new point in edit mode
  public linearPointer: FloorPlanPlanLinearPointer;

  public drawPoints: Array<fabric.Circle>;
  public drawLines: Array<fabric.Line>;

  // keep selected on mousedown out
  public lockedPolygon: fabric.Polygon;

  public areas: Array<FloorPlanArea>;

  public building?: string;
  public level?: string;

  private _isEditMode: boolean;
  private _isDrawMode: boolean;

  constructor() {
    this._isEditMode = false;
    this._isDrawMode = false;
    this.isDialogOpened = false;
    this.drawLines = [];
    this.drawPoints = [];
    this.areas = [];
  }

  public get isEditMode(): boolean {
    return this._isEditMode;
  }

  public set isEditMode(value: boolean) {
    this._isEditMode = value;
  }

  public get isDrawMode(): boolean {
    return this._isDrawMode;
  }

  public set isDrawMode(value: boolean) {
    this._isDrawMode = value;
    this.setSelectionMode(!value);
  }

  public get content(): Array<fabric.Object> {
    return [
      ...this.drawPoints,
      ...this.drawLines,
      ...this.areas.map((area) => area.polygon),
      ...this.areas.map((area) => area.label)
    ];
  }

  public get canDrawPolygon(): boolean {
    return this.drawPoints.length > 2;
  }

  public setLinearPointerVisibility(value: boolean): void {
    this.linearPointer?.pointer.set({ visible: value });
    this.linearPointer?.leftLinePointer.set({ visible: value });
    this.linearPointer?.rightLinePointer.set({ visible: value });
  }

  public setLinearPointerCoords(center: fabric.Point, left: fabric.Point, right: fabric.Point): void {
    this.linearPointer.leftLinePointer.set({
      x1: left.x,
      y1: left.y,
      x2: center.x,
      y2: center.y
    }).setCoords();
    this.linearPointer.rightLinePointer.set({
      x1: right.x,
      y1: right.y,
      x2: center.x,
      y2: center.y
    }).setCoords();
    this.linearPointer.pointer.set({
      left: center.x,
      top: center.y
    }).setCoords();
  }

  public redrawBorders(zoom: number): void {
    this.linearPointer.pointer.set({
      radius: configuration.drawableArea.pointerRadius / zoom,
      strokeWidth: configuration.drawableArea.pointerStrokeWidth / zoom
    });
    this.linearPointer.leftLinePointer.set({
      strokeWidth: configuration.drawableArea.pointerStrokeWidth / zoom
    });
    this.linearPointer.rightLinePointer.set({
      strokeWidth: configuration.drawableArea.pointerStrokeWidth / zoom
    });
    this.drawingLinePointer.set({
      strokeWidth: configuration.drawableArea.lineStrokeWidth / zoom
    });

    for (const area of this.areas) {
      area.polygon?.set({ strokeWidth: configuration.drawableArea.polygonStrokeWidth / zoom });
      area.label?.set({
        fontSize: Math.max(configuration.drawableArea.fontSize / zoom, configuration.drawableArea.minFontSize)
      });
    }

    for (const point of this.drawPoints) {
      point.set({
        strokeWidth: configuration.drawableArea.pointerStrokeWidth / zoom,
        radius: configuration.drawableArea.pointerRadius / zoom
      });
    }

    for (const line of this.drawLines) {
      line.set({ strokeWidth: configuration.drawableArea.lineStrokeWidth / zoom });
    }
  }

  public refreshDrawingLine(): void {
    if (!this.drawPoints.length) {
      this.drawingLinePointer.set({ visible: false });

      return;
    }

    const lastPoint = this.drawPoints[this.drawPoints.length - 1];

    this.drawingLinePointer.set({
      x1: lastPoint.get('left'),
      y1: lastPoint.get('top')
    }).setCoords();
  }

  public setSelectionMode(value: boolean): void {
    this.areas.forEach((area) => {
      area.polygon?.set({
        selectable: value,
        evented: value
      });
    });
  }

  public findAreaByName(name: string): FloorPlanArea {
    return this.areas.find((item) => item.area === name && !item.isDeleted);
  }

  public findArea(uuid: string): FloorPlanArea {
    return this.areas.find((area) => area.uuid === uuid);
  }

  public deleteArea(area: FloorPlanArea): void {
    if (!area) {
      return;
    }

    if (!area.isCreated) {
      area.isDeleted = true;

      return;
    }

    this.areas = this.areas.filter((item) => item !== area);
  }

  public clear(): void {
    this.drawPoints = [];
    this.drawLines = [];
    this.drawingLinePointer?.set({ visible: false });
  }

  public clearAll(): void {
    this.clear();
    this.areas = this.areas.filter((item) => !item.isCreated);
    this.areas.forEach((item) => {
      item.isDeleted = true;
      item.polygon = undefined;
    });
  }

  public reset(): void {
    this.clear();
    this.areas = [];
  }
}
