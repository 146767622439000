import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { PackageHandoverDocumentMediaStatusEnum } from '../enums';

export class PackageHandoverDocumentMediaPaginationRequest extends PaginationRequest {
  @Expose()
  public limit?: number;

  @Expose()
  public offset?: number;

  @Expose()
  public package?: number;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'package_handover_document_group' })
  public packageHandoverDocumentGroup?: Array<number>;

  @Expose({ name: 'package_handover_document_type' })
  public packageHandoverDocumentType?: Array<number>;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  @Expose({ name: 'use_limit_offset_pagination' })
  public useLimitOffsetPagination?: boolean;

  @Expose()
  public status?: Array<PackageHandoverDocumentMediaStatusEnum>;

  constructor(model: Partial<PackageHandoverDocumentMediaPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
