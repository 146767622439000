import { AuthConfig } from '../config';
import { CommonAuthService } from '../common-auth.service';
import { take } from 'rxjs/operators';
import { AbstractUser } from '@shared/common-user';

export function jwtOptionsFactory(config: AuthConfig, authService: CommonAuthService<AbstractUser>): object {
  return {
    tokenGetter: () => {
      return authService.token$
        .pipe(take(1))
        .toPromise();
    },
    allowedDomains: config.allowedDomains,
    disallowedRoutes: config.disallowedRoutes
  };
}
