export enum QualityIssueSortField {
  ID = 'id',
  BUILDING = 'building',
  LEVEL = 'level',
  AREA = 'area',
  STATUS = 'status',
  DESCRIPTION = 'description',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
  DEFAULT_SORT = 'default_sort',
  DUE_DATE = 'due_date',
  RESPONSE_CATEGORY = 'response_category'
}
