import { Expose } from 'class-transformer';

export class PackageMatrixCompanyAddRequest {
  @Expose({ name: 'package_matrix' })
  public packageMatrixID: number;

  @Expose()
  public company: number;

  constructor(model: Partial<PackageMatrixCompanyAddRequest> = {}) {
    Object.assign(this, model);
  }
}
