import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationComponent } from './pagination.component';
import { TranslateModule } from '@ngx-translate/core';
import { LetDirective, PushPipe } from '@ngrx/component';

@NgModule({
  declarations: [
    PaginationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxPaginationModule,
    LetDirective, PushPipe
  ],
  exports: [
    PaginationComponent,
    NgxPaginationModule
  ]
})
export class PaginationModule { }
