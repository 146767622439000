import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageHandoverDocumentGroupService } from './package-handover-document-group.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    PackageHandoverDocumentGroupService
  ]
})
export class PackageHandoverDocumentGroupModule { }
