import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { Media } from './media';

export class MediaThumbnail {
  @Type(() => Number)
  @Expose()
  public id: number;

  @Expose({ name: 'original_media' })
  public originalMedia: number;

  @Expose()
  public thumbnail: number;

  @Expose()
  public height: number;

  @Expose()
  public width: number;

  @Type(() => Media)
  @Expose({ name: 'expanded_thumbnail' })
  public expandedThumbnail?: Media;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at' })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at' })
  public updatedAt: DateTime;

  constructor(model: Partial<MediaThumbnail> = {}) {
    Object.assign(this, model);
  }
}
