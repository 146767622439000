import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ClassGroup } from '@shared/class-group';
import { DateTime } from 'luxon';
import { MediaThumbnail } from './thumbnail';
import { FloorPlanAreaPinEnum } from '@shared/floor-plan-area-pin';

export class Media {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public name: string;

  @Expose({ groups: [ClassGroup.MAIN] })
  public link: string;

  @Expose({ groups: [ClassGroup.MAIN] })
  public size: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public hash: string;

  @Expose({ groups: [ClassGroup.MAIN] })
  public type: FloorPlanAreaPinEnum;

  @Expose({ groups: [ClassGroup.MAIN] })
  public extension: string;

  @Expose({ name: 'is_public', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public isPublic: boolean;

  @Type(() => Object)
  @Transform(({ obj }) => obj.file)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public file: File;

  @Type(() => MediaThumbnail)
  @Expose({ name: 'expanded_thumbnails', groups: [ClassGroup.MAIN] })
  public expandedThumbnails: Array<MediaThumbnail>;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  @Exclude()
  public get thumbnail(): string {
    return this.expandedThumbnails?.[0]?.expandedThumbnail?.link;
  }

  constructor(model: Partial<Media> = {}) {
    Object.assign(this, model);
  }
}
