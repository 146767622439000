import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { AssetHandoverDocumentMediaStatusEnum } from '../enums';

export class AssetHandoverDocumentMediaPaginationRequest extends PaginationRequest {
  @Expose()
  public limit?: number;

  @Expose()
  public offset?: number;

  @Expose()
  public package?: number;

  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose()
  public area?: Array<string>;

  @Expose()
  public company?: Array<number>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose()
  public status?: Array<AssetHandoverDocumentMediaStatusEnum>;

  @Expose({ name: 'asset_handover' })
  public assetHandover?: number;

  @Expose({ name: 'asset_handover_document_type' })
  public assetHandoverDocumentType?: number;

  @Expose({ name: 'use_limit_offset_pagination' })
  public useLimitOffsetPagination?: boolean;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<AssetHandoverDocumentMediaPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
