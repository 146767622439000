import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '@shared/class-group';

export class HandoverDocumentType {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public name: string;

  @Type(() => Number)
  @Expose({ name: 'group', groups: [ClassGroup.MAIN] })
  public groupID: number;

  constructor(model: Partial<HandoverDocumentType> = {}) {
    Object.assign(this, model);
  }
}
