import { AppState } from '@shared/store';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AccountProjectsDetailsPageState } from './state';
import { Project } from '@shared/project';

const selectFeature = (state: AppState) => state.accountProjectsDetailsPage;

export class AccountProjectsDetailsPageSelectors {
  public static currentProject: MemoizedSelector<AppState, Project> = createSelector(
    selectFeature,
    (state: AccountProjectsDetailsPageState) => state.currentProject
  );

  public static isAccessDeniedShown: MemoizedSelector<AppState, boolean> = createSelector(
    selectFeature,
    (state: AccountProjectsDetailsPageState) => state.isAccessDeniedShown
  );
}
