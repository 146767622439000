import { CommonUserService } from './common-user.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserConfig } from './config';

@NgModule()
export class CommonUserModule {
  static forRoot(config: UserConfig): ModuleWithProviders<CommonUserModule> {
    return {
      ngModule: CommonUserModule,
      providers: [
        { provide: UserConfig, useValue: config },
        { provide: CommonUserService, useExisting: config.userService }
      ]
    };
  }
}
