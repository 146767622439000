import { PaginationRequest } from '@shared/pagination';
import { QualityIssueStatusEnum } from '@shared/quality-issue';
import { SubtaskStatus } from '@shared/subtask';
import { Exclude, Expose, Transform } from 'class-transformer';
import { DateTime } from 'luxon';
import { FloorPlanSortField } from '../enums';

export class FloorPlanPaginationRequest extends PaginationRequest {
  @Expose()
  public id?: number;

  @Expose()
  public package?: Array<number>;

  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  @Expose({ name: 'has_floor_plan_area' })
  public hasFloorPlanArea?: boolean;

  @Expose()
  @Transform(({ value }) => value && value.includes(QualityIssueStatusEnum.IN_PROGRESS)
    ? [...value, QualityIssueStatusEnum.INSPECTION_REJECTED]
    : value
  , { toPlainOnly: true })
  public status?: Array<QualityIssueStatusEnum | SubtaskStatus>;

  @Expose({ name: 'subtask_is_defect' })
  public isDefect?: boolean;

  @Expose()
  public user?: Array<number>;

  @Exclude()
  public sortBy?: FloorPlanSortField;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'created_at__gte' })
  public startDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value
    ?.endOf('day')
    ?.startOf('second')
    ?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'created_at__lte' })
  public endDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'updated_at__gte' })
  public updatedAtStartDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value
    ?.endOf('day')
    ?.startOf('second')
    ?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'updated_at__lte' })
  public updatedAtEndDate: DateTime;

  constructor(model: Partial<FloorPlanPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
