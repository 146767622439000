import { Expose } from 'class-transformer';

export class ProjectKeyContactsRequest {
  @Expose({ name: 'key_contacts' })
  public keyContacts: Array<number>;

  constructor(model: Partial<ProjectKeyContactsRequest> = {}) {
    Object.assign(this, model);
  }
}
