import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectNewsService } from './project-news.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ProjectNewsService
  ]
})
export class ProjectNewsModule { }
