export class FilterOptions {
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public package?: Array<number>;
  public packageActivity?: Array<number>;
  public group?: Array<number>;
  public hasPublishedTasks?: boolean;
  public hasPackageHandoverDocuments?: boolean;
  public excludeWhereAssetHandoverExist?: boolean;
  public project?: number;

  constructor(model: Partial<FilterOptions> = {}) {
    Object.assign(this, model);
  }
}
