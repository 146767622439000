import { AssetHandoverDocumentMediaStatusEnum } from '@shared/asset-handover-document-media';

export class AssetHandoverFilters {
  public package?: number;
  public packageActivity?: Array<number>;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public status?: Array<AssetHandoverDocumentMediaStatusEnum>;
  public useLimitOffsetPagination?: boolean;
  public company?: Array<number>;

  constructor(model: Partial<AssetHandoverFilters> = {}) {
    Object.assign(this, model);
  }
}
