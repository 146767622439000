import { QualityIssueStatusEnum } from '../enums';
import { DateTime } from 'luxon';

export class QualityIssueFilters {
  public search?: string;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public status?: Array<QualityIssueStatusEnum>;
  public isDefect?: boolean;
  public user?: Array<number>;
  public startDate?: DateTime;
  public endDate?: DateTime;
  public updatedAtStartDate?: DateTime;
  public updatedAtEndDate?: DateTime;
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<QualityIssueFilters> = {}) {
    Object.assign(this, model);
  }
}
