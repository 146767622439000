import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoConnectionInterceptor } from './interceptors';
import { NotificationModule } from '@shared/notification';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    NotificationModule,
    TranslateModule
  ],
  providers: [
    NoConnectionInterceptor
  ]
})
export class NoConnectionModule { }
