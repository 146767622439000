import { Expose } from 'class-transformer';

export class ProjectUsersRequest {
  @Expose()
  public users: Array<number>;

  constructor(model: Partial<ProjectUsersRequest> = {}) {
    Object.assign(this, model);
  }
}
