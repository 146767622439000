import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/notification';
import { Observable, tap } from 'rxjs';

@Injectable()
export class NoConnectionInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap(
        () => {},
        (response: HttpErrorResponse) => {
          if (response.status === 0) {
            const errorMessage = this.translateService.instant('SHARED.ERRORS.TEXT_NO_CONNECTION');

            this.notificationService.error(errorMessage);
          }
        }
      )
    );
  }
}
