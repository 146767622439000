import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectService } from './project.service';
import { ProjectClientSubtaskAccessGuard } from './guards';
import { ProjectClientTaskBreadcrumbResolver } from './resolvers';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ProjectService,
    ProjectClientSubtaskAccessGuard,
    ProjectClientTaskBreadcrumbResolver
  ]
})
export class ProjectModule { }
