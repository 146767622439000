import { ApiService } from '@shared/api';
import { Injectable } from '@angular/core';
import { Package, PackageFilters, PackagePaginationRequest } from './models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClassGroup } from '@shared/class-group';
import { PaginationResponse } from '@shared/pagination';
import { PackageSortField } from './enums';
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { PackageRelationType } from './types';
import { FileType } from '@shared/file-save';

@Injectable()
export class PackageService {
  public endpoint: string;

  constructor(
    private readonly apiService: ApiService
  ) {
    this.endpoint = '/packages';
  }

  public search({ page, perPage, sortBy, desc, all, filters, relations }: {
    page?: number;
    perPage?: number;
    sortBy?: PackageSortField;
    desc?: boolean;
    all?: boolean;
    filters?: PackageFilters;
    relations?: Array<PackageRelationType>;
  } = {}): Observable<PaginationResponse<Package>> {
    const request = new PackagePaginationRequest({ ...filters, page, perPage, sortBy, desc, all, relations });

    return this.apiService
      .get<PaginationResponse<Package>>(this.endpoint, omitBy(classToPlain<PackagePaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(new PaginationResponse<Package>(Package), response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public upsert(packages: Array<Package>, sortBy?: PackageSortField, relations?: Array<PackageRelationType>): Observable<Array<Package>> {
    const params = { sort: sortBy, expand: relations };

    return this.apiService.put<Array<Package>>(
      this.endpoint,
      classToPlain(packages, { groups: [ClassGroup.UPDATING] }),
      { params: omitBy(params, isUndefined) }
    )
      .pipe(
        map((response) => plainToClass(Package, response, { groups: [ClassGroup.UPDATING] }))
      );
  }

  public generatePackageReport(type: FileType, { sortBy }: {
    sortBy?: PackageSortField;
  } = {}): Observable<void> {
    const request = new PackagePaginationRequest({ sortBy });

    return this.apiService.get(
      `${this.endpoint}/${type}`,
      omitBy(classToPlain<PackagePaginationRequest>(request), isUndefined)
    );
  }
}
