import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';

export class LocationMatrixPaginationRequest extends PaginationRequest {
  @Expose()
  public building?: string;

  @Expose()
  public level?: string;

  @Expose()
  public area?: string;

  constructor(model: Partial<LocationMatrixPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
