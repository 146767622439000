import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompanyService } from './company.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    CompanyService
  ]
})
export class CompanyModule { }
