import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard, UnauthenticatedGuard } from '@shared/auth';
import { IsApprovedUserGuard } from '@shared/user';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'log-in',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [UnauthenticatedGuard],
    loadChildren: () => import('./public/public.module').then((module) => module.PublicModule)
  },
  {
    path: 'account',
    canActivate: [AuthenticatedGuard, IsApprovedUserGuard],
    loadChildren: () => import('./account/account.module').then((module) => module.AccountModule)
  },
  {
    path: '**',
    loadChildren: () => import('./public/not-found/not-found.module').then((module) => module.PublicNotFoundPageModule),
    data: { meta: { title: 'SHARED.META.TAB_TITLE.NOT_FOUND' } }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
