import { ApiService } from '@shared/api';
import { Injectable } from '@angular/core';
import { ProjectNews } from './models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClassGroup } from '@shared/class-group';
import { classToPlain, plainToClass } from 'class-transformer';

@Injectable()
export class ProjectNewsService {
  public endpoint: string;

  constructor(
    private readonly apiService: ApiService
  ) {
    this.endpoint = '/project-news';
  }

  public create(projectNews: ProjectNews): Observable<ProjectNews> {
    return this.apiService
      .post<ProjectNews>(this.endpoint, classToPlain(projectNews, { groups: [ClassGroup.CREATING] }))
      .pipe(
        map((response) => plainToClass(ProjectNews, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public update(projectNews: ProjectNews): Observable<void> {
    return this.apiService.put(`${this.endpoint}/${projectNews.id}`, classToPlain(projectNews, { groups: [ClassGroup.UPDATING] }));
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }
}
