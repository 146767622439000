import { Expose, Transform, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { DateTime } from 'luxon';
import { Recipient } from '@shared/recipient';
import { SubtaskStatus } from '../enums';
import { Subtask } from './subtask';
import { Media } from '@shared/media';
import { User } from '@shared/user';

export class SubtaskUpdate {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public task: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public status: SubtaskStatus;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public description: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public comment: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public estimation: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public company: number;

  @Expose({ name: 'quality_issue', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public qualityIssue: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public files: Array<number>;

  @Type(() => Media)
  @Expose({ name: 'expanded_files', groups: [ClassGroup.MAIN] })
  public expandedFiles: Array<Media>;

  @Expose({ groups: [ClassGroup.MAIN] })
  public user: number;

  @Type(() => User)
  @Expose({ name: 'expanded_user', groups: [ClassGroup.MAIN] })
  public expandedUser: User;

  @Type(() => Subtask)
  @Expose({ name: 'old_data', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public oldData: Partial<Subtask>;

  @Type(() => Subtask)
  @Expose({ name: 'new_data', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public newData: Partial<Subtask>;

  @Type(() => Recipient)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public recipients: Array<Recipient>;

  @Type(() => Boolean)
  @Expose({ name: 'is_comment', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public isComment: boolean;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO(), { toPlainOnly: true, groups: [ClassGroup.CREATING] })
  @Expose({ name: 'due_date', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public dueDate: DateTime;

  constructor(model: Partial<SubtaskUpdate> = {}) {
    Object.assign(this, model);
  }
}
