import { PackageHandoverDocumentGroup } from '@shared/package-handover-document-group';
import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';

export class PackageHandoverDocumentType {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public name: string;

  @Type(() => Number)
  @Expose({ name: 'group', groups: [ClassGroup.MAIN] })
  public groupID: number;

  @Type(() => PackageHandoverDocumentGroup)
  @Expose({ name: 'expanded_group', groups: [ClassGroup.MAIN] })
  public expandedGroup: PackageHandoverDocumentGroup;

  constructor(model: Partial<PackageHandoverDocumentType> = {}) {
    Object.assign(this, model);
  }
}
