import { ClassGroup } from '@shared/class-group';
import { PackageHandoverDocumentMediaStatusEnum } from '@shared/package-handover-document-media/enums';
import { Type, Expose } from 'class-transformer';

export class PackageHandoverStatistics {
  @Type(() => Number)
  @Expose({ name: 'accepted_count', groups: [ClassGroup.MAIN] })
  public [PackageHandoverDocumentMediaStatusEnum.ACCEPTED]: number;

  @Type(() => Number)
  @Expose({ name: 'in_progress_count', groups: [ClassGroup.MAIN] })
  public [PackageHandoverDocumentMediaStatusEnum.IN_PROGRESS]?: number;

  @Type(() => Number)
  @Expose({ name: 'contested_count', groups: [ClassGroup.MAIN] })
  public [PackageHandoverDocumentMediaStatusEnum.CONTESTED]?: number;

  @Type(() => Number)
  @Expose({ name: 'requested_approval_rejected_count', groups: [ClassGroup.MAIN] })
  public [PackageHandoverDocumentMediaStatusEnum.REQUESTED_APPROVAL_REJECTED]?: number;

  @Type(() => Number)
  @Expose({ name: 'requesting_approval_count', groups: [ClassGroup.MAIN] })
  public [PackageHandoverDocumentMediaStatusEnum.REQUESTING_APPROVAL]?: number;

  constructor(model: Partial<PackageHandoverStatistics> = {}) {
    Object.assign(this, model);
  }
}
