import { PaginationRequest } from '@shared/pagination';
import { Expose } from 'class-transformer';

export class FloorPlanRevisionPaginationRequest extends PaginationRequest {
  @Expose({ name: 'floor_plan' })
  public floorPlan?: number;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<FloorPlanRevisionPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
