import { FloorPlan } from '@shared/floor-plan/models';
import { Exclude, Expose, Type } from 'class-transformer';
import { fabric } from 'fabric';

export class FloorPlanArea {
  @Type(() => Number)
  @Expose()
  public id?: number;

  @Expose({ name: 'floor_plan' })
  public floorPlan?: number;

  @Type(() => FloorPlan)
  @Expose({ name: 'expanded_floor_plan' })
  public expandedFloorPlan?: FloorPlan;

  @Expose()
  public polygon: fabric.Polygon;

  @Expose()
  public area: string;

  @Exclude()
  public uuid: string;

  @Exclude()
  public label?: fabric.IText;

  @Exclude()
  public isCreated?: boolean;

  @Exclude()
  public isUpdated?: boolean;

  @Exclude()
  public isDeleted?: boolean;

  constructor(model: Partial<FloorPlanArea> = {}) {
    Object.assign(this, model);
  }
}
