import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { DateTime } from 'luxon';

export class Recipient {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ name: 'first_name', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public firstName: string;

  @Expose({ name: 'last_name', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public lastName: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public email: string;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public user: number;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  @Exclude()
  public get fullName(): string {
    return this.firstName?.trim()
      ? this.lastName?.trim()
        ? `${this.firstName} ${this.lastName}`
        : this.firstName
      : this.lastName?.trim()
        ? this.lastName
        : this.email;
  }

  constructor(model: Partial<Recipient> = {}) {
    Object.assign(this, model);
  }
}
