import { Media } from '@shared/media';
import { PackageHandoverDocumentMediaUpdate } from '@shared/package-handover-document-media-update';
import { PackageHandoverDocument } from '@shared/package-handover-document/models';
import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { PackageHandoverDocumentMediaStatusEnum } from '../enums';

export class PackageHandoverDocumentMedia {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => Number)
  @Expose({ name: 'package_handover_document', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public packageHandoverDocument: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public media: number;

  @Type(() => String)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public status: PackageHandoverDocumentMediaStatusEnum;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public uid: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public information: string;

  @Type(() => Media)
  @Expose({ name: 'expanded_media', groups: [ClassGroup.MAIN] })
  public expandedMedia: Media;

  @Type(() => PackageHandoverDocument)
  @Expose({ name: 'expanded_package_handover_document', groups: [ClassGroup.MAIN] })
  public expandedPackageHandoverDocument: PackageHandoverDocument;

  @Type(() => PackageHandoverDocumentMediaUpdate)
  @Expose({ name: 'expanded_upload_update', groups: [ClassGroup.MAIN] })
  public expandedUploadUpdate: PackageHandoverDocumentMediaUpdate;

  @Type(() => PackageHandoverDocumentMediaUpdate)
  @Expose({ name: 'expanded_last_confirmed_update', groups: [ClassGroup.MAIN] })
  public expandedLastConfirmedUpdate: PackageHandoverDocumentMediaUpdate;

  @Type(() => PackageHandoverDocumentMediaUpdate)
  @Expose({ name: 'expanded_last_status_change_update', groups: [ClassGroup.MAIN] })
  public expandedLastStatusChangeUpdate: PackageHandoverDocumentMediaUpdate;

  constructor(model: Partial<PackageHandoverDocumentMedia> = {}) {
    Object.assign(this, model);
  }
}
