import { ApiService } from '@shared/api';
import { Injectable } from '@angular/core';
import {
  Project,
  ProjectAreaPaginationRequest,
  ProjectAssetHandoverDocumentMediaBulkRequest,
  ProjectBuildingPaginationRequest,
  ProjectFilters,
  ProjectFloorPlanThumbnailRequest,
  ProjectKeyContactsRequest,
  ProjectLevelPaginationRequest,
  ProjectLocationMatrixExportRequest,
  ProjectMediaRequest,
  ProjectPaginationRequest,
  ProjectRecipientPaginationRequest,
  ProjectUsersRequest
} from './models';
import { Observable, of } from 'rxjs';
import { filter as filterObservable, map, switchMap, take, tap } from 'rxjs/operators';
import { ClassGroup } from '@shared/class-group';
import { PaginationData, PaginationRequest, PaginationResponse } from '@shared/pagination';
import { ProjectSortField } from './enums';
import { classToPlain, instanceToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { filter, find, flatten, groupBy, isUndefined, mapValues, omitBy, sortBy as sortByFn } from 'lodash';
import { ProjectRelationType } from './types';
import { Package, PackageFilters, PackagePaginationRequest, PackageSortField } from '@shared/package';
import { PackageActivity, PackageActivityRelationType } from '@shared/package-activity';
import { PackageActivityTask } from '@shared/package-activity-task';
import { PackageMatrix, PackageMatrixCreation } from '@shared/package-matrix';
import { Task, TaskFilters, TaskPaginationRequest, TaskRelationType, TaskSortField } from '@shared/task';
import {
  LocationMatrix,
  LocationMatrixFilters,
  LocationMatrixPackage,
  LocationMatrixPackageFilters,
  LocationMatrixPackageRelationType,
  LocationMatrixPackagesData,
  LocationMatrixPaginationRequest
} from '@shared/location-matrix';
import { EditModeRelationType } from '@app/account/shared/page-mode-toggle/types';
import { EditMode } from '@app/account/shared/page-mode-toggle/models';
import { FilterMode, FilterOptions } from '@app/account/shared/base-filter';
import {
  Subtask,
  SubtaskFilters,
  SubtaskPaginationRequest,
  SubtaskRelationType,
  SubtaskSortField,
  SubtaskUpdate,
  SubtaskUpdateRelationType,
  SubtaskUpdatesPaginationRequest
} from '@shared/subtask';
import { FileType } from '@shared/file-save';
import { QrCodeRequest } from '@shared/qr-code';
import { HttpParams } from '@angular/common/http';
import { AuthService } from '@shared/auth/auth.service';
import { User } from '@shared/user';
import { Recipient, RecipientFilters } from '@shared/recipient';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  QualityIssue,
  QualityIssueBulkData,
  QualityIssueFilters,
  QualityIssuePaginationRequest,
  QualityIssueRelationType,
  QualityIssueUpdate,
  QualityIssueUpdateRelationType,
  QualityIssueUpdatesPaginationRequest
} from '@shared/quality-issue';
import { QualityIssueSortField, QualityIssueStatusEnum } from '@shared/quality-issue/enums';
import {
  PackageMatrixCompany,
  PackageMatrixCompanyAddRequest,
  PackageMatrixCompanyFilters,
  PackageMatrixCompanyPaginationRequest,
  PackageMatrixCompanyRelationType
} from '@shared/package-matrix-company';
import { Company, CompanyFilters, CompanyPaginationRequest } from '@shared/company';
import { AreaSelectFilters } from '@app/account/shared/area-select/models';
import { LevelSelectFilters } from '@app/account/shared/level-select/models';
import { SubtaskCounter } from '@shared/subtask/models/subtask-counter';
import {
  PackageHandover,
  PackageHandoverFilters,
  PackageHandoverPaginationRequest,
  PackageHandoverRelationType,
  PackageHandoverSortField
} from '@shared/package-handover';
import { ProjectPackageHandoverDocumentMediaBulkRequest } from './models/package-handover-document-media-bulk-request';
import { PackageHandoverDocument, PackageHandoverDocumentFilters, PackageHandoverDocumentPaginationRequest, PackageHandoverDocumentRelationType } from '@shared/package-handover-document';
import { PackageHandoverDocumentMedia, PackageHandoverDocumentMediaPaginationRequest, PackageHandoverDocumentMediaRelationType } from '@shared/package-handover-document-media';
import {
  PackageHandoverDocumentMediaUpdate,
  PackageHandoverDocumentMediaUpdateRelationType
} from '@shared/package-handover-document-media-update';
import { ProjectResponseCategory } from '@shared/project-response-category/models/response-category';
import { PackageHandoverDocumentMediaUpdateSortField } from '@shared/package-handover-document-media-update/enums';
import { PackageHandoverDocumentType, PackageHandoverDocumentTypeFilters, PackageHandoverDocumentTypePaginationRequest, PackageHandoverDocumentTypeRelationType } from '@shared/package-handover-document-type';
import { AssetHandover, AssetHandoverFilters, AssetHandoverPaginationRequest, AssetHandoverRelationType, AssetHandoverSortField } from '@shared/asset-handover';
import { FloorPlan, FloorPlanFilters, FloorPlanRelationType, FloorPlanSortField, FloorPlanPaginationRequest } from '@shared/floor-plan';
import { AssetHandoverDocumentMedia, AssetHandoverDocumentMediaFilters, AssetHandoverDocumentMediaPaginationRequest, AssetHandoverDocumentMediaRelationType } from '@shared/asset-handover-document-media';
import { AssetHandoverDocumentMediaUpdate, AssetHandoverDocumentMediaUpdateRelationType } from '@shared/asset-handover-document-media-update';
import { AccountProjectsDetailsPlansDrawAreasNodeData } from '@app/account/projects/details-plans/draw-areas/shared/models';
import { AssetHandoverDocumentMediaUpdateSortField } from '@shared/asset-handover-document-media-update/enums/sort-field';
import { AssetHandoverInformation } from '@shared/asset-handover-information';
import { HandoverDocument, HandoverDocumentFilters, HandoverDocumentPaginationRequest, HandoverDocumentGenerateDownloadLinkRequest } from '@shared/handover-document';
import { AssetHandoverStatistics, AssetHandoverStatisticsRequest } from '@shared/asset-handover-statistics';
import { PackageHandoverDocumentMediaFilters } from '@shared/package-handover-document-media/models/filters';
import { PackageHandoverStatistics } from '@shared/package-handover-statistics';
import { HandoverDocumentRelationType } from '@shared/handover-document/types/relations';
import { AssetHandoverBulkItem } from '@shared/asset-handover-bulk-item';
import { QualityIssueCounter } from '@shared/quality-issue/models/quality-issue-counter';
import { AssetRegister } from '@shared/asset-register';
import { FloorPlanArea, FloorPlanAreaFilters, FloorPlanAreaPaginationRequest } from '@shared/floor-plan-area/models';
import { FloorPlanAreaBulk } from '@shared/floor-plan-area/models/bulk';
import { AccountProjectsDetailsLocationMatrixTabEnum } from '@app/account/projects/details-location-matrix/shared/enums';
import { FloorPlanRevision, FloorPlanRevisionFilters, FloorPlanRevisionPaginationRequest, FloorPlanRevisionRelationType } from '@shared/floor-plan-revision';
import { Media } from '@shared/media';
import { FloorPlanAreaPinRelationType } from '@shared/floor-plan-area-pin/types/relations';
import { FloorPlanAreaPin } from '@shared/floor-plan-area-pin';

@Injectable()
export class ProjectService {
  public endpoint: string;

  constructor(
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
    private jwtHelperService: JwtHelperService
  ) {
    this.endpoint = '/projects';
  }

  public search({ page, perPage, filters, sortBy, desc, relations }: {
    page?: number;
    perPage?: number;
    filters?: ProjectFilters;
    sortBy?: ProjectSortField;
    desc?: boolean;
    relations?: Array<ProjectRelationType>;
  } = {}): Observable<PaginationResponse<Project> | PaginationData> {
    const request = new ProjectPaginationRequest({ ...filters, page, perPage, sortBy, desc, relations });

    return this.apiService
      .get<PaginationResponse<Project>>(this.endpoint, omitBy(classToPlain<ProjectPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<Project>(Project), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public create(project: Project): Observable<Project> {
    return this.apiService
      .post<Project>(this.endpoint, omitBy(classToPlain(project, { groups: [ClassGroup.CREATING] }), isUndefined))
      .pipe(
        map((response) => plainToClass(Project, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public get(id: number, relations?: Array<ProjectRelationType>): Observable<Project> {
    return this.apiService
      .get<Project>(`${this.endpoint}/${id}`, omitBy({ expand: relations }, isUndefined))
      .pipe(
        map((response) => plainToClass(Project, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public update(project: Project): Observable<void> {
    return this.apiService.put(`${this.endpoint}/${project.id}`, omitBy(classToPlain(project, { groups: [ClassGroup.UPDATING] }), isUndefined));
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }

  public addContacts(id: number, contacts: Array<number>): Observable<void> {
    const request = new ProjectKeyContactsRequest({ keyContacts: contacts });

    return this.apiService
      .post(`${this.endpoint}/${id}/key-contacts/add`, classToPlain<ProjectKeyContactsRequest>(request));
  }

  public removeContacts(id: number, contactID: number): Observable<void> {
    const request = new ProjectKeyContactsRequest({ keyContacts: [contactID] });

    return this.apiService
      .post(`${this.endpoint}/${id}/key-contacts/remove`, classToPlain<ProjectKeyContactsRequest>(request));
  }

  public searchResponseCategories(id: number, { page, perPage, desc }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
  } = {}): Observable<PaginationResponse<ProjectResponseCategory>> {
    const request = new PaginationRequest({ page, perPage, desc });

    return this.apiService
      .get<PaginationResponse<ProjectResponseCategory>>(`${this.endpoint}/${id}/response-categories`, omitBy(classToPlain<PaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<ProjectResponseCategory>(ProjectResponseCategory), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public removeResponseCategory(id: number, responseCategoryID: number): Observable<void> {
    return this.apiService
      .delete(`${this.endpoint}/${id}/response-categories/${responseCategoryID}`);
  }

  public addUsers(id: number, users: Array<number>): Observable<void> {
    const request = new ProjectUsersRequest({ users });

    return this.apiService
      .post(`${this.endpoint}/${id}/users/add`, classToPlain<ProjectUsersRequest>(request));
  }

  public removeUsers(id: number, users: Array<number>): Observable<void> {
    const request = new ProjectUsersRequest({ users });

    return this.apiService.post(`${this.endpoint}/${id}/users/remove`, classToPlain<ProjectUsersRequest>(request));
  }

  public getPackages(id: number): Observable<Array<Package>> {
    return this.searchPackageMatrices(id, { all: true, desc: false })
      .pipe(
        map((matrices) => this.mapPackageMatrixData(matrices))
      );
  }

  public getSubtasksCount(id: number): Observable<SubtaskCounter> {
    return this.apiService
      .get<SubtaskCounter>(`${this.endpoint}/${id}/subtasks/count`)
      .pipe(
        map((response) => plainToClass(SubtaskCounter, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public getQualityIssueCounter(id: number): Observable<QualityIssueCounter> {
    return this.apiService
      .get<QualityIssueCounter>(`${this.endpoint}/${id}/quality-issues/count`)
      .pipe(
        map((response) => plainToClass(QualityIssueCounter, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public getPackageHandovers(projectID: number, {
    all,
    filters,
    sortBy,
    desc,
    relations,
    limit,
    offset
  }: {
    all?: boolean;
    filters?: PackageHandoverFilters;
    sortBy?: PackageHandoverSortField;
    desc?: boolean;
    relations?: Array<PackageHandoverRelationType>;
    limit?: number;
    offset?: number;
  } = {}): Observable<PaginationResponse<PackageHandover>> {
    const request = new PackageHandoverPaginationRequest({ ...filters, all, sortBy, desc, limit, offset, relations });

    return this.apiService
      .get<PaginationResponse<PackageHandover>>(`${this.endpoint}/${projectID}/package-handovers`, omitBy(classToPlain<PaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<PackageHandover>(PackageHandover), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public getPackageHandoverMedia(projectID: number, {
    all,
    page,
    perPage,
    filters,
    desc,
    relations,
    limit,
    offset
  }: {
    all?: boolean;
    page?: number;
    perPage?: number;
    filters?: PackageHandoverDocumentMediaFilters;
    desc?: boolean;
    relations?: Array<PackageHandoverDocumentMediaRelationType>;
    limit?: number;
    offset?: number;
  } = {}): Observable<PaginationResponse<PackageHandoverDocumentMedia> | PaginationData> {
    const request = new PackageHandoverDocumentMediaPaginationRequest({ ...filters, page, perPage, all, desc, limit, offset, relations });

    return this.apiService.get<PaginationResponse<PackageHandoverDocumentMedia> | PaginationData>(
      `${this.endpoint}/${projectID}/package-handover-document-media`,
      omitBy(classToPlain<PackageHandoverDocumentMediaPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<PackageHandoverDocumentMedia>(PackageHandoverDocumentMedia), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public getAssetHandoverMedia(projectID: number, {
    all,
    page,
    perPage,
    filters,
    desc,
    relations,
    limit,
    offset
  }: {
    all?: boolean;
    page?: number;
    perPage?: number;
    filters?: AssetHandoverDocumentMediaFilters;
    desc?: boolean;
    relations?: Array<AssetHandoverDocumentMediaRelationType>;
    limit?: number;
    offset?: number;
  } = {}): Observable<PaginationResponse<AssetHandoverDocumentMedia> | PaginationData> {
    const request = new AssetHandoverDocumentMediaPaginationRequest({ ...filters, all, page, perPage, desc, limit, offset, relations });

    return this.apiService
      .get<PaginationResponse<AssetHandoverDocumentMedia> | PaginationData>(`${this.endpoint}/${projectID}/asset-handover-document-media`, omitBy(classToPlain<PaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<AssetHandoverDocumentMedia>(AssetHandoverDocumentMedia), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public getAssetHandovers(projectID: number, {
    all,
    page,
    perPage,
    limit,
    offset,
    filters,
    sortBy,
    desc,
    relations
  }: {
    all?: boolean;
    perPage?: number;
    page?: number;
    limit?: number;
    offset?: number;
    filters?: AssetHandoverFilters;
    sortBy?: AssetHandoverSortField;
    desc?: boolean;
    relations?: Array<AssetHandoverRelationType>;
  } = {}): Observable<PaginationResponse<AssetHandover>> {
    const request = new AssetHandoverPaginationRequest({ ...filters, all, page, perPage, limit, offset, sortBy, desc, relations });

    return this.apiService
      .get<PaginationResponse<AssetHandover>>(`${this.endpoint}/${projectID}/asset-handovers`, omitBy(classToPlain<PaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<AssetHandover>(AssetHandover), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public searchPackageHandovers(projectID: number, {
    all,
    sortBy,
    desc,
    filters,
    relations
  }: {
    all?: boolean;
    sortBy?: PackageHandoverSortField;
    desc?: boolean;
    filters?: PackageHandoverFilters;
    relations?: Array<PackageHandoverRelationType>;
  } = {}): Observable<PaginationResponse<PackageHandover>> {
    const request = new PackageHandoverPaginationRequest({ ...filters, relations, all, sortBy, desc });

    return this.apiService
      .get<Array<PackageHandover>>(`${this.endpoint}/${projectID}/package-handovers`, omitBy(classToPlain<PackageHandoverPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<PackageHandover>(PackageHandover), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public createPackageHandoverDocumentMediaBulk(
    projectID: number,
    media: number,
    packageHandoverDocumentType: number,
    packageID: number,
    packageActivities: Array<number>
  ): Observable<PackageHandoverDocumentMedia> {
    const request = new ProjectPackageHandoverDocumentMediaBulkRequest(
      { media, packageHandoverDocumentType, package: packageID, packageActivities }
    );

    return this.apiService
      .post(
        `${this.endpoint}/${projectID}/package-handover-document-media/bulk`,
        classToPlain(request, { groups: [ClassGroup.CREATING] })
      )
      .pipe(
        map((response: unknown) => plainToClass(PackageHandoverDocumentMedia, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createAssetHandoverDocumentMediaBulk(
    projectID: number,
    media: Array<number>,
    documentType: number,
    bulkItems: Array<AssetHandoverBulkItem>
  ): Observable<Array<AssetHandoverDocumentMedia>> {
    const request = new ProjectAssetHandoverDocumentMediaBulkRequest(
      { media, documentType, bulkItems }
    );

    return this.apiService
      .post(
        `${this.endpoint}/${projectID}/asset-handover-document-media/bulk`,
        classToPlain(request, { groups: [ClassGroup.CREATING] })
      )
      .pipe(
        map((response: Array<unknown>) => plainToClass(AssetHandoverDocumentMedia, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public searchPackageHandoverDocumentTypes(id: number, { page, perPage, desc, filters, relations }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    filters?: PackageHandoverDocumentTypeFilters;
    relations?: Array<PackageHandoverDocumentTypeRelationType>;
  } = {}): Observable<PaginationResponse<PackageHandoverDocumentType>> {
    const request = new PackageHandoverDocumentTypePaginationRequest({ ...filters, page, perPage, desc, relations });

    return this.apiService.get<Array<PackageHandoverDocumentType>>(
      `${this.endpoint}/${id}/package-handover-document-types`,
      omitBy(classToPlain<PackageHandoverDocumentTypePaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<PackageHandoverDocumentType>(PackageHandoverDocumentType), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public searchPackageHandoverDocuments(id: number, { all, page, perPage, desc, filters, relations }: {
    all?: boolean;
    page?: number;
    perPage?: number;
    desc?: boolean;
    filters?: PackageHandoverDocumentFilters;
    relations?: Array<PackageHandoverDocumentRelationType>;
  } = {}): Observable<PaginationResponse<PackageHandoverDocument>> {
    const request = new PackageHandoverDocumentPaginationRequest({ ...filters, page, perPage, desc, relations, all });

    return this.apiService.get<Array<PackageHandoverDocument>>(
      `${this.endpoint}/${id}/package-handover-documents`,
      omitBy(classToPlain<PackageHandoverDocumentPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<PackageHandoverDocument>(PackageHandoverDocument), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public getAssetRegister(id: number): Observable<AssetRegister> {
    return this.apiService
      .get<AssetRegister>(`${this.endpoint}/${id}/asset-register`)
      .pipe(
        map((response) => plainToClass(AssetRegister, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public updateAssetRegister(data: AssetRegister): Observable<AssetRegister> {
    return this.apiService
      .put<AssetRegister>(
        `${this.endpoint}/${data.project}/asset-register`,
        classToPlain(data, { groups: [ClassGroup.UPDATING] })
      )
      .pipe(
        map((response) => plainToClass(AssetRegister, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public getPackageHandoverTotalStatistics(id: number): Observable<PackageHandoverStatistics> {
    return this.apiService
      .get<PackageHandoverStatistics>(`${this.endpoint}/${id}/package-handovers-statistics/status-counter`)
      .pipe(
        map((response) => plainToClass(PackageHandoverStatistics, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public searchPackageHandoverDocumentMediaUpdate(projectID: number, mediaID: number, {
    page,
    perPage,
    desc,
    all,
    sortBy,
    relations
  }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    all?: boolean;
    sortBy?: PackageHandoverDocumentMediaUpdateSortField;
    relations?: Array<PackageHandoverDocumentMediaUpdateRelationType>;
  } = {}): Observable<PaginationResponse<PackageHandoverDocumentMediaUpdate>> {
    const request = new PaginationRequest({ page, perPage, desc, all, sortBy, relations });

    return this.apiService.get<PaginationResponse<PackageHandoverDocumentMediaUpdate>>(
      `${this.endpoint}/${projectID}/package-handover-document-media/${mediaID}/updates`,
      omitBy(classToPlain<PaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<PackageHandoverDocumentMediaUpdate>(PackageHandoverDocumentMediaUpdate), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public searchAssetHandoverDocumentMediaUpdate(projectID: number, mediaID: number, {
    page,
    perPage,
    desc,
    all,
    sortBy,
    relations
  }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    all?: boolean;
    sortBy?: AssetHandoverDocumentMediaUpdateSortField;
    relations?: Array<AssetHandoverDocumentMediaUpdateRelationType>;
  } = {}): Observable<PaginationResponse<AssetHandoverDocumentMediaUpdate>> {
    const request = new PaginationRequest({ page, perPage, desc, all, sortBy, relations });

    return this.apiService.get<PaginationResponse<AssetHandoverDocumentMediaUpdate>>(
      `${this.endpoint}/${projectID}/asset-handover-document-media/${mediaID}/updates`,
      omitBy(classToPlain<PaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<AssetHandoverDocumentMediaUpdate>(AssetHandoverDocumentMediaUpdate), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public removePackageHandoverDocumentMedia(projectID: number, id: number): Observable<unknown> {
    return this.apiService.delete(`${this.endpoint}/${projectID}/package-handover-document-media/${id}`);
  }

  public removeAssetHandoverDocumentMedia(projectID: number, id: number): Observable<unknown> {
    return this.apiService.delete(`${this.endpoint}/${projectID}/asset-handover-document-media/${id}`);
  }

  public createPackageHandoverDocumentMediaUpdate(id: number, documentMediaID: number, documentMediaUpdate: PackageHandoverDocumentMediaUpdate): Observable<PackageHandoverDocumentMediaUpdate> {
    return this.apiService
      .post(
        `${this.endpoint}/${id}/package-handover-document-media/${documentMediaID}/updates`,
        classToPlain(documentMediaUpdate, { groups: [ClassGroup.CREATING] })
      )
      .pipe(
        map((response) => plainToClass(PackageHandoverDocumentMediaUpdate, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createAssetHandoverDocumentMediaUpdate(id: number, documentMediaID: number, documentMediaUpdate: AssetHandoverDocumentMediaUpdate): Observable<AssetHandoverDocumentMediaUpdate> {
    return this.apiService
      .post(
        `${this.endpoint}/${id}/asset-handover-document-media/${documentMediaID}/updates`,
        classToPlain(documentMediaUpdate, { groups: [ClassGroup.CREATING] })
      )
      .pipe(
        map((response) => plainToClass(AssetHandoverDocumentMediaUpdate, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createPackageHandoverDocumentMediaUpdateBulk(id: number, documentID: number, update: PackageHandoverDocumentMediaUpdate): Observable<Array<PackageHandoverDocumentMediaUpdate>> {
    return this.apiService.post(
      `${this.endpoint}/${id}/package-handover-documents/${documentID}/media-updates/bulk`,
      classToPlain(update, { groups: [ClassGroup.CREATING] })
    )
      .pipe(
        map((response: Array<unknown>) => plainToClass(PackageHandoverDocumentMediaUpdate, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createAssetHandoverDocumentMediaUpdateBulk(id: number, documentID: number, update: AssetHandoverDocumentMediaUpdate): Observable<Array<AssetHandoverDocumentMediaUpdate>> {
    return this.apiService.post(
      `${this.endpoint}/${id}/asset-handover-documents/${documentID}/media-updates/bulk`,
      classToPlain(update, { groups: [ClassGroup.CREATING] })
    )
      .pipe(
        map((response: Array<unknown>) => plainToClass(AssetHandoverDocumentMediaUpdate, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createPackageHandoverDocument(id: number, packageHandoverDocument: PackageHandoverDocument): Observable<PackageHandoverDocument> {
    return this.apiService.post<PackageHandoverDocument>(
      `${this.endpoint}/${id}/package-handover-documents`,
      classToPlain(packageHandoverDocument, { groups: [ClassGroup.CREATING] })
    )
      .pipe(
        map((response) => plainToClass(PackageHandoverDocument, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public updatePackageHandover(projectID: number, handoverID: number, packageHandover: PackageHandover): Observable<PackageHandover> {
    return this.apiService
      .put(
        `${this.endpoint}/${projectID}/package-handovers/${handoverID}`,
        classToPlain(packageHandover, { groups: [ClassGroup.UPDATING] })
      )
      .pipe(
        map((response) => plainToClass(PackageHandover, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public updatePackageHandoverDocument(id: number, packageHandoverDocument: PackageHandoverDocument): Observable<void> {
    return this.apiService.put(
      `${this.endpoint}/${id}/package-handover-documents/${packageHandoverDocument.id}`,
      classToPlain(packageHandoverDocument, { groups: [ClassGroup.UPDATING] })
    );
  }

  public deletePackageHandoverDocument(id: number, packageHandoverDocumentID: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${id}/package-handover-documents/${packageHandoverDocumentID}`);
  }

  public createAssetHandoverDocuments(id: number, assetHandover: AssetHandover): Observable<Array<AssetHandover>> {
    return this.apiService.post<Array<AssetHandover>>(
      `${this.endpoint}/${id}/asset-handovers`,
      classToPlain(assetHandover, { groups: [ClassGroup.CREATING] })
    )
      .pipe(
        map((response: Array<unknown>) => plainToClass(AssetHandover, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public updateAssetHandoverDocuments(id: number, assetHandoverID: number, assetHandover: AssetHandover): Observable<AssetHandover> {
    return this.apiService.put<AssetHandover>(
      `${this.endpoint}/${id}/asset-handovers/${assetHandoverID}`,
      classToPlain(assetHandover, { groups: [ClassGroup.UPDATING] })
    );
  }

  public deleteAssetHandoverDocument(id: number, documentID: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${id}/asset-handovers/${documentID}`);
  }

  public createResponseCategory(id: number, responseCategory: ProjectResponseCategory): Observable<ProjectResponseCategory> {
    return this.apiService.post<ProjectResponseCategory>(
      `${this.endpoint}/${id}/response-categories`,
      classToPlain(responseCategory, { groups: [ClassGroup.CREATING] })
    )
      .pipe(
        map((response) => plainToClass(ProjectResponseCategory, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public searchPackageMatrices(id: number, { page, perPage, desc, all }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    all?: boolean;
  } = {}): Observable<Array<PackageMatrix>> {
    const request = new PaginationRequest({ page, perPage, desc, all });

    return this.apiService
      .get<Array<PackageMatrix>>(`${this.endpoint}/${id}/package-matrix`, omitBy(classToPlain<PaginationRequest>(request), isUndefined))
      .pipe(
        map((response: Array<unknown>) => plainToClass(PackageMatrix, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createPackageMatrix(id: number, matrix: PackageMatrixCreation): Observable<void> {
    return this.apiService.post<void>(`${this.endpoint}/${id}/package-matrix`, classToPlain(matrix, { groups: [ClassGroup.CREATING] }));
  }

  public getQualityIssue(id: number, qualityIssueID: number, relations?: Array<QualityIssueRelationType>): Observable<QualityIssue> {
    return this.apiService
      .get<QualityIssue>(`${this.endpoint}/${id}/quality-issues/${qualityIssueID}`, omitBy({ expand: relations }, isUndefined))
      .pipe(
        map((response) => plainToClass(QualityIssue, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createQualityIssue(id: number, qualityIssue: QualityIssue): Observable<void> {
    return this.apiService.post<void>(`${this.endpoint}/${id}/quality-issues`, classToPlain(qualityIssue, { groups: [ClassGroup.CREATING] }));
  }

  public createQualityIssueUpdate(id: number, qualityIssueID: number, qualityIssueUpdate: QualityIssueUpdate): Observable<void> {
    return this.apiService.post(`${this.endpoint}/${id}/quality-issues/${qualityIssueID}/updates`, classToPlain(qualityIssueUpdate, { groups: [ClassGroup.CREATING] }));
  }

  public createBulk(id: number, bulk: QualityIssueBulkData): Observable<void> {
    return this.apiService.post(`${this.endpoint}/${id}/quality-issues/subtasks/bulk`, classToPlain(bulk, { groups: [ClassGroup.CREATING] }));
  }

  public getPackageActivity(id: number, activityID: number, relations?: Array<PackageActivityRelationType>): Observable<PackageActivity> {
    return this.apiService
      .get<PackageActivity>(`${this.endpoint}/${id}/package-activities/${activityID}`, omitBy({ expand: relations }, isUndefined))
      .pipe(
        map((response) => plainToClass(PackageActivity, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public togglePackageActivityTaskVisibility(id: number, packageMatrixID: number, task: PackageActivityTask): Observable<void> {
    return this.apiService.post<void>(`${this.endpoint}/${id}/package-matrix/${packageMatrixID}/activity-tasks/${task.id}/${task.expandedHidden ? 'show' : 'hide'}`);
  }

  public searchLocationMatrix(id: number, { all, filters }: {
    all?: boolean;
    filters?: LocationMatrixFilters;
  } = {}): Observable<PaginationResponse<LocationMatrix>> {
    const request = new LocationMatrixPaginationRequest({ ...filters, all });

    return this.apiService
      .get<PaginationResponse<LocationMatrix>>(`${this.endpoint}/${id}/location-matrix`, omitBy(classToPlain<PaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<LocationMatrix>(LocationMatrix), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public syncLocationMatrix(id: number, locationMatrix: Array<LocationMatrix>, locationMatrixOriginal: Array<LocationMatrix>): Observable<Array<LocationMatrix>> {
    const modifiedLocations = locationMatrix.filter((location) => {
      if (!location.id) {
        return true;
      }

      const originalLocation = find(locationMatrixOriginal, { id: location.id });

      return location.building !== originalLocation.building
        || location.level !== originalLocation.level
        || location.area !== originalLocation.area;
    });

    const locationsForDeletion = locationMatrixOriginal.filter((location) => !find(locationMatrix, { id: location.id }));

    modifiedLocations.push(...locationsForDeletion.map((item) => new LocationMatrix({
      ...item,
      isDeleteMatrix: true
    })));

    const locationMatrices = modifiedLocations.filter(
      (modifiedLocation, modifiedLocationIndex) => modifiedLocations.findIndex((originalModifiedLocation, originalModifiedLocationIndex) =>
        originalModifiedLocationIndex !== modifiedLocationIndex &&
        originalModifiedLocation.building === modifiedLocation.building &&
        originalModifiedLocation.level === modifiedLocation.level &&
        originalModifiedLocation.area === modifiedLocation.area
      ) === -1
    );

    return this.apiService
      .post<Array<LocationMatrix>>(`${this.endpoint}/${id}/location-matrix/sync`, classToPlain(locationMatrices, { groups: [ClassGroup.UPDATING] }))
      .pipe(
        map((response) => plainToClass(LocationMatrix, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public searchLocationMatrixPackages(id: number, { all, relations, filters }: {
    all?: boolean;
    relations?: Array<LocationMatrixPackageRelationType>;
    filters?: LocationMatrixFilters;
  } = {}): Observable<PaginationResponse<LocationMatrixPackage>> {
    const request = new LocationMatrixPaginationRequest({ ...filters, all, relations });

    return this.apiService
      .get<PaginationResponse<LocationMatrixPackage>>(`${this.endpoint}/${id}/location-matrix-packages`, omitBy(classToPlain<PaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<LocationMatrixPackage>(LocationMatrixPackage), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public getLocationMatrixPackages(id: number, { all, relations, filters }: {
    all?: boolean;
    relations?: Array<LocationMatrixPackageRelationType>;
    filters?: LocationMatrixFilters;
  } = {}): Observable<LocationMatrixPackagesData> {
    return this.searchLocationMatrixPackages(id, { all, relations, filters })
      .pipe(
        map((matrices) => this.mapLocationMatrixPackagesData(matrices.items))
      );
  }

  public getLocationMatrixPackage(projectID: number, id: number, relations?: Array<LocationMatrixPackageRelationType>): Observable<LocationMatrixPackage> {
    return this.apiService
      .get<LocationMatrixPackage>(`${this.endpoint}/${projectID}/location-matrix-packages/${id}`, omitBy({ expand: relations }, isUndefined))
      .pipe(
        map((response) => plainToClass(LocationMatrixPackage, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public syncLocationMatrixPackages(
    id: number,
    locationMatrixPackagesData: LocationMatrixPackagesData,
    locationMatrixPackagesDataModified: LocationMatrixPackagesData
  ): Observable<LocationMatrixPackagesData> {
    const locationMatrixPackages = this.filterLocationMatrixPackagesForRequest(locationMatrixPackagesData, locationMatrixPackagesDataModified);

    if (!locationMatrixPackages.length) {
      return of([]);
    }

    return this.apiService
      .put<Array<LocationMatrixPackage>>(`${this.endpoint}/${id}/location-matrix-packages/sync`, classToPlain(locationMatrixPackages, { groups: [ClassGroup.UPDATING] }))
      .pipe(
        map((response) => plainToClass(LocationMatrixPackage, response, { groups: [ClassGroup.MAIN] })),
        map((matrices) => this.mapLocationMatrixPackagesData(matrices))
      );
  }

  public addFilesToLocationMatrixPackage(projectID: number, id: number, media: Array<number>): Observable<void> {
    const request = new ProjectMediaRequest({ media });

    return this.apiService.post(`${this.endpoint}/${projectID}/location-matrix-packages/${id}/media/add`, classToPlain(request));
  }

  public removeFilesFromLocationMatrixPackage(projectID: number, id: number, media: Array<number>): Observable<LocationMatrixPackage> {
    const request = new ProjectMediaRequest({ media });

    return this.apiService.post(`${this.endpoint}/${projectID}/location-matrix-packages/${id}/media/remove`, classToPlain(request));
  }

  public getPageMode(id: number, relations?: Array<EditModeRelationType>): Observable<EditMode> {
    return this.apiService
      .get<EditMode>(`${this.endpoint}/${id}/edit-mode`, omitBy({ expand: relations }, isUndefined))
      .pipe(
        map((response) => plainToClass(EditMode, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public togglePageModeToEdit(id: number): Observable<void> {
    return this.apiService.post<void>(`${this.endpoint}/${id}/edit-mode`);
  }

  public togglePageModeToView(id: number): Observable<void> {
    return this.apiService.delete<void>(`${this.endpoint}/${id}/edit-mode`);
  }

  public searchTask(id: number, { page, perPage, sortBy, desc, filters, relations }: {
    page?: number;
    perPage?: number;
    sortBy?: TaskSortField;
    desc?: boolean;
    filters?: TaskFilters;
    relations?: Array<TaskRelationType>;
  } = {}): Observable<PaginationResponse<Task> | PaginationData> {
    const request = new TaskPaginationRequest({ ...filters, page, perPage, sortBy, desc, relations });

    return this.apiService
      .get<Array<Task> | PaginationData>(`${this.endpoint}/${id}/tasks`, omitBy(classToPlain<TaskPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<Task>(Task), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public getFloorPlansRevisions(id: number, { page, perPage, desc, filters, relations }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    filters?: FloorPlanRevisionFilters;
    relations?: Array<FloorPlanRevisionRelationType>;
  } = {}): Observable<PaginationResponse<FloorPlanRevision> | PaginationData> {
    const request = new FloorPlanRevisionPaginationRequest({ ...filters, page, perPage, desc, relations });

    return this.apiService.get<PaginationResponse<FloorPlanRevision>>(
      `${this.endpoint}/${id}/floor-plans/floor_plan_revisions`,
      omitBy(classToPlain<FloorPlanRevisionPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<FloorPlanRevision>(FloorPlanRevision), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public revertFloorPlanRevison(projectID: number, planID: number | string, id: number): Observable<FloorPlanRevision> {
    return this.apiService.post<FloorPlanRevision>(`${this.endpoint}/${projectID}/floor-plans/${planID}/floor_plan_revisions/${id}/revert`);
  }

  public getFloorPlansBuilding(id: number, { page, perPage, desc }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
  } = {}): Observable<PaginationResponse<LocationMatrix>> {
    const request = new ProjectBuildingPaginationRequest({ page, perPage, desc });

    return this.apiService.get<Array<LocationMatrix>>(
      `${this.endpoint}/${id}/floor-plans/buildings`,
      omitBy(classToPlain<ProjectBuildingPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<LocationMatrix>(LocationMatrix), response, { groups: [ClassGroup.BUILDING] }
        ))
      );
  }

  public getFloorPlansLevel(id: number, { page, perPage, desc, filters }: {
    filters?: FilterOptions;
    page?: number;
    perPage?: number;
    desc?: boolean;
  } = {}): Observable<PaginationResponse<LocationMatrix>> {
    const request = new ProjectBuildingPaginationRequest({ ...filters, page, perPage, desc });

    return this.apiService.get<Array<LocationMatrix>>(
      `${this.endpoint}/${id}/floor-plans/level`,
      omitBy(classToPlain<ProjectBuildingPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<LocationMatrix>(LocationMatrix), response, { groups: [ClassGroup.LEVEL] }
        ))
      );
  }

  public searchBuilding(id: number, { page, perPage, desc, filters }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    filters?: FilterOptions;
  } = {}): Observable<PaginationResponse<LocationMatrix>> {
    const request = new ProjectBuildingPaginationRequest({ ...filters, page, perPage, desc });

    return this.apiService.get<Array<LocationMatrix>>(
      `${this.endpoint}/${id}/locations/buildings`,
      omitBy(classToPlain<ProjectBuildingPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<LocationMatrix>(LocationMatrix), response, { groups: [ClassGroup.BUILDING] }
        ))
      );
  }

  public searchLevel(id: number, { page, perPage, desc, all, filters }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    all?: boolean;
    filters?: LevelSelectFilters;
  } = {}): Observable<PaginationResponse<LocationMatrix>> {
    const request = new ProjectLevelPaginationRequest({ ...filters, page, perPage, desc, all });

    return this.apiService.get<Array<LocationMatrix>>(
      `${this.endpoint}/${id}/locations/levels`,
      omitBy(classToPlain<ProjectLevelPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<LocationMatrix>(LocationMatrix), response, { groups: [ClassGroup.LEVEL] }
        ))
      );
  }

  public searchArea(id: number, { page, perPage, desc, filters }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    filters?: AreaSelectFilters;
  } = {}): Observable<PaginationResponse<LocationMatrix>> {
    const request = new ProjectAreaPaginationRequest({ ...filters, page, perPage, desc });

    return this.apiService.get<Array<LocationMatrix>>(
      `${this.endpoint}/${id}/locations/areas`,
      omitBy(classToPlain<ProjectAreaPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<LocationMatrix>(LocationMatrix), response, { groups: [ClassGroup.AREA] }
        ))
      );
  }

  public searchFloorPlan(id: number, { page, perPage, desc, filters, relations, all }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    all?: boolean;
    filters?: FloorPlanFilters;
    relations?: Array<FloorPlanRelationType>;
  } = {}): Observable<PaginationResponse<FloorPlan>> {
    const request = new FloorPlanPaginationRequest({ ...filters, relations, page, perPage, desc, all });

    return this.apiService.get<Array<FloorPlan>>(
      `${this.endpoint}/${id}/floor-plans`,
      omitBy(classToPlain<FloorPlanPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<FloorPlan>(FloorPlan), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public searchPackage(id: number, { page, perPage, sortBy, filters, desc }: {
    page?: number;
    perPage?: number;
    sortBy?: PackageSortField;
    filters?: PackageFilters;
    desc?: boolean;
  } = {}): Observable<PaginationResponse<Package>> {
    const request = new PackagePaginationRequest({ ...filters, page, perPage, sortBy, desc });

    return this.apiService.get<PaginationResponse<Package>>(
      `${this.endpoint}/${id}/package-matrix/packages`,
      omitBy(classToPlain<PackagePaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<Package>(Package), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public searchSubtask(id: number, { page, perPage, sortBy, desc, extraSortBy, extraDesc, filters, relations }: {
    page?: number;
    perPage?: number;
    sortBy?: SubtaskSortField;
    extraSortBy?: SubtaskSortField;
    desc?: boolean;
    extraDesc?: boolean;
    filters?: SubtaskFilters;
    relations?: Array<SubtaskRelationType>;
  } = {}): Observable<PaginationResponse<Subtask> | PaginationData> {
    const request = new SubtaskPaginationRequest({
      ...filters,
      page,
      perPage,
      sortBy,
      desc,
      extraSortBy,
      extraDesc,
      relations
    });

    return this.apiService
      .get<Array<Subtask> | PaginationData>(`${this.endpoint}/${id}/subtasks`, omitBy(classToPlain<SubtaskPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<Subtask>(Subtask), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public searchSubtaskUpdate(projectID: number, subtaskID: number, { page, perPage, sortBy, desc, relations }: {
    page?: number;
    perPage?: number;
    sortBy?: SubtaskSortField;
    desc?: boolean;
    relations?: Array<SubtaskUpdateRelationType>;
  } = {}): Observable<PaginationResponse<SubtaskUpdate>> {
    const request = new SubtaskUpdatesPaginationRequest({ page, perPage, sortBy, desc, relations });

    return this.apiService.get<Array<SubtaskUpdate>>(
      `${this.endpoint}/${projectID}/subtasks/${subtaskID}/updates`,
      omitBy(classToPlain<SubtaskUpdatesPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<SubtaskUpdate>(SubtaskUpdate), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public searchHandoverDocumentsCompany(id: number, { page, perPage, desc, filters }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    filters?: CompanyFilters;
  } = {}): Observable<PaginationResponse<Company>> {
    const request = new CompanyPaginationRequest({ ...filters, page, perPage, desc });

    return this.apiService.get<Array<Company>>(
      `${this.endpoint}/${id}/handover-documents/companies`,
      omitBy(classToPlain<CompanyPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<Company>(Company), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public searchAssetHandoverCompany(id: number, { page, perPage, desc, filters }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    filters?: CompanyFilters;
  } = {}): Observable<PaginationResponse<Company>> {
    const request = new CompanyPaginationRequest({ ...filters, page, perPage, desc });

    return this.apiService.get<Array<Company>>(
      `${this.endpoint}/${id}/asset-handovers/companies`,
      omitBy(classToPlain<CompanyPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<Company>(Company), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public searchPackageHandoverCompany(id: number, { page, perPage, desc, filters }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    filters?: CompanyFilters;
  } = {}): Observable<PaginationResponse<Company>> {
    const request = new CompanyPaginationRequest({ ...filters, page, perPage, desc });

    return this.apiService.get<Array<Company>>(
      `${this.endpoint}/${id}/package-handovers/companies`,
      omitBy(classToPlain<CompanyPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<Company>(Company), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public getSubtask(projectID: number, id: number, relations?: Array<SubtaskRelationType>, fields?: Array<SubtaskRelationType>): Observable<Subtask> {
    return this.apiService
      .get<Subtask>(`${this.endpoint}/${projectID}/subtasks/${id}`, omitBy({ expand: relations, fields }, isUndefined))
      .pipe(
        map((response) => plainToClass(Subtask, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public getSubtaskFiles(projectID: number, id: number): Observable<Subtask> {
    return this.apiService
      .get<Subtask>(`${this.endpoint}/${projectID}/subtasks/${id}/files`)
      .pipe(
        map((response) => plainToClass(Subtask, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createSubtask(id: number, subtask: Subtask): Observable<Subtask> {
    return this.apiService
      .post<Subtask>(`${this.endpoint}/${id}/subtasks`, classToPlain(subtask, { groups: [ClassGroup.CREATING] }))
      .pipe(
        map((response) => plainToClass(Subtask, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createSubtaskUpdate(id: number, subtaskID: number, subtaskUpdate: SubtaskUpdate): Observable<void> {
    return this.apiService.post(`${this.endpoint}/${id}/subtasks/${subtaskID}/updates`, classToPlain(subtaskUpdate, { groups: [ClassGroup.CREATING] }));
  }

  public searchSubtaskSubcontractors(id: number, { page, perPage }: {
    page?: number;
    perPage?: number;
  } = {}): Observable<Array<Company>> {
    const request = new PaginationRequest({ page, perPage });

    return this.apiService
      .get<Array<Company>>(`${this.endpoint}/${id}/subtasks/companies`, omitBy(classToPlain<PaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClass(Company, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public searchQualityIssue(id: number, { page, perPage, sortBy, desc, filters, relations }: {
    page?: number;
    perPage?: number;
    sortBy?: QualityIssueSortField;
    desc?: boolean;
    filters?: QualityIssueFilters;
    relations?: Array<QualityIssueRelationType>;
  } = {}): Observable<PaginationResponse<QualityIssue> | PaginationData> {
    const request = new QualityIssuePaginationRequest({ ...filters, page, perPage, sortBy, desc, relations });

    return this.apiService
      .get<Array<QualityIssue> | PaginationData>(`${this.endpoint}/${id}/quality-issues`, omitBy(classToPlain<QualityIssuePaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<QualityIssue>(QualityIssue), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public searchQualityIssueUpdate(projectID: number, qualityIssueID: number, {
    page,
    perPage,
    sortBy,
    desc,
    relations
  }: {
    page?: number;
    perPage?: number;
    sortBy?: QualityIssueSortField;
    desc?: boolean;
    relations?: Array<QualityIssueUpdateRelationType>;
  } = {}): Observable<PaginationResponse<QualityIssueUpdate>> {
    const request = new QualityIssueUpdatesPaginationRequest({ page, perPage, sortBy, desc, relations });

    return this.apiService.get<Array<QualityIssueUpdate>>(
      `${this.endpoint}/${projectID}/quality-issues/${qualityIssueID}/updates`,
      omitBy(classToPlain<QualityIssueUpdatesPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<QualityIssueUpdate>(QualityIssueUpdate), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public generateProjectReport(type: FileType, { sortBy, filters }: {
    sortBy?: ProjectSortField;
    filters?: ProjectFilters;
  } = {}): Observable<void> {
    const request = new ProjectPaginationRequest({ ...filters, sortBy });

    return this.apiService.get(
      `${this.endpoint}/${type}`,
      omitBy(classToPlain<ProjectPaginationRequest>(request), isUndefined)
    );
  }

  public generatePackageMatrixReport(id: number, type: FileType): Observable<void> {
    return this.apiService.get(`${this.endpoint}/${id}/package-matrix/${type}`);
  }

  public generateLocationMatrixReport(
    id: number,
    activeTab: AccountProjectsDetailsLocationMatrixTabEnum,
    type: FileType,
    filters: LocationMatrixPackageFilters
  ): Observable<void> {
    const matrixType = activeTab === AccountProjectsDetailsLocationMatrixTabEnum.LOCATIONS
      ? 'location-matrix'
      : 'location-matrix-packages';

    return this.apiService.get(
      `${this.endpoint}/${id}/${matrixType}/${type}`,
      omitBy(classToPlain<ProjectLocationMatrixExportRequest>(filters), isUndefined)
    );
  }

  public generateTasksReport(id: number, type: FileType, { sortBy, desc, filters }: {
    sortBy?: TaskSortField;
    desc?: boolean;
    filters?: TaskFilters;
  } = {}): Observable<void> {
    const request = new TaskPaginationRequest({ ...filters, sortBy, desc });

    return this.apiService.get(
      `${this.endpoint}/${id}/tasks/${type}`,
      omitBy(classToPlain<TaskPaginationRequest>(request), isUndefined)
    );
  }

  public generateSubtasksReport(id: number, type: FileType, {
    sortBy,
    desc,
    extraSortBy,
    extraDesc,
    filters,
    relations
  }: {
    sortBy?: SubtaskSortField;
    desc?: boolean;
    extraSortBy?: SubtaskSortField;
    extraDesc?: boolean;
    filters?: SubtaskFilters;
    relations?: Array<SubtaskRelationType>;
  } = {}): Observable<void> {
    const request = new SubtaskPaginationRequest({ ...filters, sortBy, desc, extraSortBy, extraDesc, relations });

    return this.apiService.get(
      `${this.endpoint}/${id}/subtasks/${type}`,
      omitBy(classToPlain<SubtaskPaginationRequest>(request), isUndefined)
    );
  }

  public generateQualityIssuesReport(id: number, type: FileType, {
    sortBy,
    desc,
    extraSortBy,
    extraDesc,
    filters,
    relations
  }: {
    sortBy?: QualityIssueSortField;
    desc?: boolean;
    extraSortBy?: QualityIssueSortField;
    extraDesc?: boolean;
    filters?: QualityIssueFilters;
    relations?: Array<QualityIssueRelationType>;
  } = {}): Observable<void> {
    const request = new QualityIssuePaginationRequest({ ...filters, sortBy, desc, extraSortBy, extraDesc, relations });

    return this.apiService.get(
      `${this.endpoint}/${id}/quality-issues/${type}`,
      omitBy(classToPlain<QualityIssuePaginationRequest>(request), isUndefined)
    );
  }

  public generatePackageHandoverReport(id: number, {
    sortBy,
    desc,
    filters,
    relations
  }: {
    sortBy?: PackageHandoverSortField;
    desc?: boolean;
    filters?: PackageHandoverFilters;
    relations?: Array<PackageHandoverRelationType>;
  } = {}): Observable<void> {
    const request = new PackageHandoverPaginationRequest({ ...filters, sortBy, desc, relations });

    return this.apiService.get(
      `${this.endpoint}/${id}/package-handovers/csv`,
      omitBy(classToPlain<PackageHandoverPaginationRequest>(request), isUndefined)
    );
  }

  public generateAssetHandoverReport(id: number, {
    sortBy,
    desc,
    filters,
    relations
  }: {
    sortBy?: AssetHandoverSortField;
    desc?: boolean;
    filters?: AssetHandoverFilters;
    relations?: Array<AssetHandoverRelationType>;
  } = {}): Observable<void> {
    const request = new AssetHandoverPaginationRequest({ ...filters, sortBy, desc, relations });

    return this.apiService.get(
      `${this.endpoint}/${id}/asset-handovers/csv`,
      omitBy(classToPlain<AssetHandoverPaginationRequest>(request), isUndefined)
    );
  }

  public generateHandoverDocumentReport(id: number, { filters }: {
    filters?: HandoverDocumentFilters;
  } = {}): Observable<void> {
    const request = new HandoverDocumentPaginationRequest({ ...filters });

    return this.apiService.get(
      `${this.endpoint}/${id}/handover-documents/csv`,
      omitBy(classToPlain<HandoverDocumentPaginationRequest>(request), isUndefined)
    );
  }

  public generateAssetHandoverDocumentReport(id: number, { filters }: {
    filters?: AssetHandoverDocumentMediaFilters;
  } = {}): Observable<void> {
    const request = new AssetHandoverDocumentMediaPaginationRequest({ ...filters });

    return this.apiService.get(
      `${this.endpoint}/${id}/asset-handovers/information-csv`,
      omitBy(classToPlain<AssetHandoverDocumentMediaPaginationRequest>(request), isUndefined)
    );
  }

  public generatePackageHandoverDocumentReport(id: number, { filters }: {
    filters?: PackageHandoverDocumentMediaFilters;
  } = {}): Observable<void> {
    const request = new PackageHandoverDocumentMediaPaginationRequest({ ...filters });

    return this.apiService.get(
      `${this.endpoint}/${id}/package-handovers/information-csv`,
      omitBy(classToPlain<PackageHandoverDocumentMediaPaginationRequest>(request), isUndefined)
    );
  }

  public generateHandoverDocumentArchive(id: number): Observable<void> {
    return this.apiService.get(`${this.endpoint}/${id}/handover-documents/archive`);
  }

  public generateQrCodes(id: number, request?: QrCodeRequest): Observable<string> {
    return this.authService
      .token$
      .pipe(
        switchMap((token) => {
          if (this.jwtHelperService.isTokenExpired(token)) {
            return this.authService
              .refreshToken()
              .pipe(map(() => null));
          }

          return of(token);
        }),
        filterObservable((token: string | null) => !!token),
        take(1),
        tap((token: string) => {
          const endpoint = `${this.apiService.apiUrl}${this.endpoint}/${id}/location-matrix/qr-codes`;
          const queryString = new HttpParams({
            fromObject: {
              ...omitBy(classToPlain<QrCodeRequest>(request), isUndefined),
              jwt: token
            }
          }).toString();

          window.location.href = `${endpoint}?${queryString}`;
        })
      );
  }

  public generateDownloadPackageHandoverMedia(id: number, mediaID: number): Observable<string> {
    return this.authService
      .token$
      .pipe(
        switchMap((token) => {
          if (this.jwtHelperService.isTokenExpired(token)) {
            return this.authService
              .refreshToken()
              .pipe(map(() => null));
          }

          return of(token);
        }),
        filterObservable((token: string | null) => !!token),
        take(1),
        tap((token: string) => {
          const endpoint = `${this.apiService.apiUrl}${this.endpoint}/${id}/package-handover-document-media/${mediaID}/download`;
          const queryString = new HttpParams({
            fromObject: { jwt: token }
          }).toString();

          window.location.href = `${endpoint}?${queryString}`;
        })
      );
  }

  public generateDownloadAssetHandoverMedia(id: number, mediaID: number): Observable<string> {
    return this.authService
      .token$
      .pipe(
        switchMap((token) => {
          if (this.jwtHelperService.isTokenExpired(token)) {
            return this.authService
              .refreshToken()
              .pipe(map(() => null));
          }

          return of(token);
        }),
        filterObservable((token: string | null) => !!token),
        take(1),
        tap((token: string) => {
          const endpoint = `${this.apiService.apiUrl}${this.endpoint}/${id}/asset-handover-document-media/${mediaID}/download`;
          const queryString = new HttpParams({
            fromObject: { jwt: token }
          }).toString();

          window.location.href = `${endpoint}?${queryString}`;
        })
      );
  }

  public toggleUserNotifications(id: number, user: User): Observable<void> {
    return this.apiService.put(
      `${this.endpoint}/${id}/users/${user.id}`,
      { is_notifications_enabled: !user.expandedIsNotificationsEnabled }
    );
  }

  public searchUser(id: number, mode: FilterMode): Observable<Array<User>> {
    return this.apiService.get<Array<User>>(
      `${this.endpoint}/${id}/${mode}s/${mode === FilterMode.TASK ? 'assigned-users' : 'creators'}`
    )
      .pipe(
        map((response: Array<unknown>) => plainToClass(User, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public searchRecipient(id: number, mode: FilterMode, { page, perPage, desc, filters }: {
    page?: number;
    perPage?: number;
    desc?: boolean;
    filters?: RecipientFilters;
  } = {}): Observable<PaginationResponse<Recipient>> {
    const request = new ProjectRecipientPaginationRequest({ ...filters, page, perPage, desc });

    return this.apiService.get<PaginationResponse<Recipient>>(
      `${this.endpoint}/${id}/${mode ? `${mode}-update/` : ''}recipients`,
      omitBy(classToPlain<PaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<Recipient>(Recipient), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public searchCompanies(id: number, { all, filters, relations }: {
    all?: boolean;
    filters?: PackageMatrixCompanyFilters;
    relations?: Array<PackageMatrixCompanyRelationType>;
  } = {}): Observable<PaginationResponse<PackageMatrixCompany>> {
    const request = new PackageMatrixCompanyPaginationRequest({ ...filters, all, relations });

    return this.apiService.get<PaginationResponse<PackageMatrixCompany>>(
      `${this.endpoint}/${id}/package-matrix-companies`,
      omitBy(classToPlain<PackageMatrixCompanyPaginationRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<PackageMatrixCompany>(PackageMatrixCompany),
          response,
          { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public addCompanies(projectID: number, packageMatrixID: number, companies: Array<number>): Observable<void> {
    const request = companies.map((id) => new PackageMatrixCompanyAddRequest({
      company: id,
      packageMatrixID
    }));

    return this.apiService.post(`${this.endpoint}/${projectID}/package-matrix-companies`, classToPlain(request));
  }

  public deleteCompany(projectID: number, packageMatrixCompanyID: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${projectID}/package-matrix-companies/${packageMatrixCompanyID}`);
  }

  public createFloorPlan(id: number, floorPlan: FloorPlan): Observable<FloorPlan> {
    return this.apiService.post<FloorPlan>(`${this.endpoint}/${id}/floor-plans`, classToPlain(floorPlan, { groups: [ClassGroup.CREATING] }))
      .pipe(
        map((response) => plainToClass(FloorPlan, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public searchQualityIssuesFloorPlans(id: number, { page, perPage, sortBy, desc, filters, relations }: {
    page?: number;
    perPage?: number;
    sortBy?: FloorPlanSortField;
    desc?: boolean;
    filters?: FloorPlanFilters<QualityIssueStatusEnum>;
    relations?: Array<FloorPlanRelationType>;
  } = {}): Observable<PaginationResponse<FloorPlan> | PaginationData> {
    const request = new FloorPlanPaginationRequest({ ...filters, page, perPage, sortBy, desc, relations });

    return this.apiService
      .get<Array<FloorPlan> | PaginationData>(`${this.endpoint}/${id}/quality-issues/floor-plans`, omitBy(classToPlain<FloorPlanPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<FloorPlan>(FloorPlan), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public searchSubtaskFloorPlans(id: number, { page, perPage, sortBy, desc, filters, relations }: {
    page?: number;
    perPage?: number;
    sortBy?: FloorPlanSortField;
    desc?: boolean;
    filters?: SubtaskFilters;
    relations?: Array<FloorPlanRelationType>;
  } = {}): Observable<PaginationResponse<FloorPlan> | PaginationData> {
    const request = new FloorPlanPaginationRequest({ ...filters, page, perPage, sortBy, desc, relations });

    return this.apiService
      .get<Array<FloorPlan> | PaginationData>(`${this.endpoint}/${id}/subtasks/floor-plans`, omitBy(classToPlain<FloorPlanPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<FloorPlan>(FloorPlan), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public searchFloorPlans(id: number, { page, perPage, sortBy, desc, filters, relations }: {
    page?: number;
    perPage?: number;
    sortBy?: FloorPlanSortField;
    desc?: boolean;
    filters?: FloorPlanFilters;
    relations?: Array<FloorPlanRelationType>;
  } = {}): Observable<PaginationResponse<FloorPlan> | PaginationData> {
    const request = new FloorPlanPaginationRequest({ ...filters, page, perPage, sortBy, desc, relations });

    return this.apiService
      .get<Array<FloorPlan> | PaginationData>(`${this.endpoint}/${id}/floor-plans`, omitBy(classToPlain<FloorPlanPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<FloorPlan>(FloorPlan), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public updateFloorPlan(projectID: number, floorPlanID: number, floorPlan: FloorPlan): Observable<FloorPlan> {
    return this.apiService
      .put(
        `${this.endpoint}/${projectID}/floor-plans/${floorPlanID}`,
        classToPlain(floorPlan, { groups: [ClassGroup.UPDATING] })
      )
      .pipe(
        map((response) => plainToClass(FloorPlan, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public deleteFloorPlan(projectID: number, floorPlanID: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${projectID}/floor-plans/${floorPlanID}`);
  }

  public searchFloorPlanAreas(): Array<AccountProjectsDetailsPlansDrawAreasNodeData> {
    return JSON.parse(localStorage.getItem('nodes')) || [];
  }

  public updateFloorPlanAreas(data: Array<AccountProjectsDetailsPlansDrawAreasNodeData> = []): void {
    localStorage.setItem('nodes', JSON.stringify(data));
  }

  public updateAssetHandoverInformation(
    projectID: number,
    assetHandoverInformationID: number,
    data: AssetHandoverInformation
  ): Observable<AssetHandoverInformation> {
    return this.apiService
      .put(
        `${this.endpoint}/${projectID}/asset-handover-information/${assetHandoverInformationID}`,
        classToPlain(data, { groups: [ClassGroup.UPDATING] })
      )
      .pipe(
        map((response) => plainToClass(AssetHandoverInformation, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createAssetHandoverInformation(projectID: number, data: AssetHandoverInformation): Observable<AssetHandoverInformation> {
    return this.apiService.post<AssetHandoverInformation>(
      `${this.endpoint}/${projectID}/asset-handover-information`,
      classToPlain(data, { groups: [ClassGroup.CREATING] })
    )
      .pipe(
        map((response) => plainToClass(AssetHandoverInformation, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public buildAssetReference(locationMatrix: LocationMatrix, packageActivity: string): string {
    const assetReference = `${ locationMatrix.building }-${ locationMatrix.level }-${ locationMatrix.area }-${ packageActivity }`;

    return assetReference
      .replace(/ /g, '-')
      .replace(/[^0-9a-zA-Z-]/g, '')
      .replace(/(-)\1+/g, '$1');
  }

  public searchHandoverDocuments(id: number, { page, perPage, filters, relations }: {
    page?: number;
    perPage?: number;
    filters?: HandoverDocumentFilters;
    relations?: Array<HandoverDocumentRelationType>;
  } = {}): Observable<PaginationResponse<HandoverDocument> | PaginationData> {
    const request = new HandoverDocumentPaginationRequest({ ...filters, page, perPage, relations });

    return this.apiService
      .get<Array<HandoverDocument> | PaginationData>(`${this.endpoint}/${id}/handover-documents`, omitBy(classToPlain<HandoverDocumentPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<HandoverDocument>(HandoverDocument), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public generateHandoverDocumentsDownloadLink(id: number, documentID: Array<number>): Observable<void> {
    const request = new HandoverDocumentGenerateDownloadLinkRequest({ handoverDocument: documentID });

    return this.apiService.post(`${this.endpoint}/${id}/handover-documents/media/batch`, instanceToPlain(request));
  }

  public generateAssetHandoverDocumentMediaDownloadLink(id: number, documentID: Array<number>): Observable<void> {
    const request = new HandoverDocumentGenerateDownloadLinkRequest({ assetHandoverDocumentMedia: documentID });

    return this.apiService.post(`${this.endpoint}/${id}/asset-handover-document-media/batch`, instanceToPlain(request));
  }

  public generatePackageHandoverDocumentMediaDownloadLink(id: number, documentID: Array<number>): Observable<void> {
    const request = new HandoverDocumentGenerateDownloadLinkRequest({ packageHandoverDocumentMedia: documentID });

    return this.apiService.post(`${this.endpoint}/${id}/package-handover-document-media/batch`, instanceToPlain(request));
  }

  public getTotalAssetHandoverStatistics(id: number): Observable<AssetHandoverStatistics> {
    return this.apiService.get<AssetHandoverStatistics>(`${this.endpoint}/${id}/asset-handover-statistics/project`)
      .pipe(
        map((response) => plainToClass(AssetHandoverStatistics, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public getAssetHandoverStatistics(id: number, filters: AssetHandoverFilters): Observable<AssetHandoverStatistics> {
    const request = new AssetHandoverStatisticsRequest({ ...filters });

    return this.apiService.get<AssetHandoverStatistics>(
      `${this.endpoint}/${id}/asset-handover-statistics`,
      omitBy(classToPlain<AssetHandoverStatisticsRequest>(request), isUndefined)
    )
      .pipe(
        map((response) => plainToClass(AssetHandoverStatistics, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public getFloorPlanAreas(id: number, { page, perPage, all, filters }: {
    page?: number;
    perPage?: number;
    all?: boolean;
    filters?: FloorPlanAreaFilters;
  } = {}): Observable<PaginationResponse<FloorPlanArea> | PaginationData> {
    const request = new FloorPlanAreaPaginationRequest({ ...filters, page, perPage, all });

    return this.apiService
      .get<PaginationResponse<FloorPlanArea> | PaginationData>(`${this.endpoint}/${id}/floor-plan-areas`, omitBy(classToPlain<FloorPlanAreaPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<FloorPlanArea>(FloorPlanArea), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public createPinThumbnails(id: number, planID: number, data: FloorPlanAreaPin): Observable<Array<Media>> {
    const request = new ProjectFloorPlanThumbnailRequest({ floorPlanArea: data.floorPlanArea, pinCoordinates: data.pin });

    return this.apiService.post(
      `${this.endpoint}/${id}/floor-plans/${planID}/temporary-pin-thumbnails`,
      omitBy(classToPlain<ProjectFloorPlanThumbnailRequest>(request), isUndefined)
    )
      .pipe(
        map((response: Array<Media>) => plainToClass(Media, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public getQualityIssueFloorPlanAreaPins(
    id: number,
    floorPlan: number,
    relations: Array<FloorPlanAreaPinRelationType>,
    filters: QualityIssueFilters
  ): Observable<PaginationResponse<FloorPlanAreaPin>> {
    const request = new FloorPlanAreaPaginationRequest({ ...filters, floorPlan, relations, all: true });

    return this.apiService
      .get<PaginationResponse<FloorPlanAreaPin>>(`${this.endpoint}/${id}/quality-issues/floor-plan-area-pins`, omitBy(classToPlain<FloorPlanAreaPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<FloorPlanAreaPin>(FloorPlanAreaPin), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public getSubtaskFloorPlanAreaPins(
    id: number,
    floorPlan: number,
    relations: Array<FloorPlanAreaPinRelationType>,
    filters: QualityIssueFilters
  ): Observable<PaginationResponse<FloorPlanAreaPin>> {
    const request = new FloorPlanAreaPaginationRequest({ ...filters, floorPlan, relations, all: true });

    return this.apiService
      .get<PaginationResponse<FloorPlanAreaPin>>(`${this.endpoint}/${id}/subtasks/floor-plan-area-pins`, omitBy(classToPlain<FloorPlanAreaPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => plainToClassFromExist(
          new PaginationResponse<FloorPlanAreaPin>(FloorPlanAreaPin), response, { groups: [ClassGroup.MAIN] }
        ))
      );
  }

  public createFloorPlanAreasBulk(id: number, bulk: FloorPlanAreaBulk): Observable<FloorPlanAreaBulk> {
    return this.apiService.post(
      `${this.endpoint}/${id}/floor-plan-areas/bulk`,
      classToPlain(bulk)
    )
      .pipe(
        map((response: FloorPlanAreaBulk) => plainToClass(FloorPlanAreaBulk, response))
      );
  }

  private filterLocationMatrixPackagesForRequest(
    locationMatrixPackagesData: LocationMatrixPackagesData,
    locationMatrixPackagesDataModified: LocationMatrixPackagesData
  ): Array<LocationMatrixPackage> {
    const originData = this.mapLocationMatrixPackagesDataForRequest(locationMatrixPackagesData);
    const modifiedData = this.mapLocationMatrixPackagesDataForRequest(locationMatrixPackagesDataModified);

    return filter(modifiedData, (item) => item.enabled !== find(originData, { id: item.id })?.enabled);
  }

  private mapLocationMatrixPackagesData(data: Array<LocationMatrixPackage>): LocationMatrixPackagesData {
    return mapValues(groupBy(data, (item) => item.package), (items) =>
      mapValues(groupBy(items, (item) => item.locationMatrix), (locationMatrixItems) =>
        mapValues(groupBy(locationMatrixItems, (item) => item.packageActivity), (packageActivityItems) => packageActivityItems[0])
      )
    );
  }

  private mapLocationMatrixPackagesDataForRequest(data: LocationMatrixPackagesData): Array<LocationMatrixPackage> {
    return flatten(
      flatten(
        flatten(Object.values(data)).map((items) => Object.values(items))
      )
        .map((items) => Object.values(items))
    );
  }

  private mapPackageMatrixData(data: Array<PackageMatrix>): Array<Package> {
    return data
      .map((item) => new Package({
        ...item.expandedPackage,
        expandedPackageActivities: [
          new PackageActivity({
            ...item.expandedPackageActivity,
            packageMatrixID: item.id
          })
        ]
      }))
      .reduce((packages, current) => {
        const existingPackage = find(packages, { id: current.id });

        if (existingPackage) {
          existingPackage.expandedPackageActivities = sortByFn(
            existingPackage.expandedPackageActivities.concat(current.expandedPackageActivities),
            ({ name }) => name.toLowerCase()
          );
        } else {
          packages.push(current);
        }

        return packages;
      }, [])
      .sort((a, b) => a.order - b.order);
  }
}
