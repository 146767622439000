import { AssetHandoverDocumentMediaStatusEnum } from '@shared/asset-handover-document-media';
import { PackageHandoverDocumentMediaStatusEnum } from '@shared/package-handover-document-media/enums';
import { PaginationRequest } from '@shared/pagination';
import { Exclude, Expose } from 'class-transformer';
import { CompanySortField } from '../enums';

export class CompanyPaginationRequest extends PaginationRequest {
  @Expose()
  public name?: string;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'subcontractors_in_project' })
  public subcontractorsInProject?: number;

  @Expose()
  public project?: number;

  @Expose()
  public status?: Array<PackageHandoverDocumentMediaStatusEnum | AssetHandoverDocumentMediaStatusEnum>;

  @Expose({ name: 'companies_of_package_activities' })
  public companiesOfPackageActivities?: number;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  @Expose({ name: 'has_published_tasks' })
  public hasPublishedTasks?: boolean;

  @Expose({ name: 'has_asset_handover_documents' })
  public hasAssetHandoverDocuments?: boolean;

  @Expose({ name: 'has_handover_documents' })
  public hasHandoverDocuments?: boolean;

  @Expose({ name: 'package_handover_document_type' })
  public packageHandoverDocumentType?: Array<number>;

  @Expose({ name: 'document_group' })
  public documentGroup?: Array<number>;

  @Expose({ name: 'asset_handover_document_type' })
  public assetHandoverDocumentType?: Array<number>;

  @Expose({ name: 'clients_in_project' })
  public clientsInProject?: number;

  @Exclude()
  public sortBy?: CompanySortField;

  constructor(model: Partial<CompanyPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
