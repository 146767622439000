import { PackageHandoverDocumentMediaStatusEnum } from '../enums';

export class PackageHandoverDocumentMediaFilters {
  public search?: string;
  public package?: number;
  public packageActivity?: Array<number>;
  public packageHandoverDocumentGroup?: Array<number>;
  public packageHandoverDocumentType?: Array<number>;
  public status?: Array<PackageHandoverDocumentMediaStatusEnum>;
  public company?: Array<number>;
  public getTotalItemsCount?: boolean;
  public useLimitOffsetPagination?: boolean;

  constructor(model: Partial<PackageHandoverDocumentMediaFilters> = {}) {
    Object.assign(this, model);
  }
}
