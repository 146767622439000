import { ApiService } from '@shared/api';
import { classToPlainFromExist, plainToClass } from 'class-transformer';
import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { Media } from './models';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse, HttpProgressEvent } from '@angular/common/http';
import { ClassGroup } from '../class-group';

@Injectable()
export class MediaService {
  private endpoint: string;

  constructor(
    private apiService: ApiService
  ) {
    this.endpoint = '/media';
  }

  public get(id: number): Observable<Media> {
    return this.apiService
      .get<Media>(`${this.endpoint}/${id}`)
      .pipe(
        map((response) => plainToClass(Media, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public getPreview(hash: string): Observable<Blob> {
    return this.apiService.get<Blob>(`${this.endpoint}/private/${hash}`, null, { responseType: 'blob' });
  }

  public create(media: Media): Observable<Media> {
    return this.apiService
      .post<Media>(this.endpoint, classToPlainFromExist(media, { file: media.file }, { groups: [ClassGroup.CREATING] }))
      .pipe(
        map((response) => plainToClass(Media, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public createWithProgress(media: Media, isPlanMedia?: boolean): Observable<Media | number> {
    const endpoint = isPlanMedia ? `${this.endpoint}/plan` : this.endpoint;

    return this.apiService.post<HttpProgressEvent | HttpResponse<Media>>(
      endpoint,
      classToPlainFromExist(media, { file: media.file }, { groups: [ClassGroup.CREATING] }),
      { reportProgress: true, observe: 'events' }
    )
      .pipe(
        filter((response) => response.type === HttpEventType.UploadProgress || !!(response as HttpResponse<Media>).body),
        map((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            return response.loaded * 100 / response.total;
          }

          return plainToClass(Media, (response as HttpResponse<Media>).body, { groups: [ClassGroup.MAIN] });
        })
      );
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }
}
