import { Expose, Transform, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { DateTime } from 'luxon';

export class ProjectResponseCategory {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public name: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public description: string;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public project: number;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  constructor(model: Partial<ProjectResponseCategory> = {}) {
    Object.assign(this, model);
  }
}
