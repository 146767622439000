import { Expose, Type } from 'class-transformer';
import { Package } from '@shared/package';
import { Media } from '@shared/media';

export class FloorPlan {
  @Type(() => Number)
  @Expose()
  public id?: number;

  @Type(() => Number)
  @Expose()
  public project?: number;

  @Expose()
  public package: number;

  @Expose()
  public building: string;

  @Expose()
  public level: string;

  @Expose()
  public media?: number;

  @Type(() => Boolean)
  @Expose({ name: 'keep_floor_plan_areas_and_floor_plan_pins' })
  public keepFloorPlanAreasAndFloorPlanPins?: boolean;

  @Type(() => Package)
  @Expose({ name: 'expanded_package' })
  public expandedPackage?: Package;

  @Type(() => Media)
  @Expose({ name: 'expanded_media' })
  public expandedMedia?: Media;

  @Type(() => Media)
  @Expose({ name: 'expanded_image' })
  public expandedImage?: Media;

  constructor(model: Partial<FloorPlan> = {}) {
    Object.assign(this, model);
  }
}
