import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';

export class PackageHandoverDocumentPaginationRequest extends PaginationRequest {
  @Expose()
  public name?: string;

  @Expose()
  public project?: Array<number>;

  @Expose()
  public company?: Array<number>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  constructor(model: Partial<PackageHandoverDocumentPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
