import { ApiService } from '@shared/api';
import { Injectable } from '@angular/core';
import { Company, CompanyFilters, CompanyPaginationRequest} from './models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClassGroup } from '@shared/class-group';
import { PaginationData, PaginationResponse } from '@shared/pagination';
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { CompanySortField } from './enums/sort-field';
import { CompanyRelationType } from './types';
import { FileType } from '@shared/file-save';

@Injectable()
export class CompanyService {
  public endpoint: string;

  constructor(
    private apiService: ApiService
  ) {
    this.endpoint = '/companies';
  }

  public search({ page, perPage, sortBy, desc, filters, relations, all }: {
    page?: number;
    perPage?: number;
    sortBy?: CompanySortField;
    desc?: boolean;
    filters?: CompanyFilters;
    relations?: Array<CompanyRelationType>;
    all?: boolean;
  } = {}): Observable<PaginationResponse<Company> | PaginationData> {
    const request = new CompanyPaginationRequest({ page, perPage, sortBy, desc, ...filters, relations, all });

    return this.apiService
      .get<PaginationResponse<Company> | PaginationData>(this.endpoint, omitBy(classToPlain<CompanyPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(new PaginationResponse<Company>(Company), response, { groups: [ClassGroup.MAIN] });
        })
      );
  }

  public create(company: Company): Observable<Company> {
    return this.apiService
      .post<Company>(this.endpoint, classToPlain(company, { groups: [ClassGroup.CREATING] }))
      .pipe(
        map((response) => plainToClass(Company, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public get(id: number): Observable<Company> {
    return this.apiService
      .get<Company>(`${this.endpoint}/${id}`)
      .pipe(
        map((response) => plainToClass(Company, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public update(company: Company): Observable<void> {
    return this.apiService.put(`${this.endpoint}/${company.id}`, classToPlain(company, { groups: [ClassGroup.UPDATING] }));
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }

  public generateCompanyReport(type: FileType, { sortBy, filters }: {
    sortBy?: CompanySortField;
    filters?: CompanyFilters;
  } = {}): Observable<void> {
    const request = new CompanyPaginationRequest({ ...filters, sortBy });

    return this.apiService.get(
      `${this.endpoint}/${type}`,
      omitBy(classToPlain<CompanyPaginationRequest>(request), isUndefined)
    );
  }
}
