import { ApiService } from '@shared/api';
import { Injectable } from '@angular/core';
import { PackageActivity, PackageActivityFilters, PackageActivityPaginationRequest } from './models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClassGroup } from '@shared/class-group';
import { PaginationData, PaginationResponse } from '@shared/pagination';
import { PackageActivitySortField } from './enums';
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { PackageActivityOmitType, PackageActivityRelationType } from './types';
import { FileType } from '@shared/file-save';

@Injectable()
export class PackageActivityService {
  public endpoint: string;

  constructor(
    private readonly apiService: ApiService
  ) {
    this.endpoint = '/package-activities';
  }

  public search({ all, page, perPage, sortBy, desc, filters, relations, omit }: {
    all?: boolean;
    page?: number;
    perPage?: number;
    sortBy?: PackageActivitySortField;
    desc?: boolean;
    filters?: PackageActivityFilters;
    relations?: Array<PackageActivityRelationType>;
    omit?: Array<PackageActivityOmitType>;
  } = {}): Observable<PaginationResponse<PackageActivity> | PaginationData> {
    const request = new PackageActivityPaginationRequest({ ...filters, all, page, perPage, sortBy, desc, relations, omit });

    return this.apiService.get<PaginationResponse<PackageActivity>>(
      this.endpoint,
      omitBy(
        classToPlain<PackageActivityPaginationRequest>(request), isUndefined
      )
    )
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(
            new PaginationResponse<PackageActivity>(PackageActivity), response, { groups: [ClassGroup.MAIN] }
          );
        })
      );
  }

  public create(activity: PackageActivity): Observable<PackageActivity> {
    return this.apiService
      .post<PackageActivity>(this.endpoint, classToPlain(activity, { groups: [ClassGroup.CREATING] }))
      .pipe(
        map((response) => plainToClass(PackageActivity, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public get(id: number, relations?: Array<PackageActivityRelationType>): Observable<PackageActivity> {
    return this.apiService
      .get<PackageActivity>(`${this.endpoint}/${id}`, omitBy({ expand: relations }, isUndefined))
      .pipe(
        map((response) => plainToClass(PackageActivity, response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public update(activity: PackageActivity): Observable<void> {
    return this.apiService.put(`${this.endpoint}/${activity.id}`, classToPlain(activity, { groups: [ClassGroup.UPDATING] }));
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }

  public generatePackageActivityReport(type: FileType, { sortBy, filters }: {
    sortBy?: PackageActivitySortField;
    filters?: PackageActivityFilters;
  } = {}): Observable<void> {
    const request = new PackageActivityPaginationRequest({ ...filters, sortBy });

    return this.apiService.get(
      `${this.endpoint}/${type}`,
      omitBy(classToPlain<PackageActivityPaginationRequest>(request), isUndefined)
    );
  }
}
