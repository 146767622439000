import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { LocationMatrix } from '@shared/location-matrix';
import { QualityIssueStatusEnum } from '../enums';
import { Recipient } from '@shared/recipient';
import { DateTime } from 'luxon';
import { Media } from '@shared/media';
import { User } from '@shared/user';
import { QualityIssueUpdate } from './quality-issue-update';
import { Subtask } from '@shared/subtask';
import { ProjectResponseCategory } from '@shared/project-response-category/models/response-category';
import { FloorPlanAreaPin } from '@shared/floor-plan-area-pin';

export class QualityIssue {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ name: 'location_matrix', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public locationMatrix: number;

  @Expose({ name: 'response_category', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public responseCategory: number;

  @Type(() => ProjectResponseCategory)
  @Expose({ name: 'expanded_response_category', groups: [ClassGroup.MAIN] })
  public expandedResponseCategory: ProjectResponseCategory;

  @Type(() => LocationMatrix)
  @Expose({ name: 'expanded_location_matrix', groups: [ClassGroup.MAIN] })
  public expandedLocationMatrix: LocationMatrix;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public user: number;

  @Type(() => User)
  @Expose({ name: 'expanded_user', groups: [ClassGroup.MAIN] })
  public expandedUser: User;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public description: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public status: QualityIssueStatusEnum;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public attachments: Array<number>;

  @Type(() => FloorPlanAreaPin)
  @Expose({ name: 'floor_plan_area_pin', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public floorPlanAreaPin: FloorPlanAreaPin;

  @Type(() => Media)
  @Expose({ name: 'expanded_attachments', groups: [ClassGroup.MAIN] })
  public expandedAttachments: Array<Media>;

  @Type(() => FloorPlanAreaPin)
  @Expose({ name: 'expanded_floor_plan_area_pin', groups: [ClassGroup.MAIN] })
  public expandedFloorPlanAreaPin: FloorPlanAreaPin;

  @Type(() => Recipient)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public recipients: Array<Recipient>;

  @Type(() => Recipient)
  @Expose({ name: 'expanded_last_recipients', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public expandedLastRecipients: Array<Recipient>;

  @Type(() => QualityIssueUpdate)
  @Expose({ name: 'expanded_last_confirmed_update', groups: [ClassGroup.MAIN] })
  public expandedLastConfirmedUpdate: QualityIssueUpdate;

  @Type(() => QualityIssueUpdate)
  @Expose({ name: 'expanded_last_status_change_update', groups: [ClassGroup.MAIN] })
  public expandedLastStatusChangeUpdate: QualityIssueUpdate;

  @Type(() => Subtask)
  @Expose({ name: 'expanded_subtasks', groups: [ClassGroup.MAIN] })
  public expandedSubtasks: Array<Subtask>;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO(), { toPlainOnly: true, groups: [ClassGroup.CREATING, ClassGroup.UPDATING] })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO(), { toPlainOnly: true, groups: [ClassGroup.CREATING, ClassGroup.UPDATING] })
  @Expose({ name: 'due_date', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public dueDate: DateTime;

  @Expose({ name: 'old_quality_issue', groups: [ClassGroup.CREATING], toPlainOnly: true })
  public oldQualityIssue: number;

  @Exclude()
  public get referenceID(): number {
    return this?.expandedSubtasks[0]?.id;
  }

  @Exclude()
  public get isUnderReview(): boolean {
    return this.status === QualityIssueStatusEnum.UNDER_REVIEW;
  }

  @Exclude()
  public get isUnderInspection(): boolean {
    return this.status === QualityIssueStatusEnum.UNDER_INSPECTION;
  }

  @Exclude()
  public get isRequestingApproval(): boolean {
    return this.status === QualityIssueStatusEnum.REQUESTING_APPROVAL;
  }

  @Exclude()
  public get isRequestedApprovalRejected(): boolean {
    return this.status === QualityIssueStatusEnum.REQUESTED_APPROVAL_REJECTED;
  }

  @Exclude()
  public get isRemoved(): boolean {
    return this.status === QualityIssueStatusEnum.REMOVED;
  }

  @Exclude()
  public get isInProgress(): boolean {
    return this.status === QualityIssueStatusEnum.IN_PROGRESS;
  }

  @Exclude()
  public get isInspectionRejected(): boolean {
    return this.status === QualityIssueStatusEnum.INSPECTION_REJECTED;
  }

  @Exclude()
  public get isDeclined(): boolean {
    return this.status === QualityIssueStatusEnum.DECLINED;
  }

  @Exclude()
  public get isContested(): boolean {
    return this.status === QualityIssueStatusEnum.CONTESTED;
  }

  @Exclude()
  public get isClosed(): boolean {
    return this.status === QualityIssueStatusEnum.CLOSED;
  }

  @Exclude()
  public get isContestedBeforeReworkCreation(): boolean {
    return this.isContested && !this.expandedSubtasks?.length;
  }

  @Exclude()
  public get isContestedAfterReworkCreation(): boolean {
    return this.isContested && !!this.expandedSubtasks?.length;
  }

  constructor(model: Partial<QualityIssue> = {}) {
    Object.assign(this, model);
  }
}
