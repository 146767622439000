import { FloorPlan } from '@shared/floor-plan';
import { FloorPlanAreaPin } from '@shared/floor-plan-area-pin';

export class FloorPlanSetLocationData {
  public pin: FloorPlanAreaPin;
  public plan: FloorPlan;

  constructor(model: Partial<FloorPlanSetLocationData> = {}) {
    Object.assign(this, model);
  }
}
