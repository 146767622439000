import { Expose } from 'class-transformer';
import { QualityIssueStatusEnum } from '../enums/status';
import { ClassGroup } from '../../class-group';

export class QualityIssueCounter {
  @Expose({ groups: [ClassGroup.MAIN] })
  public total: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public closed: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public contested: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public declined: number;

  @Expose({ name: QualityIssueStatusEnum.INSPECTION_REJECTED, groups: [ClassGroup.MAIN] })
  public inspectionRejected: number;

  @Expose({ name: QualityIssueStatusEnum.IN_PROGRESS, groups: [ClassGroup.MAIN] })
  public inProgress: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public removed: number;

  @Expose({ name: QualityIssueStatusEnum.REQUESTED_APPROVAL_REJECTED, groups: [ClassGroup.MAIN] })
  public requestedApprovalRejected: number;

  @Expose({ name: QualityIssueStatusEnum.REQUESTING_APPROVAL, groups: [ClassGroup.MAIN] })
  public requestingApproval: number;

  @Expose({ name: QualityIssueStatusEnum.UNDER_INSPECTION, groups: [ClassGroup.MAIN] })
  public underInspection: number;

  constructor(model: Partial<QualityIssueCounter> = {}) {
    Object.assign(this, model);
  }
}
