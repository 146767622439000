import { ClassGroup } from '@shared/class-group';
import { PackageHandoverDocumentMediaStatusEnum } from '@shared/package-handover-document-media/enums';
import { PackageHandoverStatistics } from '@shared/package-handover-statistics';
import { Type, Expose, Exclude } from 'class-transformer';
import { sum } from 'lodash';

export class PackageHandoverDocumentStatistics extends PackageHandoverStatistics {
  @Type(() => Number)
  @Expose({ name: 'package_handover_document', groups: [ClassGroup.MAIN] })
  public packageHandoverDocument?: number;

  @Exclude()
  public get mediaCount(): number {
    const statuses = Object.values(PackageHandoverDocumentMediaStatusEnum);

    return sum(statuses.map((status) => this[status]));
  }

  @Exclude()
  public get existingStatuses(): Array<PackageHandoverDocumentMediaStatusEnum> {
    const statuses = Object.values(PackageHandoverDocumentMediaStatusEnum);

    return statuses.filter((status) => this[status] !== 0);
  }

  @Exclude()
  public get areAllMediaInTheSameStatus(): boolean {
    return this.existingStatuses.length === 1;
  }

  constructor(model: Partial<PackageHandoverDocumentStatistics> = {}) {
    super();

    Object.assign(this, model);
  }
}
