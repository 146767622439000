import { ErrorHandler, Injectable } from '@angular/core';
import { configuration } from '@configurations';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  public handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const currentReloadsCount = parseInt(localStorage.getItem(configuration.app.reloads.storageKey), 10);

    if (
      chunkFailedMessage.test(error.message) &&
      currentReloadsCount <= configuration.app.reloads.maxCount
    ) {
      const nextReloadsCount = currentReloadsCount + 1;

      localStorage.setItem(configuration.app.reloads.storageKey, nextReloadsCount.toString());
      window.location.reload();
    }
  }
}
