import { FloorPlanAreaPoint } from '@shared/floor-plan-area';
import { Expose } from 'class-transformer';

export class ProjectFloorPlanThumbnailRequest {
  @Expose({ name: 'floor_plan_area' })
  public floorPlanArea: number;

  @Expose({ name: 'pin_coordinates', toClassOnly: true })
  public pinCoordinates: FloorPlanAreaPoint;

  @Expose()
  public type: Array<string> = ['general', 'area'];

  constructor(model: Partial<ProjectFloorPlanThumbnailRequest> = {}) {
    Object.assign(this, model);
  }
}
