import { Expose, Exclude } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { ProjectSortField, ProjectStatusEnum } from '../enums';

export class ProjectPaginationRequest extends PaginationRequest {
  @Expose()
  public name?: string;

  @Exclude()
  public sortBy?: ProjectSortField;

  @Expose()
  public status?: Array<ProjectStatusEnum>;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<ProjectPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
