import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';

export class PackageMatrixCompanyPaginationRequest extends PaginationRequest {
  @Expose({ name: 'package_matrix' })
  public packageMatrix?: number;

  constructor(model: Partial<PackageMatrixCompanyPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
