import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';

export class PackageActivityTaskPaginationRequest extends PaginationRequest {
  @Expose({ name: 'package_activity' })
  public packageActivity?: number;

  constructor(model: Partial<PackageActivityTaskPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
