import { Expose, Transform, Type } from 'class-transformer';
import { Recipient } from '@shared/recipient';
import { DateTime } from 'luxon';
import { Media } from '@shared/media';
import { User } from '@shared/user';
import { QualityIssue } from './quality-issue';
import { ClassGroup } from '@shared/class-group';

export class QualityIssueUpdate {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => QualityIssue)
  @Expose({ name: 'quality_issue', groups: [ClassGroup.MAIN] })
  public qualityIssue: QualityIssue;

  @Type(() => User)
  @Expose({ name: 'expanded_user', groups: [ClassGroup.MAIN] })
  public expandedUser: User;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public comment: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public files: Array<number>;

  @Type(() => Media)
  @Expose({ name: 'expanded_files', groups: [ClassGroup.MAIN] })
  public expandedFiles: Array<Media>;

  @Type(() => Boolean)
  @Expose({ name: 'is_comment', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public isComment: boolean;

  @Type(() => QualityIssue)
  @Expose({ name: 'old_data', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public oldData: Partial<QualityIssue>;

  @Type(() => QualityIssue)
  @Expose({ name: 'new_data', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public newData: Partial<QualityIssue>;

  @Type(() => Recipient)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public recipients: Array<Recipient>;

  @Type(() => Recipient)
  @Expose({ name: 'expanded_recipients', groups: [ClassGroup.MAIN] })
  public expandedRecipients: Array<Recipient>;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  constructor(model: Partial<QualityIssueUpdate> = {}) {
    Object.assign(this, model);
  }
}
