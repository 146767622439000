import { ClassGroup } from '@shared/class-group';
import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';

export class Company {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public name: string;

  @Type(() => Number)
  @Expose({ name: 'expanded_users_count', groups: [ClassGroup.MAIN] })
  public expandedUsersCount?: number;

  @Expose({ name: 'assigned_to_activity', groups: [ClassGroup.MAIN] })
  public assignedToActivity?: boolean;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  constructor(model: Partial<Company> = {}) {
    Object.assign(this, model);
  }
}
