import { Expose } from 'class-transformer';

export class ProjectPackageHandoverDocumentMediaBulkRequest {
  @Expose()
  public media: number;

  @Expose()
  public package: number;

  @Expose({ name: 'package_activities' })
  public packageActivities: Array<number>;

  @Expose({ name: 'package_handover_document_type' })
  public packageHandoverDocumentType: number;

  constructor(model: Partial<ProjectPackageHandoverDocumentMediaBulkRequest> = {}) {
    Object.assign(this, model);
  }
}
