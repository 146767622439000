import { Expose, Type } from 'class-transformer';
import { Package } from '@shared/package';
import { Media } from '@shared/media';
import { FloorPlanRevisionSerializedDataItem } from './serialized-data-item';
import { FloorPlan } from '@shared/floor-plan/models';

export class FloorPlanRevisionFloorPlan {
  @Type(() => Number)
  @Expose()
  public id?: number;

  @Type(() => Number)
  @Expose()
  public revision?: number;

  @Expose({ name: 'floor_plan' })
  public floorPlan: string;

  @Type(() => Package)
  @Expose({ name: 'expanded_package' })
  public expandedPackage?: Package;

  @Type(() => Media)
  @Expose({ name: 'expanded_media' })
  public expandedMedia?: Media;

  @Type(() => Media)
  @Expose({ name: 'expanded_image' })
  public expandedImage?: Media;

  @Type(() => FloorPlanRevisionSerializedDataItem)
  @Expose({ name: 'serialized_data' })
  public serializedData?: Array<FloorPlanRevisionSerializedDataItem<FloorPlan>>;

  constructor(model: Partial<FloorPlanRevisionFloorPlan> = {}) {
    Object.assign(this, model);
  }
}
