import { ClassGroup } from '@shared/class-group';
import { Type, Expose, Transform } from 'class-transformer';
import { toArray } from 'lodash';
import { DateTime } from 'luxon';
import { AssetRegisterData } from './asset-register-data';

export class AssetRegister {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public project: number;

  @Transform(({ value }) => toArray(value), { toClassOnly: true })
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public data: AssetRegisterData;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  constructor(model: Partial<AssetRegister> = {}) {
    Object.assign(this, model);
  }
}
