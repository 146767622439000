import { NgModule, Optional, SkipSelf } from '@angular/core';
import { User } from './models';
import { CommonUserModule } from '@shared/common-user';
import { UserService } from './user.service';
import { IsApprovedUserGuard } from './guards';

@NgModule({
  imports: [
    CommonUserModule.forRoot({
      userModel: User,
      userService: UserService,
      profileRelationsKey: 'expand',
      profileRelations: ['expanded_projects', 'expanded_user_company']
    })
  ],
  providers: [
    UserService,
    IsApprovedUserGuard
  ]
})
export class UserModule {
  constructor(@Optional() @SkipSelf() parentModule: UserModule) {
    if (parentModule) {
      throw new Error('UserModule is already loaded. Import it in the AppModule only');
    }
  }
}
