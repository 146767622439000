import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/store';
import { combineLatest, Observable, of } from 'rxjs';
import { Project, ProjectService } from '@shared/project';
import { AccountProjectsDetailsPageSelectors } from '@app/account/projects/details/shared/store';
import { User, UserGroupEnum, UserService } from '@shared/user';
import { exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class ProjectClientSubtaskAccessGuard implements CanActivate {
  private get project$(): Observable<Project> {
    return this.store.select(AccountProjectsDetailsPageSelectors.currentProject);
  }

  private get profile$(): Observable<User> {
    return this.userService.profile$;
  }

  constructor(
    private store: Store<AppState>,
    private userService: UserService,
    private projectService: ProjectService,
    private router: Router
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this.profile$.pipe(
        exhaustMap((profile) => {
          if (profile) {
            return of(profile);
          }

          return this.userService.refreshProfile();
        })
      ),
      this.project$.pipe(
        exhaustMap((project) => {
          if (project) {
            return of(project);
          }

          const id = parseInt(routerState.url.split('/')[3], 10);

          if (id) {
            return this.projectService.get(id);
          }

          this.router.navigate(['/not-found']);
        })
      )
    ])
      .pipe(
        map(([profile, project]) => {
          if (profile.group !== UserGroupEnum.CLIENT) {
            return true;
          }

          if (!project.isSubtaskVisibleForClients) {
            this.router.navigate(['/not-found']);
          }

          return project.isSubtaskVisibleForClients;
        })
      );
  }
}
