import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { Project } from '@shared/project';
import { Package } from '@shared/package';
import { PackageActivity } from '@shared/package-activity';

export class LocationPackageMatrix {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public id: number;

  @Type(() => Project)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public project: number;

  @Type(() => Package)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public package: number;

  @Type(() => PackageActivity)
  @Expose({ name: 'package_activity', groups: [ClassGroup.MAIN] })
  public packageActivity: PackageActivity;

  constructor(model: Partial<LocationPackageMatrix> = {}) {
    Object.assign(this, model);
  }
}
