import { AssetHandoverDocument } from '@shared/asset-handover-document';
import { AssetHandoverInformation } from '@shared/asset-handover-information';
import { AssetHandoverLocation } from '@shared/asset-handover-location';
import { LocationMatrix } from '@shared/location-matrix';
import { Exclude, Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { PackageActivity } from '@shared/package-activity';
import { AssetHandoverStatistics } from '@shared/asset-handover-statistics';

export class AssetHandover {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public name: string;

  @Type(() => Number)
  @Expose({ name: 'package_activity', groups: [ClassGroup.CREATING, ClassGroup.UPDATING] })
  public packageActivity: number;

  @Expose({ groups: [ClassGroup.CREATING, ClassGroup.BUILDING] })
  public building: string;

  @Expose({ groups: [ClassGroup.CREATING, ClassGroup.LEVEL] })
  public level: string;

  @Expose({ groups: [ClassGroup.CREATING, ClassGroup.AREA] })
  public area: string;

  @Type(() => AssetHandoverDocument)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public documents: Array<AssetHandoverDocument>;

  @Type(() => AssetHandoverInformation)
  @Expose({ name: 'asset_handover_information', groups: [ClassGroup.CREATING, ClassGroup.UPDATING] })
  public assetHandoverInformation: AssetHandoverInformation;

  @Expose({ name: 'activity_id', groups: [ClassGroup.MAIN] })
  public activityID: string;

  @Type(() => AssetHandoverLocation)
  @Expose({ name: 'asset_handover_locations', groups: [ClassGroup.MAIN] })
  public assetHandoverLocations: Array<AssetHandoverLocation>;

  @Type(() => LocationMatrix)
  @Expose({ name: 'expanded_location_matrix', groups: [ClassGroup.MAIN] })
  public expandedLocationMatrix: LocationMatrix;

  @Type(() => AssetHandoverStatistics)
  @Expose({ name: 'expanded_asset_handover_statistics', groups: [ClassGroup.MAIN] })
  public expandedAssetHandoverStatistics: AssetHandoverStatistics;

  @Type(() => AssetHandoverDocument)
  @Expose({ name: 'expanded_asset_handover_documents', groups: [ClassGroup.MAIN] })
  public expandedAssetHandoverDocuments: Array<AssetHandoverDocument>;

  @Type(() => AssetHandoverInformation)
  @Expose({ name: 'expanded_asset_handover_information', groups: [ClassGroup.MAIN] })
  public expandedAssetHandoverInformation: AssetHandoverInformation;

  @Type(() => PackageActivity)
  @Expose({ name: 'expanded_package_activity', groups: [ClassGroup.MAIN] })
  public expandedPackageActivity: PackageActivity;

  @Exclude()
  public get isInformationFilled(): boolean {
    return this.expandedAssetHandoverInformation && (
      !!this.expandedAssetHandoverInformation.warranty &&
      !!this.expandedAssetHandoverInformation.guidExternalRef &&
      !!this.expandedAssetHandoverInformation.modelNumber &&
      !!this.expandedAssetHandoverInformation.manufactureSerialNumber
    );
  }

  constructor(model: Partial<AssetHandover> = {}) {
    Object.assign(this, model);
  }
}
