export enum SubtaskSortField {
  ID = 'id',
  BUILDING = 'building',
  LEVEL = 'level',
  AREA = 'area',
  PACKAGE = 'package',
  PACKAGE_ACTIVITY = 'package_activity',
  PACKAGE_ACTIVITY_TASK = 'package_activity_task',
  DESCRIPTION = 'description',
  FILES = 'files_count',
  STATUS = 'status',
  CREATED_AT = 'created_at',
  DUE_DATE = 'due_date',
  STATUS_DATE = 'status_date',
  UPDATED_AT = 'updated_at',
  SUBCONTRACTOR = 'subcontractor',
  USER = 'user',
  DEFAULT_SORT = 'default_sort',
  LOCATION_DESCRIPTION = 'location_description'
}
