import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';

export class ProjectAreaPaginationRequest extends PaginationRequest {
  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose()
  public exclude?: Array<string>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'has_published_tasks' })
  public hasPublishedTasks?: boolean;

  @Expose({ name: 'has_handover_documents' })
  public hasHandoverDocuments?: boolean;

  @Expose({ name: 'exclude_where_asset_handover_exist' })
  public excludeWhereAssetHandoverExist?: boolean;

  constructor(model: Partial<ProjectAreaPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
