import { AssetHandoverDocumentMediaStatusEnum } from '../enums';

export class AssetHandoverDocumentMediaFilters {
  public search?: string;
  public package: number;
  public packageActivity?: Array<number>;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public status?: Array<AssetHandoverDocumentMediaStatusEnum>;
  public company?: Array<number>;
  public assetHandover?: number;
  public assetHandoverDocumentType?: number;
  public useLimitOffsetPagination?: boolean;
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<AssetHandoverDocumentMediaFilters> = {}) {
    Object.assign(this, model);
  }
}
