import { PackageHandoverDocument } from '@shared/package-handover-document';
import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';

export class PackageHandoverDocumentGroup {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public name: string;

  @Type(() => PackageHandoverDocument)
  @Expose({ name: 'package_handover_documents', groups: [ClassGroup.MAIN] })
  public packageHandoverDocuments: Array<PackageHandoverDocument>;

  constructor(model: Partial<PackageHandoverDocumentGroup> = {}) {
    Object.assign(this, model);
  }
}
