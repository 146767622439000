import { Expose } from 'class-transformer';

export class AssetHandoverStatisticsRequest {
  @Expose()
  public package?: number;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose()
  public area?: Array<string>;

  constructor(model: Partial<AssetHandoverStatisticsRequest> = {}) {
    Object.assign(this, model);
  }
}
