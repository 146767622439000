export class PackageFilters {
  public search?: string;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public documentType?: Array<number>;
  public filterByCompany?: boolean;
  public getTotalItemsCount?: boolean;
  public hasPublishedTasks?: boolean;
  public hasHandoverDocuments?: boolean;
  public hasPackageHandoverDocuments?: boolean;

  constructor(model: Partial<PackageFilters> = {}) {
    Object.assign(this, model);
  }
}
