import { Expose, Transform, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { DateTime } from 'luxon';

export class LocationMatrix {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public id: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public project: number;

  @Transform(({ value }) => value ? 1 : undefined, { toPlainOnly: true })
  @Expose({ name: 'delete_matrix', groups: [ClassGroup.UPDATING] })
  public isDeleteMatrix: boolean;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING, ClassGroup.BUILDING] })
  public building: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING, ClassGroup.LEVEL] })
  public level: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING, ClassGroup.AREA] })
  public area: string;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  constructor(model: Partial<LocationMatrix> = {}) {
    Object.assign(this, model);
  }
}
