import { Expose } from 'class-transformer';
import { ClassGroup } from '../../class-group';

export class PackageMatrixCreation {
  @Expose({ groups: [ClassGroup.CREATING] })
  public package: number;

  @Expose({ name: 'package_activity', groups: [ClassGroup.CREATING] })
  public packageActivity: Array<number>;

  constructor(model: Partial<PackageMatrixCreation> = {}) {
    Object.assign(this, model);
  }
}
