import { Action, createReducer, on } from '@ngrx/store';
import { AccountProjectsDetailsPageActions } from './actions';
import { AccountProjectsDetailsPageState } from './state';
import { ProjectNews } from '@shared/project-news';
import { Project } from '@shared/project';
import { cloneDeep, find, reject, sortBy, uniqBy } from 'lodash';
import { User } from '@shared/user';
import { ProjectResponseCategory } from '@shared/project-response-category/models/response-category';

const initialState = new AccountProjectsDetailsPageState();

const reducer = createReducer(
  initialState,
  on(AccountProjectsDetailsPageActions.resetState, () => initialState),
  on(AccountProjectsDetailsPageActions.setCurrentProject, (state, { currentProject }) => ({
    ...state,
    currentProject
  })),
  on(AccountProjectsDetailsPageActions.addNewsSuccess, (state, { response }) => ({
    ...state,
    currentProject: addNews(state.currentProject, response)
  })),
  on(AccountProjectsDetailsPageActions.updateNewsSuccess, (state, { news }) => ({
    ...state,
    currentProject: updateNews(state.currentProject, news)
  })),
  on(AccountProjectsDetailsPageActions.deleteNewsSuccess, (state, { id }) => ({
    ...state,
    currentProject: deleteNews(state.currentProject, id)
  })),
  on(AccountProjectsDetailsPageActions.addContactsSuccess, (state, { contacts }) => ({
    ...state,
    currentProject: addContacts(state.currentProject, contacts)
  })),
  on(AccountProjectsDetailsPageActions.removeContactSuccess, (state, { id }) => ({
    ...state,
    currentProject: removeContact(state.currentProject, id)
  })),
  on(AccountProjectsDetailsPageActions.addUsersSuccess, (state, { users }) => ({
    ...state,
    currentProject: addUsers(state.currentProject, users)
  })),
  on(AccountProjectsDetailsPageActions.addResponseCategorySuccess, (state, { response }) => ({
    ...state,
    currentProject: addResponseCategory(state.currentProject, response)
  })),
  on(AccountProjectsDetailsPageActions.removeResponseCategorySuccess, (state, { id }) => ({
    ...state,
    currentProject: removeResponseCategory(state.currentProject, id)
  })),
  on(AccountProjectsDetailsPageActions.showAccessDenied, (state) => ({
    ...state,
    isAccessDeniedShown: true
  })),
  on(AccountProjectsDetailsPageActions.hideAccessDenied, (state) => ({
    ...state,
    isAccessDeniedShown: false
  }))
);

export const accountProjectsDetailsPageReducer = (state: AccountProjectsDetailsPageState | undefined, action: Action): AccountProjectsDetailsPageState => reducer(state, action);

const addNews = (data: Project, news: ProjectNews): Project => {
  const clonedData = cloneDeep(data);

  clonedData.expandedNews.push(news);

  return clonedData;
};

const updateNews = (data: Project, news: ProjectNews): Project => {
  const clonedData = cloneDeep(data);
  const desiredNews = find(clonedData.expandedNews, { id: news.id });

  if (desiredNews) {
    Object.assign(desiredNews, news);
  }

  return clonedData;
};

const deleteNews = (data: Project, id: number): Project => {
  const clonedData = cloneDeep(data);

  clonedData.expandedNews = reject(clonedData.expandedNews, { id });

  return clonedData;
};

const addContacts = (data: Project, contacts: Array<User>): Project => {
  const clonedData = cloneDeep(data);

  clonedData.expandedKeyContacts = uniqBy([...clonedData.expandedKeyContacts, ...contacts], 'id');

  return clonedData;
};

const removeContact = (data: Project, id: number): Project => {
  const clonedData = cloneDeep(data);

  clonedData.expandedKeyContacts = reject(clonedData.expandedKeyContacts, { id });

  return clonedData;
};

const addUsers = (data: Project, users: Array<User>): Project => {
  const clonedData = cloneDeep(data);

  clonedData.users = [...(clonedData.users || []), ...users];

  return clonedData;
};

const addResponseCategory = (data: Project, responseCategory: ProjectResponseCategory): Project => {
  const clonedData = cloneDeep(data);

  clonedData.expandedResponseCategories = sortBy(
    [...clonedData.expandedResponseCategories, responseCategory],
    ['name']
  );

  return clonedData;
};

const removeResponseCategory = (data: Project, id: number): Project => {
  const clonedData = cloneDeep(data);

  clonedData.expandedResponseCategories = reject(clonedData.expandedResponseCategories, { id });

  return clonedData;
};
