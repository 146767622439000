import { Exclude, Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { PackageSortField } from '../enums';

export class PackagePaginationRequest extends PaginationRequest {
  @Exclude()
  public sortBy?: PackageSortField;

  @Expose({ name: 'document_type' })
  public documentType?: Array<number>;

  @Expose({ name: 'filter_by_company' })
  public filterByCompany?: boolean;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  @Expose({ name: 'has_published_tasks' })
  public hasPublishedTasks?: boolean;

  @Expose({ name: 'has_handover_documents' })
  public hasHandoverDocuments?: boolean;

  @Expose({ name: 'has_package_handover_documents' })
  public hasPackageHandoverDocuments?: boolean;

  constructor(model: Partial<PackagePaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
