import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { User } from '@shared/user';
import { DateTime } from 'luxon';
import { FloorPlanRevisionMeta } from './meta';
import { FloorPlanRevisionFloorPlan } from './floor-plan';
import { FloorPlanRevisionFloorPlanArea } from './floor-plan-area';
import { FloorPlanRevisionEventType } from '../enums';

export class FloorPlanRevision {
  @Type(() => Number)
  @Expose()
  public id?: number;

  @Expose()
  public media?: number;

  @Expose()
  public user?: number;

  @Type(() => User)
  @Expose({ name: 'expanded_user' })
  public expandedUser?: User;

  @Type(() => FloorPlanRevisionFloorPlan)
  @Expose({ name: 'expanded_floor_plan' })
  public expandedFloorPlan?: FloorPlanRevisionFloorPlan;

  @Type(() => FloorPlanRevisionFloorPlanArea)
  @Expose({ name: 'expanded_floor_plan_areas' })
  public expandedFloorPlanAreas?: Array<FloorPlanRevisionFloorPlanArea>;

  @Type(() => FloorPlanRevisionMeta)
  @Expose({ name: 'expanded_meta' })
  public expandedMeta?: Array<FloorPlanRevisionMeta>;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at' })
  public createdAt: DateTime;

  @Exclude()
  public get eventTypeKey(): string {
    return this.expandedMeta?.length ? `SHARED.FLOOR_PLAN_REVISION_EVENT_TYPES.TEXT_${this.expandedMeta[0].eventType.toUpperCase()}` : '';
  }

  @Exclude()
  public get isAreasUpdate(): boolean {
    return this.expandedMeta?.[0]?.eventType === FloorPlanRevisionEventType.AREAS_UPDATED;
  }

  constructor(model: Partial<FloorPlanRevision> = {}) {
    Object.assign(this, model);
  }
}
