import { Exclude, Expose, Type } from 'class-transformer';

export class PaginationResponse<T> {
  @Expose({ name: 'previous_page' })
  public previousPage: number;

  @Expose({ name: 'next_page' })
  public nextPage: number;

  @Expose({ name: 'last_page' })
  public lastPage: number;

  @Type((options) => options.newObject.type)
  public items: Array<T>;

  @Type((options) => options.newObject.type)
  public results: Array<T>;

  public count: number;

  @Exclude()
  private type: new() => T;

  constructor(type: new() => T) {
    this.type = type;
  }
}
