import { FloorPlanAreaPinEnum } from '@shared/floor-plan-area-pin';
import { Media } from '@shared/media';
import { Expose, Type } from 'class-transformer';

export class FloorPlanAreaPinThumbnail {
  @Type(() => Number)
  @Expose()
  public id?: number;

  @Expose({ name: 'floor_plan_area_pin' })
  public floorPlanAreaPin: number;

  @Type(() => Media)
  @Expose({ name: 'expanded_thumbnail' })
  public expandedThumbnail: Media;

  @Expose()
  public type: FloorPlanAreaPinEnum;

  @Expose()
  public thumbnail: number;

  constructor(model: Partial<FloorPlanAreaPinThumbnail> = {}) {
    Object.assign(this, model);
  }
}
