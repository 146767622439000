import { Expose, Exclude } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { UserSortField, UserStatusEnum } from '../enums';
import { UserRelationType } from '../types';

export class UserPaginationRequest extends PaginationRequest {
  @Expose()
  public company?: Array<number>;

  @Expose()
  public group?: Array<number>;

  @Expose()
  public project?: number;

  @Expose({ name: 'last_name' })
  public lastName?: string;

  @Exclude()
  public sortBy?: UserSortField;

  @Expose({ name: 'expand' })
  public relations?: Array<UserRelationType>;

  @Expose({ name: 'project_or_company_admins' })
  public projectOrCompanyAdmins?: number;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  @Expose({ name: 'project__exclude' })
  public projectExclude?: number;

  @Expose({ name: 'exclude_group' })
  public groupExclude?: number;

  @Expose({ name: 'project__key_contacts__exclude_or_company_admins' })
  public projectKeyContactsExcludeOrCompanyAdmins?: number;

  @Expose({ name: 'subcontractors_of_package_activity' })
  public subcontractorsOfPackageActivity?: number;

  @Expose({ name: 'subcontractors_company' })
  public subcontractorsCompany?: number;

  @Expose()
  public status?: Array<string>;

  @Expose({ name: 'exclude_status' })
  public statusExclude?: Array<UserStatusEnum>;

  constructor(model: Partial<UserPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
