import { SubtaskStatus } from '../enums';
import { DateTime } from 'luxon';

export class SubtaskFilters {
  public search?: string;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public package?: Array<number>;
  public packageActivity?: Array<number>;
  public status?: Array<SubtaskStatus>;
  public isClosed?: boolean;
  public isDefect?: boolean;
  public user?: Array<number>;
  public recipient?: Array<number>;
  public allWithActivity?: boolean;
  public company?: Array<number>;
  public startDate?: DateTime;
  public endDate?: DateTime;
  public updatedAtStartDate?: DateTime;
  public updatedAtEndDate?: DateTime;
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<SubtaskFilters> = {}) {
    Object.assign(this, model);
  }
}
