import { PackageHandoverDocumentMediaStatusEnum } from '@shared/package-handover-document-media/enums';

export class PackageActivityFilters {
  public id?: Array<number>;
  public search?: string;
  public excludeBoundWithProject?: number;
  public package?: Array<number>;
  public project?: number;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public hasPublishedTasks?: boolean;
  public documentType?: Array<number>;
  public filterByCompany?: boolean;
  public hasPackageHandoverDocuments?: boolean;
  public hasAssetHandoverDocuments?: boolean;
  public hasAssetHandovers?: boolean;
  public hasHandoverDocuments?: boolean;
  public withDeletedAssetHandovers?: boolean;
  public getTotalItemsCount?: boolean;
  public documentGroup?: Array<number>;
  public packageHandoverDocumentCompany?: Array<number>;
  public assetHandoverDocumentCompany?: Array<number>;
  public status?: Array<PackageHandoverDocumentMediaStatusEnum>;

  constructor(model: Partial<PackageActivityFilters> = {}) {
    Object.assign(this, model);
  }
}
