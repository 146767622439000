/* eslint-disable no-undef */
import { LocationMatrixPackage } from './location-matrix-package';

export class LocationMatrixPackagesData {
  [packageID: number]: {
    [locationID: number]: {
      [packageActivityID: number]: LocationMatrixPackage;
    };
  };

  constructor(model: Partial<LocationMatrixPackagesData> = {}) {
    Object.assign(this, model);
  }
}
