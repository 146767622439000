import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '@shared/class-group';
import { Company } from '@shared/company';
import { HandoverDocumentType } from './handover-document-type';
import { Package } from '@shared/package';
import { PackageActivity } from '@shared/package-activity';
import { Media } from '@shared/media';

export class HandoverDocument {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public project: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public building: string;

  @Expose({ groups: [ClassGroup.MAIN] })
  public level: string;

  @Expose({ groups: [ClassGroup.MAIN] })
  public area: string;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public package: number;

  @Type(() => Number)
  @Expose({ name: 'package_activity', groups: [ClassGroup.MAIN] })
  public packageActivity: number;

  @Type(() => Number)
  @Expose({ name: 'document_type', groups: [ClassGroup.MAIN] })
  public documentType: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public company: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public media: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public filename: string;

  @Expose({ groups: [ClassGroup.MAIN] })
  public uid: string;

  @Expose({ groups: [ClassGroup.MAIN] })
  public information: string;

  @Expose({ name: 'file_type', groups: [ClassGroup.MAIN] })
  public fileType: string;

  @Type(() => Package)
  @Expose({ name: 'expanded_package', groups: [ClassGroup.MAIN] })
  public expandedPackage: Package;

  @Type(() => PackageActivity)
  @Expose({ name: 'expanded_package_activity', groups: [ClassGroup.MAIN] })
  public expandedPackageActivity: PackageActivity;

  @Type(() => Company)
  @Expose({ name: 'expanded_company', groups: [ClassGroup.MAIN] })
  public expandedCompany: Company;

  @Type(() => Media)
  @Expose({ name: 'expanded_media', groups: [ClassGroup.MAIN] })
  public expandedMedia: Media;

  @Type(() => HandoverDocumentType)
  @Expose({ name: 'expanded_document_type', groups: [ClassGroup.MAIN] })
  public expandedDocumentType: HandoverDocumentType;

  constructor(model: Partial<HandoverDocument> = {}) {
    Object.assign(this, model);
  }
}
