import { Expose, Type, Transform, Exclude } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { Media } from '@shared/media';
import { ProjectStatusEnum } from '../enums';
import { DateTime } from 'luxon';
import { ProjectNews } from '@shared/project-news';
import { maxBy } from 'lodash';
import { User } from '@shared/user/models';
import { ProjectResponseCategory } from '@shared/project-response-category/models/response-category';

export class Project {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public name: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public number: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public value: string;

  @Type(() => Media)
  @Expose({ groups: [ClassGroup.MAIN] })
  public image: Media;

  @Type(() => Number)
  @Expose({ name: 'image_id', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public imageID: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public status: ProjectStatusEnum;

  @Expose({ name: 'show_estimated_man_hours', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public showEstimatedManHours: boolean;

  @Expose({ name: 'is_subtask_visible_for_clients', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public isSubtaskVisibleForClients: boolean;

  @Expose({ name: 'is_task_visible_for_clients', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public isTaskVisibleForClients: boolean;

  @Type(() => ProjectNews)
  @Expose({ name: 'expanded_news', groups: [ClassGroup.MAIN] })
  public expandedNews: Array<ProjectNews>;

  @Type(() => User)
  @Expose({ name: 'expanded_key_contacts', groups: [ClassGroup.MAIN] })
  public expandedKeyContacts: Array<User>;

  @Type(() => User)
  @Expose({ name: 'expanded_response_categories', groups: [ClassGroup.MAIN] })
  public expandedResponseCategories: Array<ProjectResponseCategory>;

  @Type(() => User)
  @Expose({ groups: [ClassGroup.MAIN] })
  public users: Array<User>;

  @Expose({ name: 'value_currency', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public valueCurrency: string;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISODate(), { toPlainOnly: true, groups: [ClassGroup.CREATING, ClassGroup.UPDATING] })
  @Expose({ name: 'start_date', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public startDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISODate(), { toPlainOnly: true, groups: [ClassGroup.CREATING, ClassGroup.UPDATING] })
  @Expose({ name: 'completion_date', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public completionDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  @Exclude()
  public get statusTranslationKey(): string {
    const key = Object.keys(ProjectStatusEnum).find((status) => ProjectStatusEnum[status] === this.status);

    return `SHARED.PROJECT_STATUSES.TEXT_${key}`;
  }

  @Exclude()
  public get hasImage(): boolean {
    return !!(this.image?.id && this.image?.link.trim());
  }

  @Exclude()
  public get latestNews(): ProjectNews | null {
    const news = this.expandedNews;

    if (!news.length) {
      return null;
    }

    if (news.length === 1) {
      return news[0];
    }

    return maxBy(news, ({ date, updatedAt }) => (date || updatedAt).ordinal);
  }

  @Exclude()
  public get smallThumbnailLink(): string {
    return this.getLinkWithPrefix('32x32_');
  }

  @Exclude()
  public get smallThumbnail2xLink(): string {
    return this.getLinkWithPrefix('64x64_');
  }

  @Exclude()
  public get mediumThumbnailLink(): string {
    return this.getLinkWithPrefix('88x88_');
  }

  @Exclude()
  public get mediumThumbnail2xLink(): string {
    return this.getLinkWithPrefix('176x176_');
  }

  @Exclude()
  public get largeThumbnailLink(): string {
    return this.getLinkWithPrefix('540x540_');
  }

  @Exclude()
  public get largeThumbnail2xLink(): string {
    return this.getLinkWithPrefix('1080x1080_');
  }

  constructor(model: Partial<Project> = {}) {
    Object.assign(this, model);
  }

  private getLinkWithPrefix(prefix: string): string {
    if (!this.hasImage) {
      return;
    }

    return this.image.link
      .split('/')
      .map((item, index, array) => {
        if (index === array.length - 1) {
          item = prefix + item;
        }

        return item;
      })
      .join('/');
  }
}
