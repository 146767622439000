import { Expose, Type, Transform } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { TaskStatusEnum } from '../enums';
import { DateTime } from 'luxon';
import { User } from '@shared/user';
import { PackageActivity } from '@shared/package-activity';
import { PackageActivityTask } from '@shared/package-activity-task';
import { LocationMatrix } from '@shared/location-matrix';
import { Package } from '@shared/package';
import { Media } from '@shared/media';

export class Task {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN] })
  public status: TaskStatusEnum;

  @Expose({ name: 'expanded_comment', groups: [ClassGroup.MAIN] })
  public expandedComment: string;

  @Type(() => LocationMatrix)
  @Expose({ name: 'location_matrix', groups: [ClassGroup.MAIN] })
  public locationMatrix: LocationMatrix;

  @Expose({ groups: [ClassGroup.MAIN] })
  public Package: number;

  @Type(() => Package)
  @Expose({ name: 'expanded_package', groups: [ClassGroup.MAIN] })
  public expandedPackage: Package;

  @Type(() => PackageActivity)
  @Expose({ name: 'package_activity', groups: [ClassGroup.MAIN] })
  public packageActivity: PackageActivity;

  @Type(() => PackageActivityTask)
  @Expose({ name: 'package_activity_task', groups: [ClassGroup.MAIN] })
  public packageActivityTask: PackageActivityTask;

  @Type(() => User)
  @Expose({ groups: [ClassGroup.MAIN] })
  public user: User;

  @Type(() => Media)
  @Expose({ name: 'expanded_last_attachments', groups: [ClassGroup.MAIN] })
  public expandedLastAttachments: Array<Media>;

  @Type(() => User)
  @Expose({ name: 'expanded_user', groups: [ClassGroup.MAIN] })
  public expandedUser: User;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  constructor(model: Partial<Task> = {}) {
    Object.assign(this, model);
  }
}
