import { AssetHandoverDocumentMedia } from '@shared/asset-handover-document-media';
import { User } from '@shared/user';
import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { ClassGroup } from '../../class-group';
import { Company } from '@shared/company';

export class AssetHandoverDocumentMediaUpdate {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => Number)
  @Expose({ name: 'asset_handover_document_media', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public assetHandoverDocumentMedia: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public user: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public comment: string;

  @Type(() => User)
  @Expose({ name: 'expanded_user', groups: [ClassGroup.MAIN] })
  public expandedUser: User;

  @Type(() => Company)
  @Expose({ name: 'expanded_company', groups: [ClassGroup.MAIN] })
  public expandedCompany: Company;

  @Type(() => AssetHandoverDocumentMedia)
  @Expose({ name: 'old_data', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public oldData: Partial<AssetHandoverDocumentMedia>;

  @Type(() => AssetHandoverDocumentMedia)
  @Expose({ name: 'new_data', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public newData: Partial<AssetHandoverDocumentMedia>;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  constructor(model: Partial<AssetHandoverDocumentMediaUpdate> = {}) {
    Object.assign(this, model);
  }
}
