import { Exclude, Expose, Transform } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { SubtaskSortField, SubtaskStatus } from '@shared/subtask';
import { DateTime } from 'luxon';

export class SubtaskPaginationRequest extends PaginationRequest {
  @Expose()
  public name?: string;

  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose()
  public area?: Array<string>;

  @Expose()
  public package?: Array<number>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  @Expose()
  @Transform(({ value }) => value && value.includes(SubtaskStatus.IN_PROGRESS)
    ? [...value, SubtaskStatus.INSPECTION_REJECTED]
    : value
  , { toPlainOnly: true })
  public status?: Array<SubtaskStatus>;

  @Expose({ name: 'is_defect' })
  public isDefect?: boolean;

  @Expose()
  public user?: Array<number>;

  @Expose()
  public recipient?: Array<number>;

  @Expose()
  public company?: Array<number>;

  @Exclude()
  public sortBy?: SubtaskSortField;

  @Exclude()
  public extraSortBy?: SubtaskSortField;

  @Expose({ name: 'all_with_activity' })
  public allWithActivity?: boolean;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'created_at__gte' })
  public startDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value
    ?.endOf('day')
    ?.startOf('second')
    ?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'created_at__lte' })
  public endDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'updated_at__gte' })
  public updatedAtStartDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value
    ?.endOf('day')
    ?.startOf('second')
    ?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'updated_at__lte' })
  public updatedAtEndDate: DateTime;

  constructor(model: Partial<SubtaskPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
