import { AreaSelectFilters } from '@app/account/shared/area-select/models';

export class FloorPlanSelectAreaDialogData {
  public area?: string;
  public isEdit?: boolean;
  public filters?: AreaSelectFilters;

  constructor(model: Partial<FloorPlanSelectAreaDialogData>) {
    Object.assign(this, model);
  }
}
