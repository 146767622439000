import { Expose, Type, Transform } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { PackageActivityTask } from '@shared/package-activity-task';
import { Media } from '@shared/media';
import { AssetHandoverStatistics } from '@shared/asset-handover-statistics';
import { PackageHandover } from '@shared/package-handover';

export class PackageActivity {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public name: string;

  @Transform(({ value }) => value ? value : null, { toPlainOnly: true })
  @Expose({ name: 'activity_id', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public activityID: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public description: string;

  @Type(() => Number)
  @Expose({ name: 'description_image', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public descriptionImage: number;

  @Expose({ name: 'expanded_description', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public expandedDescription: string;

  @Type(() => Media)
  @Expose({ name: 'expanded_description_image', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public expandedDescriptionImage: Media;

  @Type(() => PackageActivityTask)
  @Expose({ name: 'expanded_package_activity_tasks', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public expandedPackageActivityTasks: Array<PackageActivityTask>;

  @Type(() => AssetHandoverStatistics)
  @Expose({ name: 'expanded_asset_handover_statistics', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public expandedAssetHandoverStatistics: AssetHandoverStatistics;

  @Type(() => PackageActivityTask)
  @Expose({ name: 'package_activity_tasks', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public packageActivityTasks: Array<PackageActivityTask>;

  @Type(() => Boolean)
  @Expose({ name: 'expanded_modified', groups: [ClassGroup.MAIN] })
  public expandedModified: boolean;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public files: Array<number>;

  @Type(() => Media)
  @Expose({ name: 'expanded_files', groups: [ClassGroup.MAIN] })
  public expandedFiles: Array<Media>;

  @Type(() => Number)
  @Expose({ name: 'package_matrix_id', groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public packageMatrixID: number;

  @Type(() => Number)
  @Expose({ name: 'expanded_projects_count', groups: [ClassGroup.MAIN] })
  public expandedProjectsCount?: number;

  @Expose({ name: 'expanded_can_add_asset_handovers', groups: [ClassGroup.MAIN] })
  public expandedCanAddAssetHandovers?: boolean;

  @Type(() => PackageHandover)
  @Expose({ name: 'expanded_package_handover', groups: [ClassGroup.MAIN] })
  public expandedPackageHandover?: PackageHandover;

  constructor(model: Partial<PackageActivity> = {}) {
    Object.assign(this, model);
  }
}
