import { Exclude, Expose, Transform } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { TaskSortField, TaskStatusEnum } from '../enums';
import { DateTime } from 'luxon';

export class TaskPaginationRequest extends PaginationRequest {
  @Expose()
  public name?: string;

  @Expose()
  public status?: Array<TaskStatusEnum>;

  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose()
  public area?: Array<string>;

  @Expose()
  public package?: number | Array<number>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'get_total_items_count' })
  public getTotalItemsCount?: boolean;

  @Expose()
  public user?: Array<number>;

  @Expose()
  public recipient?: Array<number>;

  @Exclude()
  public sortBy?: TaskSortField;

  @Expose({ name: 'all_with_activity' })
  public allWithActivity?: boolean;

  @Expose({ name: 'is_default_for_subtask' })
  public isDefaultForSubtask?: boolean;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'created_at__gte' })
  public startDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value
    ?.endOf('day')
    ?.startOf('second')
    ?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'created_at__lte' })
  public endDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'updated_at__gte' })
  public updatedAtStartDate: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Transform(({ value }) => value
    ?.endOf('day')
    ?.startOf('second')
    ?.toISO({ includeOffset: false, suppressMilliseconds: true }), { toPlainOnly: true })
  @Expose({ name: 'updated_at__lte' })
  public updatedAtEndDate: DateTime;

  @Expose({ name: 'exclude_status' })
  public statusExclude?: Array<TaskStatusEnum>;

  constructor(model: Partial<TaskPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
