import { Expose } from 'class-transformer';

export class ProjectLocationMatrixExportRequest {
  @Expose()
  public package?: number;

  @Expose()
  public level?: string;

  constructor(model: Partial<ProjectLocationMatrixExportRequest> = {}) {
    Object.assign(this, model);
  }
}
