import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';

export class AssetHandoverInformation {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Expose({ name: 'guid_external_ref', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public guidExternalRef: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public warranty: string;

  @Expose({ name: 'manufacture_serial_number', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public manufactureSerialNumber: string;

  @Expose({ name: 'model_number', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public modelNumber: string;

  @Type(() => Number)
  @Expose({ name: 'asset_handover', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public assetHandover: number;

  constructor(model: Partial<AssetHandoverInformation> = {}) {
    Object.assign(this, model);
  }
}
