import { Expose, Transform, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';

export class PackageActivityTask {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public id: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public description: string;

  @Type(() => Boolean)
  @Expose({ name: 'is_not_applicable_status_by_default', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public isNotApplicableStatusByDefault: boolean;

  @Type(() => Boolean)
  @Expose({ name: 'is_default_for_subtask', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public isDefaultForSubtask: boolean;

  @Type(() => Boolean)
  @Expose({ name: 'is_photo_required', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public isPhotoRequired: boolean;

  @Type(() => Boolean)
  @Expose({ name: 'expanded_hidden', groups: [ClassGroup.MAIN] })
  public expandedHidden: boolean;

  @Transform(({ value }) => value ? value.trim() : null, { toPlainOnly: true })
  @Expose({ name: 'photo_requirement_comment', groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public photoRequirementComment: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public order: number;

  constructor(model: Partial<PackageActivityTask> = {}) {
    Object.assign(this, model);
  }
}
