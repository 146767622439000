import { ClassGroup } from '@shared/class-group';
import { FloorPlanArea, FloorPlanAreaPinThumbnail, FloorPlanAreaPoint } from '@shared/floor-plan-area/models';
import { QualityIssue } from '@shared/quality-issue';
import { Subtask } from '@shared/subtask';
import { Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { FloorPlanAreaPinEnum } from '../enums';
import { Media } from '@shared/media';

export class FloorPlanAreaPin {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id?: number;

  @Type(() => Number)
  @Expose({ name: 'floor_plan_area', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public floorPlanArea: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public pin: FloorPlanAreaPoint;

  @Transform(({ value }) => value?.files
    ? plainToClass(Subtask, value, { groups: [ClassGroup.MAIN] })
    : plainToClass(QualityIssue, value, { groups: [ClassGroup.MAIN] })
  )
  @Expose({ name: 'expanded_object_id', groups: [ClassGroup.MAIN] })
  public expandedObjectID: QualityIssue | Subtask;

  @Type(() => FloorPlanArea)
  @Expose({ name: 'expanded_floor_plan_area', groups: [ClassGroup.MAIN] })
  public expandedFloorPlanArea: FloorPlanArea;

  @Type(() => FloorPlanAreaPinThumbnail)
  @Expose({ name: 'expanded_thumbnails', groups: [ClassGroup.MAIN] })
  public expandedThumbnails: Array<FloorPlanAreaPinThumbnail>;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  @Exclude()
  public get areaThumbnail(): Media {
    return this.expandedThumbnails
      .find((thumbnail) => thumbnail.type === FloorPlanAreaPinEnum.AREA)
      ?.expandedThumbnail;
  }

  @Exclude()
  public get generalThumbnail(): Media {
    return this.expandedThumbnails
      .find((thumbnail) => thumbnail.type === FloorPlanAreaPinEnum.GENERAL)
      ?.expandedThumbnail;
  }

  constructor(model: Partial<FloorPlanAreaPin> = {}) {
    Object.assign(this, model);
  }
}
