import { AssetHandoverBulkItem } from '@shared/asset-handover-bulk-item';
import { Expose } from 'class-transformer';

export class ProjectAssetHandoverDocumentMediaBulkRequest {
  @Expose()
  public media: Array<number>;

  @Expose({ name: 'bulk_items' })
  public bulkItems: Array<AssetHandoverBulkItem>;

  @Expose({ name: 'document_type' })
  public documentType: number;

  @Expose({ name: 'asset_handover_document' })
  public assetHandoverDocument: number;

  constructor(model: Partial<ProjectAssetHandoverDocumentMediaBulkRequest> = {}) {
    Object.assign(this, model);
  }
}
