import { Expose } from 'class-transformer';

export class FloorPlanAreaPolygon {
  @Expose()
  public id?: number;

  @Expose()
  public area?: string;

  @Expose()
  public points: Array<{ x: number; y: number }>;

  constructor(model: Partial<FloorPlanAreaPolygon> = {}) {
    Object.assign(this, model);
  }
}
