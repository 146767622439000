import { Expose } from 'class-transformer';

export class ProjectMediaRequest {
  @Expose()
  public media: Array<number>;

  constructor(model: Partial<ProjectMediaRequest> = {}) {
    Object.assign(this, model);
  }
}
