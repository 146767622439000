import { CommonUserService } from '@shared/common-user';
import { Injectable, Injector } from '@angular/core';
import { User, UserFilters, UserPaginationRequest } from './models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClassGroup } from '@shared/class-group';
import { PaginationData, PaginationResponse } from '@shared/pagination';
import { UserSortField } from './enums';
import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { isUndefined, omitBy } from 'lodash';
import { PermissionsService } from '@shared/permissions';
import { configuration } from '@configurations';
import { UserRelationType } from './types';
import * as Sentry from '@sentry/angular';
import { FileType } from '@shared/file-save';

@Injectable()
export class UserService extends CommonUserService<User> {
  public endpoint: string;

  constructor(
    protected injector: Injector,
    private readonly permissionsService: PermissionsService
  ) {
    super(injector);

    this.endpoint = '/users';
  }

  public search({ page, perPage, filters, sortBy, desc, relations }: {
    page?: number;
    perPage?: number;
    filters?: UserFilters;
    sortBy?: UserSortField;
    desc?: boolean;
    relations?: Array<UserRelationType>;
  } = {}): Observable<PaginationResponse<User> | PaginationData> {
    const request = new UserPaginationRequest({ ...filters, page, perPage, sortBy, desc, relations });

    return this.apiService
      .get<PaginationResponse<User>>(this.endpoint, omitBy(classToPlain<UserPaginationRequest>(request), isUndefined))
      .pipe(
        map((response) => {
          if (filters?.getTotalItemsCount) {
            return plainToClass(PaginationData, response, { groups: [ClassGroup.MAIN] });
          }

          return plainToClassFromExist(new PaginationResponse<User>(User), response, { groups: [ClassGroup.MAIN] });
        })
      );
  }

  public refreshProfile(): Observable<User> {
    return super
      .refreshProfile()
      .pipe(
        tap((profile) => {
          Sentry.setUser({ email: profile.email });

          localStorage.setItem(configuration.userGroupID.storageKey, profile.group.toString());

          this.permissionsService.refreshPermissions();
        })
      );
  }

  public create(user: User): Observable<User> {
    return this.apiService
      .post<User>(this.endpoint, omitBy(this.userToPlain(user, { groups: [ClassGroup.CREATING] }), isUndefined))
      .pipe(
        map((response) => this.plainToUser(response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public get(id: number): Observable<User> {
    return this.apiService
      .get<User>(`${this.endpoint}/${id}`, { expand: 'expanded_user_company' })
      .pipe(
        map((response) => this.plainToUser(response, { groups: [ClassGroup.MAIN] }))
      );
  }

  public update(user: User): Observable<void> {
    return this.apiService.put(`${this.endpoint}/${user.id}`, omitBy(this.userToPlain(user, { groups: [ClassGroup.UPDATING] }), isUndefined));
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }

  public restore(email: string): Observable<void> {
    return this.apiService.post(`${this.endpoint}/${email}/restore`);
  }

  public approve(id: number): Observable<void> {
    return this.apiService.post(`${this.endpoint}/${id}/approve`);
  }

  public generateUserReport(type: FileType, { sortBy, filters }: {
    sortBy?: UserSortField;
    filters?: UserFilters;
  } = {}): Observable<void> {
    const request = new UserPaginationRequest({ ...filters, sortBy });

    return this.apiService.get(
      `${this.endpoint}/${type}`,
      omitBy(classToPlain<UserPaginationRequest>(request), isUndefined)
    );
  }
}
