import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { configuration } from '@configurations';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  public canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated$
      .pipe(
        map((isAuthenticated) => {
          if (!isAuthenticated) {
            sessionStorage.setItem(
              configuration.requiredURL.storageKey,
              this.router.getCurrentNavigation().extractedUrl.toString()
            );

            this.router.navigate(['/log-in']);
          }

          return isAuthenticated;
        })
      );
  }
}
