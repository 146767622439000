import { Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';

export class ProjectRecipientPaginationRequest extends PaginationRequest {
  @Expose({ name: 'exclude_user_group' })
  public excludeUserGroup?: Array<number>;

  constructor(model: Partial<ProjectRecipientPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
