import { CanDeactivate, ActivatedRouteSnapshot } from '@angular/router';
import { CanDeactivateComponent } from '../interfaces/can-deactivate';
import { Injectable } from '@angular/core';
import { exhaustMap, switchMap } from 'rxjs/operators';
import { DialogService } from '@shared/dialog';
import { DialogConfirmationComponent } from '@shared/dialog-confirmation';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService
  ) { }

  public canDeactivate(component: CanDeactivateComponent, currentRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return component?.canDeactivate()
      .pipe(
        switchMap((canDeactivate) => {
          if (canDeactivate) {
            return of(canDeactivate);
          }

          const {deactivateDialog} = currentRoute.data;

          return this.dialogService.open(DialogConfirmationComponent, {
            data: {
              action: this.dialogService.close.bind(this),
              title: this.translateService.instant(deactivateDialog?.title || 'SHARED.MODAL_LEAVE_PAGE.TEXT_TITLE'),
              text: this.translateService.instant(deactivateDialog?.text || 'SHARED.MODAL_LEAVE_PAGE.TEXT_MESSAGE')
            }
          })
            .afterClosed();
        }),
        exhaustMap((result) => of(!!result))
      );
  }
}
