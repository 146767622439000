import { TaskStatusEnum } from '../enums';
import { DateTime } from 'luxon';

export class TaskFilters {
  public search?: string;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public package?: number | Array<number>;
  public packageActivity?: Array<number>;
  public packageActivityTask?: Array<number>;
  public user?: Array<number>;
  public status?: Array<TaskStatusEnum>;
  public recipient?: Array<number>;
  public allWithActivity?: boolean;
  public isDefaultForSubtask?: boolean;
  public startDate?: DateTime;
  public endDate?: DateTime;
  public updatedAtStartDate?: DateTime;
  public updatedAtEndDate?: DateTime;
  public statusExclude?: Array<TaskStatusEnum>;
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<TaskFilters> = {}) {
    Object.assign(this, model);
  }
}
