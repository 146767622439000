import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';

export class PaginationData {
  @Type(() => Number)
  @Expose({ name: 'total_items', groups: [ClassGroup.MAIN] })
  public totalItems: number;

  constructor(model: Partial<PaginationData> = {}) {
    Object.assign(this, model);
  }
}
