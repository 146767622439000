import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { FloorPlanRevisionEventType } from '../enums';

export class FloorPlanRevisionMeta {
  @Type(() => Number)
  @Expose()
  public id: number;

  @Expose({ name: 'event_type' })
  public eventType: FloorPlanRevisionEventType;

  @Expose()
  public revision: number;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at' })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at' })
  public updateAt: DateTime;

  constructor(model: Partial<FloorPlanRevisionMeta> = {}) {
    Object.assign(this, model);
  }
}
