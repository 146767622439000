import { AssetHandoverDocumentMediaStatusEnum } from '@shared/asset-handover-document-media';
import { PackageHandoverDocumentMediaStatusEnum } from '@shared/package-handover-document-media/enums';

export class CompanyFilters {
  public id?: Array<number>;
  public search?: string;
  public subcontractorsInProject?: number;
  public project?: number;
  public companiesOfPackageActivities?: number;
  public getTotalItemsCount?: boolean;
  public package?: Array<number>;
  public packageActivity?: Array<number>;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public hasHandoverDocuments?: boolean;
  public hasPublishedTasks?: boolean;
  public status?: Array<PackageHandoverDocumentMediaStatusEnum | AssetHandoverDocumentMediaStatusEnum>;
  public packageHandoverDocumentType?: Array<number>;
  public documentGroup?: Array<number>;
  public assetHandoverDocumentType?: Array<number>;
  public clientsInProject?: number;
  public hasAssetHandoverDocuments?: boolean;

  constructor(model: Partial<CompanyFilters> = {}) {
    Object.assign(this, model);
  }
}
