import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PermissionsService } from './permissions.service';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  imports: [
    CommonModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    PermissionsService
  ]
})
export class PermissionsModule {
  constructor(@Optional() @SkipSelf() parentModule: PermissionsModule) {
    if (parentModule) {
      throw new Error('PermissionsModule is already loaded. Import it in the AppModule only');
    }
  }
}
