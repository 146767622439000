import { Exclude } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { PackageHandoverDocumentMediaUpdateSortField } from '../enums';

export class PackageHandoverDocumentMediaUpdatePaginationRequest extends PaginationRequest {
  @Exclude()
  public sortBy?: PackageHandoverDocumentMediaUpdateSortField;

  constructor(model: Partial<PackageHandoverDocumentMediaUpdatePaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
