import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WebpackTranslateLoader } from './app.translate.loader';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ApiModule } from '@shared/api';
import { configuration } from '@configurations';
import { AuthModule } from '@shared/auth';
import { UserModule } from '@shared/user';
import { ToastrModule } from 'ngx-toastr';
import { NotificationComponent } from '@shared/notification';
import { NavigationModule, NavigationEffects } from '@shared/navigation';
import { CanDeactivateModule } from '@shared/can-deactivate';
import { PermissionsModule, PermissionsService } from '@shared/permissions';
import { DateAdapter, MAT_DATE_FORMATS, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { CustomDateAdapter } from '@shared/date-adapter';
import { AppEffects } from '@shared/store';
import { NgProgressbar, provideNgProgressOptions } from 'ngx-progressbar';
import { progressInterceptor, provideNgProgressHttp } from 'ngx-progressbar/http';
import { NgProgressRouter, provideNgProgressRouter } from 'ngx-progressbar/router';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import * as Sentry from '@sentry/angular';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { NoConnectionInterceptor, NoConnectionModule } from '@shared/no-connection';
import { AppErrorHandler } from '@shared/app-error-handler';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApiModule.forRoot({
      apiUrl: configuration.api.url,
      trailingSlash: true,
      fileKeys: ['file']
    }),
    AuthModule,
    UserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    EffectsModule.forRoot([
      NavigationEffects,
      AppEffects
    ]),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot({
      router: routerReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: configuration.storeDevtools.maxAge,
      logOnly: configuration.production
    }),
    ToastrModule.forRoot({
      ...configuration.notifications,
      toastComponent: NotificationComponent
    }),
    NavigationModule,
    CanDeactivateModule,
    NoConnectionModule,
    PermissionsModule,
    NgxTippyModule,
    NgProgressbar,
    NgProgressRouter,
    HttpClientModule
  ],
  providers: [
    {provide: ErrorHandler, useClass: AppErrorHandler},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: configuration.datepicker.dateFormats },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: configuration.materail.ripples },
    {
      provide: APP_INITIALIZER,
      useFactory: (ps: PermissionsService) => () => ps.refreshPermissions(),
      deps: [PermissionsService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoConnectionInterceptor,
      multi: true
    },
    provideNgProgressOptions(configuration.ngxProgress),
    provideHttpClient(withInterceptors([progressInterceptor])),
    provideNgProgressHttp({}),
    provideNgProgressRouter({})
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
