export enum SubtaskStatus {
  CLOSED = 'closed',
  IN_PROGRESS = 'in_progress',
  REMOVED = 'removed',
  CONTESTED = 'contested',
  UNDER_INSPECTION = 'under_inspection',
  INSPECTION_REJECTED = 'inspection_rejected',
  REQUESTING_APPROVAL = 'requesting_approval',
  REQUESTED_APPROVAL_REJECTED = 'requested_approval_rejected',
  DECLINED = 'declined',
  UNDER_REVIEW = 'under_review'
}
