import { createAction, props } from '@ngrx/store';
import { Project } from '@shared/project';
import { ProjectNews } from '@shared/project-news';
import { ProjectResponseCategory } from '@shared/project-response-category/models/response-category';
import { User } from '@shared/user';

export class AccountProjectsDetailsPageActions {
  /* eslint-disable  */
  public static resetState = createAction(
    '[AccountProjectsDetailsPage] Reset State'
  );

  public static setCurrentProject = createAction(
    '[AccountProjectsDetailsPage] Set Current Project',
    props<{ currentProject: Project }>()
  );

  public static addNewsSuccess = createAction(
    '[AccountProjectsDetailsPage] Add News Success',
    props<{ response: ProjectNews }>()
  );

  public static updateNewsSuccess = createAction(
    '[AccountProjectsDetailsPage] Update News Success',
    props<{ news: ProjectNews }>()
  );

  public static deleteNewsSuccess = createAction(
    '[AccountProjectsDetailsPage] Delete News Success',
    props<{ id: number }>()
  );

  public static addResponseCategorySuccess = createAction(
    '[AccountProjectsDetailsPage] Add Response Category Success',
    props<{ response: ProjectResponseCategory }>()
  );

  public static removeResponseCategorySuccess = createAction(
    '[AccountProjectsDetailsPage] Remove Response Category Success',
    props<{ id: number }>()
  );

  public static addContactsSuccess = createAction(
    '[AccountProjectsDetailsPage] Add Contacts Success',
    props<{ contacts: Array<User> }>()
  );

  public static removeContactSuccess = createAction(
    '[AccountProjectsDetailsPage] Remove Contact Success',
    props<{ id: number }>()
  );

  public static addUsersSuccess = createAction(
    '[AccountProjectsDetailsPage] Users Contacts Success',
    props<{ users: Array<User> }>()
  );

  public static showAccessDenied = createAction(
    '[AccountProjectsDetailsPage] Show Access Denied'
  );

  public static hideAccessDenied = createAction(
    '[AccountProjectsDetailsPage] Hide Access Denied'
  );

  public static clearFilters = createAction(
    '[AccountProjectsDetailsPage] Clear Filters'
  );
  /* eslint-enable */
}
