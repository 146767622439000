import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User, UserGroupEnum, UserService } from '@shared/user';
import { exhaustMap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ProjectClientTaskBreadcrumbResolver implements Resolve<string> {
  private get profile$(): Observable<User> {
    return this.userService.profile$;
  }

  constructor(
    private userService: UserService,
    private translateService: TranslateService
  ) {
  }

  public resolve(): Observable<string> {
    return this.profile$.pipe(
      exhaustMap((profile) => {
        if (profile) {
          return of(profile);
        }

        return this.userService.refreshProfile();
      })
    )
      .pipe(
        map((profile) => {
          const isClientOrConsultant = [UserGroupEnum.CLIENT, UserGroupEnum.CONSULTANT].includes(profile.group);

          return this.translateService.instant(
            `ACCOUNT.PROJECTS.DETAILS.BREADCRUMB.${isClientOrConsultant ? 'CLIENT_QUALITY_REPORT' : 'QUALITY_REPORT'}`
          );
        })
      );
  }
}
