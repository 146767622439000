import { ClassGroup } from '@shared/class-group';
import { Expose } from 'class-transformer';

export class QualityIssueBulkData {
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public task: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public company: number;

  @Expose({ name: 'quality_issues', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public qualityIssues: Array<number>;

  constructor(model: Partial<QualityIssueBulkData> = {}) {
    Object.assign(this, model);
  }
}
