export class HandoverDocumentFilters {
  public search?: string;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public package?: Array<number>;
  public packageID?: number;
  public packageActivity?: Array<number>;
  public documentType?: Array<number>;
  public company?: Array<number>;
  public getTotalItemsCount?: boolean;
  public packageHandoverDocumentType?: Array<number>;
  public assetHandoverDocumentType?: Array<number>;
  public isAssetHandover?: boolean;

  constructor(model: Partial<HandoverDocumentFilters> = {}) {
    Object.assign(this, model);
  }
}
