import { Exclude, Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { AssetHandoverSortField } from '../types';
import { AssetHandoverDocumentMediaStatusEnum } from '@shared/asset-handover-document-media';

export class AssetHandoverPaginationRequest extends PaginationRequest {
  @Expose()
  public package?: number;

  @Expose()
  public limit?: number;

  @Expose()
  public offset?: number;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'use_limit_offset_pagination' })
  public useLimitOffsetPagination?: boolean;

  @Expose()
  public building?: Array<string>;

  @Expose()
  public level?: Array<string>;

  @Expose()
  public area?: Array<string>;

  @Expose()
  public status?: Array<AssetHandoverDocumentMediaStatusEnum>;

  @Exclude()
  public sortBy?: AssetHandoverSortField;

  constructor(model: Partial<AssetHandoverPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
