import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { ValidationFn } from 'ngx-permissions/lib/model/permissions-router-data.model';
import { UserGroupEnum } from '@shared/user/enums';
import { configuration } from '@configurations';
import { DialogUnexpectedErrorOccurredComponent } from '@shared/dialog-unexpected-error';
import { DialogService } from '@shared/dialog';

@Injectable()
export class PermissionsService {
  public get isCompanyAdmin(): boolean {
    return !!this.ngxPermissionsService.getPermission(UserGroupEnum[UserGroupEnum.COMPANY_ADMIN]);
  }

  constructor(
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly dialogService: DialogService
  ) { }

  public flushPermissions(): void {
    this.ngxPermissionsService.flushPermissions();
  }

  public loadPermissions(permissions: Array<string>, validationFunction?: ValidationFn): void {
    this.ngxPermissionsService.loadPermissions(permissions, validationFunction);
  }

  public refreshPermissions(): void {
    try {
      this.flushPermissions();
      this.loadPermissions(this.getPermissions());
    } catch {
      this.dialogService.open(DialogUnexpectedErrorOccurredComponent, {
        data: {
          text: 'It looks like you have disabled local storage in your browser. Please enable it and refresh the page.'
        }
      });
    }
  }

  private getPermissions(): Array<string> {
    return [
      UserGroupEnum[parseInt(localStorage.getItem(configuration.userGroupID.storageKey), 10)]
    ];
  }
}
