import { Expose, Type } from 'class-transformer';
import { ClassGroup } from '../../class-group';
import { PackageActivity } from '@shared/package-activity';

export class Package {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public id?: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public name: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.UPDATING, ClassGroup.CREATING] })
  public order?: number;

  @Type(() => PackageActivity)
  @Expose({ name: 'expanded_package_activities', groups: [ClassGroup.MAIN] })
  public expandedPackageActivities: Array<PackageActivity>;

  @Type(() => Number)
  @Expose({ name: 'expanded_projects_count', groups: [ClassGroup.MAIN] })
  public expandedProjectsCount?: number;

  @Type(() => Number)
  @Expose({ name: 'total_items', groups: [ClassGroup.MAIN] })
  public totalItems: number;

  constructor(model: Partial<Package> = {}) {
    Object.assign(this, model);
  }
}
