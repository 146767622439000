import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { exhaustMap, finalize, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserService } from '@shared/user';
import { AuthService } from '@shared/auth/auth.service';

@Injectable()
export class IsApprovedUserGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {
  }

  public canActivate(): Observable<boolean> {
    return this.userService
      .profile$
      .pipe(
        exhaustMap((profile) => {
          if (profile) {
            return of(profile);
          }

          return this.userService.refreshProfile();
        }),
        switchMap((profile) => profile.isApproved
          ? of(profile.isApproved)
          : this.authService
            .logout()
            .pipe(
              map(() => profile.isApproved),
              finalize(() => {
                if (!profile.isApproved) {
                  this.router.navigate(['/not-found']);
                }
              })
            )
        )
      );
  }
}
