import { Exclude, Expose, Type } from 'class-transformer';
import { ClassGroup } from '@shared/class-group';
import { AssetHandoverDocumentMediaStatusEnum } from '@shared/asset-handover-document-media/enums';
import { sum } from 'lodash';

export class AssetHandoverStatistics {
  @Type(() => Number)
  @Expose({ name: 'accepted_count', groups: [ClassGroup.MAIN] })
  public [AssetHandoverDocumentMediaStatusEnum.ACCEPTED]?: number;

  @Type(() => Number)
  @Expose({ name: 'in_progress_count', groups: [ClassGroup.MAIN] })
  public [AssetHandoverDocumentMediaStatusEnum.IN_PROGRESS]?: number;

  @Type(() => Number)
  @Expose({ name: 'contested_count', groups: [ClassGroup.MAIN] })
  public [AssetHandoverDocumentMediaStatusEnum.CONTESTED]?: number;

  @Type(() => Number)
  @Expose({ name: 'requested_approval_rejected_count', groups: [ClassGroup.MAIN] })
  public [AssetHandoverDocumentMediaStatusEnum.REQUESTED_APPROVAL_REJECTED]?: number;

  @Type(() => Number)
  @Expose({ name: 'requesting_approval_count', groups: [ClassGroup.MAIN] })
  public [AssetHandoverDocumentMediaStatusEnum.REQUESTING_APPROVAL]?: number;

  @Type(() => Number)
  @Expose({ name: 'required_files_count', groups: [ClassGroup.MAIN] })
  public requiredFilesCount?: number;

  @Type(() => Number)
  @Expose({ name: 'filled_information_count', groups: [ClassGroup.MAIN] })
  public filledInformationCount?: number;

  @Type(() => Number)
  @Expose({ name: 'total_information_count', groups: [ClassGroup.MAIN] })
  public totalInformationCount?: number;

  @Type(() => Number)
  @Expose({ name: 'uploaded_files_count', groups: [ClassGroup.MAIN] })
  public uploadedFilesCount?: number;

  @Exclude()
  public get mediaCount(): number {
    const statuses = Object.values(AssetHandoverDocumentMediaStatusEnum);

    return sum(statuses.map((status) => this[status]));
  }

  @Exclude()
  public get existingStatuses(): Array<AssetHandoverDocumentMediaStatusEnum> {
    const statuses = Object.values(AssetHandoverDocumentMediaStatusEnum);

    return statuses.filter((status) => this[status] !== 0);
  }

  @Exclude()
  public get areAllMediaInTheSameStatus(): boolean {
    return this.existingStatuses.length === 1;
  }

  constructor(model: Partial<AssetHandoverStatistics> = {}) {
    Object.assign(this, model);
  }
}
