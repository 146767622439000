import { Project } from '@shared/project';

export class AccountProjectsDetailsPageState {
  public currentProject: Project;
  public isAccessDeniedShown: boolean;

  constructor() {
    this.currentProject = null;
    this.isAccessDeniedShown = false;
  }
}
