import { ProjectStatusEnum } from '../enums';

export class ProjectFilters {
  public search?: string;
  public status?: Array<ProjectStatusEnum>;
  public getTotalItemsCount?: boolean;

  constructor(model: Partial<ProjectFilters> = {}) {
    Object.assign(this, model);
  }
}
