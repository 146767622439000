import { AssetHandoverDocument } from '@shared/asset-handover-document';
import { Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { ClassGroup } from '../../class-group';

export class AssetHandoverLocation {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public project: number;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING, ClassGroup.BUILDING] })
  public building: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING, ClassGroup.LEVEL] })
  public level: string;

  @Expose({ groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING, ClassGroup.AREA] })
  public area: string;

  @Type(() => AssetHandoverDocument)
  @Expose({ name: 'asset_handover_documents', groups: [ClassGroup.MAIN] })
  public assetHandoverDocument: Array<AssetHandoverDocument>;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  constructor(model: Partial<AssetHandoverLocation> = {}) {
    Object.assign(this, model);
  }
}
