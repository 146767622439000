import { FloorPlanArea } from '@shared/floor-plan-area';
import { fabric } from 'fabric';
import { FloorPlanAreaStateAction } from '../types';

export class FloorPlanAreaHistoryItem {
  public area: FloorPlanArea;
  public data: fabric.Object;
  public action: FloorPlanAreaStateAction;
  public state: Array<FloorPlanArea>;

  constructor(model: Partial<FloorPlanAreaHistoryItem>) {
    Object.assign(this, model);
  }
}
