import { Expose, Type } from 'class-transformer';
import { FloorPlanArea } from './floor-plan-area';

export class FloorPlanAreaBulk {
  @Type(() => FloorPlanArea)
  @Expose()
  public create: Array<FloorPlanArea> = [];

  @Type(() => FloorPlanArea)
  @Expose()
  public update: Array<FloorPlanArea> = [];

  @Type(() => FloorPlanArea)
  @Expose()
  public delete: Array<FloorPlanArea> = [];

  constructor(model: Partial<FloorPlanAreaBulk> = {}) {
    Object.assign(this, model);
  }
}
