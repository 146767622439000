import { FloorPlanArea } from '@shared/floor-plan-area/models';
import { Expose, Type } from 'class-transformer';
import { FloorPlanRevisionSerializedDataItem } from './serialized-data-item';

export class FloorPlanRevisionFloorPlanArea {
  @Type(() => Number)
  @Expose()
  public id?: number;

  @Expose({ name: 'floor_plan_area' })
  public floorPlanArea?: string;

  @Expose()
  public revision: number;

  @Type(() => FloorPlanRevisionSerializedDataItem )
  @Expose({ name: 'serialized_data' })
  public serializedData?: Array<FloorPlanRevisionSerializedDataItem<FloorPlanArea>>;

  constructor(model: Partial<FloorPlanArea> = {}) {
    Object.assign(this, model);
  }
}
