import { Expose, Exclude } from 'class-transformer';

export class PaginationRequest {
  @Expose()
  public search?: string;

  @Expose()
  public page?: number;

  @Expose({ name: 'per_page' })
  public perPage?: number;

  @Expose()
  public all?: boolean;

  @Exclude()
  public sortBy?: string;

  @Exclude()
  public desc?: boolean;

  @Exclude()
  public extraSortBy?: string;

  @Exclude()
  public extraDesc?: boolean;

  @Expose({ name: 'expand', toPlainOnly: true })
  public relations?: Array<string>;

  @Expose()
  public get sort(): string {
    const sortFields = [
      this.generateSort(this.sortBy, this.desc),
      this.generateSort(this.extraSortBy, this.extraDesc)
    ];

    return sortFields.filter((field) => field).join(',');
  }

  constructor(model: Partial<PaginationRequest> = {}) {
    Object.assign(this, model);
  }

  @Exclude()
  private generateSort(sortBy: string, desc: boolean): string {
    return sortBy
      ? desc
        ? `-${sortBy}`
        : sortBy
      : '';
  }
}
