export class FloorPlanAreaFilters {
  public floorPlan?: number;
  public building?: Array<string>;
  public level?: Array<string>;
  public area?: Array<string>;
  public package?: Array<number>;
  public getTotalItemsCount?: boolean;
  public hasFloorPlanArea?: boolean;

  constructor(model: Partial<FloorPlanAreaFilters> = {}) {
    Object.assign(this, model);
  }
}
