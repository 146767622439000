import { AssetHandoverDocumentMedia } from '@shared/asset-handover-document-media';
import { AssetHandoverDocumentType } from '@shared/asset-handover-document-type';
import { AssetHandoverStatistics } from '@shared/asset-handover-statistics';
import { AssetHandover } from '@shared/asset-handover/models';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { ClassGroup } from '../../class-group';

export class AssetHandoverDocument {
  @Type(() => Number)
  @Expose({ groups: [ClassGroup.MAIN] })
  public id: number;

  @Type(() => Number)
  @Expose({ name: 'asset_handover', groups: [ClassGroup.MAIN, ClassGroup.UPDATING] })
  public assetHandover: number;

  @Type(() => Number)
  @Expose({ name: 'document_type', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public documentType: number;

  @Type(() => AssetHandoverDocumentType)
  @Expose({ name: 'expanded_document_type', groups: [ClassGroup.MAIN] })
  public expandedDocumentType: AssetHandoverDocumentType;

  @Type(() => AssetHandoverStatistics)
  @Expose({ name: 'expanded_asset_handover_statistics', groups: [ClassGroup.MAIN] })
  public expandedAssetHandoverStatistics: AssetHandoverStatistics;

  @Type(() => AssetHandoverDocumentMedia)
  @Expose({ name: 'expanded_asset_handover_document_media', groups: [ClassGroup.MAIN] })
  public expandedAssetHandoverDocumentMedia: Array<AssetHandoverDocumentMedia>;

  @Type(() => Number)
  @Expose({ name: 'number_required_files', groups: [ClassGroup.MAIN, ClassGroup.CREATING, ClassGroup.UPDATING] })
  public numberRequiredFiles: number;

  @Type(() => Number)
  @Expose({ name: 'asbuilt_drawing_required_files', groups: [ClassGroup.MAIN, ClassGroup.CREATING] })
  public asbuiltDrawingRequiredFiles: number;

  @Type(() => Number)
  @Expose({ name: 'test_pack_required_files', groups: [ClassGroup.CREATING] })
  public testPackRequiredFiles: number;

  @Type(() => AssetHandover)
  @Expose({ name: 'expanded_asset_handover', groups: [ClassGroup.MAIN] })
  public expandedAssetHandover: AssetHandover;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'created_at', groups: [ClassGroup.MAIN] })
  public createdAt: DateTime;

  @Transform(({ value }) => DateTime.fromISO(value), { toClassOnly: true })
  @Expose({ name: 'updated_at', groups: [ClassGroup.MAIN] })
  public updatedAt: DateTime;

  @Exclude()
  public get areAllMediaInTheSameStatus(): boolean {
    return this.expandedAssetHandoverDocumentMedia.every(
      (media) => media.status === this.expandedAssetHandoverDocumentMedia[0].status
    );
  }

  constructor(model: Partial<AssetHandoverDocument> = {}) {
    Object.assign(this, model);
  }
}
