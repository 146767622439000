import { Exclude, Expose } from 'class-transformer';
import { PaginationRequest } from '@shared/pagination';
import { PackageHandoverSortField } from '../types';
import { PackageHandoverDocumentMediaStatusEnum } from '@shared/package-handover-document-media/enums';

export class PackageHandoverPaginationRequest extends PaginationRequest {
  @Expose()
  public package?: number;

  @Expose()
  public limit?: number;

  @Expose()
  public offset?: number;

  @Expose()
  public status?: Array<PackageHandoverDocumentMediaStatusEnum>;

  @Expose()
  public company?: Array<number>;

  @Expose({ name: 'package_activity' })
  public packageActivity?: Array<number>;

  @Expose({ name: 'document_group' })
  public documentGroup?: Array<number>;

  @Expose({ name: 'document_type' })
  public documentType?: Array<number>;

  @Expose({ name: 'show_all_groups' })
  public showAllGroups?: boolean;

  @Expose({ name: 'show_document_group' })
  public showDocumentGroup?: Array<number>;

  @Exclude()
  public sortBy?: PackageHandoverSortField;

  constructor(model: Partial<PackageHandoverPaginationRequest> = {}) {
    super(model);

    Object.assign(this, model);
  }
}
