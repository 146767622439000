import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageMatrixService } from './package-matrix.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    PackageMatrixService
  ]
})
export class PackageMatrixModule { }
