import { FilterOptions } from './models';
import { FilterMode } from './enums';

export class BaseFilterComponentState<T = unknown> {
  public filterOptions?: FilterOptions;
  public items: Array<T>;
  public isLoading: boolean;
  public page: number;
  public perPage: number;
  public sortBy?: T;
  public desc: boolean;
  public isFilterCleared: boolean;
  public isAlwaysSelected: boolean;
  public mode?: FilterMode;

  constructor() {
    this.filterOptions = new FilterOptions();
    this.items = [];
    this.isLoading = false;
    this.page = 1;
    this.perPage = 50;
    this.desc = false;
    this.isFilterCleared = false;
    this.isAlwaysSelected = false;
    this.mode = FilterMode.TASK;
  }
}
