import { fabric } from 'fabric';

export class FloorPlanPlanLinearPointer {
  public leftLinePointer: fabric.Line;
  public rightLinePointer: fabric.Line;
  public pointer: fabric.Point;

  constructor(model: Partial<FloorPlanPlanLinearPointer>) {
    Object.assign(this, model);
  }
}
